.admin-main {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.admin-main__header {
  height: 42px;
  width: 100%;
  background-color: #333;
  display: flex;
  justify-content: space-between;
}

.admin-main__burger {
  border: none;
  margin-top: 10px;
  margin-left: 5px;
  outline: 0;
  background-color: transparent;
  align-self: flex-start;
}

.admin-main__burger:hover {
  cursor: pointer;
}

.admin-main__burger:hover .admin-main__icon {
  filter: invert(55%) sepia(94%) saturate(508%) hue-rotate(143deg)
    brightness(106%) contrast(103%);
}

.admin-main__greeting {
  color: #fff;
  margin: 0;
  padding: 0;
  display: flex;
  font-size: 14px;
  align-items: center;
}

.admin-main__button {
  height: 30px;
  margin: 0 5px 0 0;
  padding: 0 7px 0 0;
  border: none;
  border-radius: 5px;
  background-color: #028fe8;
  color: #fff;
  cursor: pointer;
  align-self: center;
  text-decoration: none;
  box-shadow: 0px 0px 15px rgba(125, 157, 204, 0.2);
  display: flex;
  justify-content: center;
  font-size: 14px;
}

.admin-main__button:hover {
  opacity: 0.7;
  transition: opactity 0.3s ease-in-out;
}

.admin-main__icon {
  margin: 0;
  padding: 0;
  width: 25px;
  fill: "#fff";
}

.admin-main__subtitle {
  font-size: 14px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.admin-main__wrapper {
  display: flex;
}

.admin__checkbox {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  height: 20px;
}

.admin__checkbox-image {
  width: 36px;
  height: 20px;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  background-color: #a0a0a0;
  border-radius: 20px;
  transition: 0.5s;
  position: relative;
}

.admin__checkbox-image::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 2px;
  left: 2px;
  background-color: #fff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  transition: 0.5s;
  transform: scale(1.1);
  border-radius: 20px;
}

.admin__checkbox-image:checked {
  background-color: #7cedfc;
}

.admin__checkbox-image:checked:before {
  left: 18px;
}

.admin__checkbox-image:hover {
  opacity: 0.7;
  transition: opactity 0.3s ease-in-out;
  cursor: pointer;
}

@media screen and (max-width: 799px) {
  .admin-main__burger {
    margin-left: 0;
  }
  .admin-main__subtitle {
    font-size: 12px;
  }
  .admin-main__greeting {
    font-size: 12px;
  }
  .admin-main__icon {
    width: 20px;
  }
}
