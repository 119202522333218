.checkbox {
  width: 454px;
  height: 50px;
  background-color: #028fe8;
  margin-top: 50px;
  cursor: pointer;
}

.checkbox:hover {
  opacity: 0.7;
  transition: opactity .3s ease-in-out;
}
