.repair-mb__price-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 55px 0 28px;
  width: 100%;
  max-width: 1066px;
}

.repair-mb__price-title {
  max-width: 700px;
  margin: 0 0 74px;
  padding: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
}

.repair-mb__price-list {
  margin: 0;
  padding: 48px 57px 71px;
  list-style-type: none;
  max-width: 946px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 134px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.repair-mb__price-item {
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(196, 196, 196, 0.63);
  margin-bottom: 18px;
}

.repair-mb__price-item:last-of-type {
  margin-bottom: 48px;
}

.repair-mb__price-text {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #88898d;
  max-width: 800px;
}

.repair-mb__price-span {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #88898d;
}

.repair-mb__price-price {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: right;
  color: #02e2ff;
  align-self: end;
}

.repair-mb__price-paragraph {
  max-width: 775px;
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #88898d;
}

@media screen and (max-width: 1130px) {
  .repair-mb__price-list {
    max-width: 780px;
  }
  .repair-mb__price-text {
    max-width: 620px;
  }
}

@media screen and (max-width: 970px) {
  .repair-mb__price-list {
    max-width: 600px;
    padding: 22px 38px 35px;
  }
  .repair-mb__price-text {
    max-width: 450px;
  }
}

@media screen and (max-width: 720px) {
  .repair-mb__price-container {
    max-width: 480px;
  }
  .repair-mb__price-title {
    margin: 0 0 37px;
    font-size: 27px;
    line-height: 32px;
  }
  .repair-mb__price-item {
    flex-direction: column;
  }
  .repair-mb__price-price {
    text-align: left;
    align-self: start;
    margin-top: 0px;
  }
}

@media screen and (max-width: 600px) {
  .repair-mb__price-container {
    max-width: 420px;
  }
  .repair-mb__price-list {
    padding: 22px 25px 30px;
  }
}

@media screen and (max-width: 500px) {
  .repair-mb__price-container {
    padding: 45px 20px;
    max-width: 100%;
  }
  .repair-mb__price-title {
    margin: 0 0 62px;
    font-size: 19px;
    line-height: 26px;
    text-align: left;
  }
  .repair-mb__price-list {
    padding: 43px 15px;
  }
  .repair-mb__price-item {
    padding-bottom: 30px;
    margin-bottom: 26px;
  }
  .repair-mb__price-paragraph {
    font-size: 14px;
    line-height: 21px;
  }
}
