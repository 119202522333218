.section {
  background-color: #028fe8;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 0 35px;
  font-family: "Open Sans", sans-serif;
  max-width: 1066px;
  width: 100%;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  padding: 0;
  margin: 0 0 10px;
}

.list {
  max-width: 734px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.link {
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 30px;
  color: #ffffff;
}

@media screen and (max-width: 834px) {
  .content {
    margin: 0 50px;
  }
}

@media screen and (max-width: 730px) {
  .list {
    display: grid;
    max-width: 490px;
    grid-template-columns: repeat(auto-fit, minmax(122px, 1fr));
    justify-items: center;
  }
}

@media screen and (max-width: 500px) {
  .list {
    display: flex;
    flex-direction: column;
    max-width: 490px;
  }
  .section {
    background: linear-gradient(
      225deg,
      rgba(196, 196, 196, 0.09) 40.61%,
      rgba(196, 196, 196, 0) 92.65%
    );
  }
  .title {
    font-style: normal;
    font-size: 19px;
    line-height: 26px;
    text-transform: uppercase;
    color: #02e2ff;
    margin: 0 0 25px;
  }
  .item {
    margin-bottom: 25px;
  }
  .link {
    font-size: 19px;
    line-height: 17px;
    color: #02e2ff;
  }
  .content {
    padding: 45px 0 10px;
  }
}
