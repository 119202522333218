.callbackSection {
  background: linear-gradient(
    225deg,
    rgba(196, 196, 196, 0.09) 40.61%,
    rgba(196, 196, 196, 0) 92.65%
  );
}

.content {
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: column;
  padding: 50px 0 66px;
  max-width: 1066px;
  width: 100%;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 31px;
  color: #02e2ff;
  margin: 0 0 50px;
  padding: 0;
}

.list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.wrapper {
  max-width: 992px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 61px;
}

.listLinks {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  max-width: 497px;
  width: 100%;
}

.linkItem {
  display: flex;
  align-items: center;
}

.iconTel {
  margin-right: 17px;
}

.link {
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 31px;
  color: #231f20;
  text-decoration: none;
  cursor: pointer;
}

.item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
}

.item:last-of-type {
  margin-bottom: 0;
}

.circle {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #02e2ff;
  margin-right: 15px;
  margin-top: 8px;
}

.text {
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 31px;
  color: #88898d;
  margin: 0;
  padding: 0;
  max-width: 1020px;
}

.button {
  width: 382px;
  padding: 16px 0;
  border: none;
  background: #028fe8;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
  cursor: pointer;
  text-decoration: none;
}

@media screen and (max-width: 1160px) {
  .content {
    max-width: 890px;
  }
  .listLinks {
    flex-direction: column;
    justify-content: space-between;
    max-width: 250px;
  }
  .wrapper {
    max-width: 780px;
  }
  .text {
    max-width: 790px;
  }
}

@media screen and (max-width: 980px) {
  .content {
    max-width: 690px;
  }
  .text {
    max-width: 620px;
  }
}

@media screen and (max-width: 780px) {
  .content {
    max-width: 550px;
  }
  .title {
    font-size: 24px;
    line-height: 25px;
  }
  .text {
    font-size: 17px;
    line-height: 26px;
    max-width: 420px;
  }
  .circle {
    align-self: flex-start;
    margin-top: 7px;
  }
  .wrapper {
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
  }
  .link {
    font-size: 18px;
    line-height: 25px;
  }
  .button {
    width: 278px;
    padding: 8px 0;
    border-radius: 20px;
    font-size: 16px;
    line-height: 35px;
    margin-top: 40px;
  }
}

@media screen and (max-width: 600px) {
  .content {
    align-items: center;
    max-width: 100%;
    padding: 45px 20px;
  }
  .title {
    font-size: 18px;
    line-height: 20px;
    max-width: 310px;
    margin: 0 0 45px;
    align-self: flex-start;
  }
  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    max-width: 90%;
  }
}
