.iphone-select-price {
  font-family: "Open Sans";
  max-width: 505px;
  width: 100%;
  border-radius: 5px;
  padding: 0 10px;
  margin: auto;
}

.iphone-select-price__text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: flex-end;
  color: #88898d;
  margin: 16px 0 10px;
  padding: 4px 0 0;
}

.iphone-select-price__span {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #02e2ff;
  margin-left: auto;
}

@media screen and (max-width: 720px) {
  .iphone-select-price {
    max-width: 450px;
  }
}

@media screen and (max-width: 500px) {
  .iphone-select-price__span {
    width: 70px;
  }
  .iphone-select-price {
    max-width: 100%;
    padding: 0;
  }
  .iphone-select-price__text {
    padding: 4px 20px 0;
  }
}
