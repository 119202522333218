.section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 65px 0;
}

.content {
  max-width: 800px;
}

.title {
  margin: 0 0 40px;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 70px;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  letter-spacing: 2.52px;
  min-height: 75px;
  color: #88898d;
  max-width: 850px;
}

.contacts {
  width: 100%;
  max-width: 600px;
  height: 100%;
  background: white;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style-type: none;
}

.item {
  margin-bottom: 20px;
}

.wrapper {
  display: flex;
  max-width: 400px;
  justify-content: space-between;
}

.subtitle_bold {
  color: rgb(136, 137, 141);
  letter-spacing: normal;
  line-height: 23px;
  font-size: 20px;
  font-weight: 700;
  padding: 0;
  margin: 0;
}

.subtitle {
  color: rgb(136, 137, 141);
  letter-spacing: normal;
  line-height: 23px;
  font-size: 18px;
  font-weight: 400;
  padding: 0;
  margin: 0;
}

.phone_link {
  text-decoration: none;
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  color: #333;
  font-size: 23px;
  font-weight: 500;
  line-height: normal;
}

.mail_link {
  padding: 0;
  margin: 0;
  color: #02e1ff;
  font-size: 19px;
  font-weight: 500;
  line-height: normal;
}

.text {
  padding: 0;
  margin: 0;
  color: #333;
  font-size: 19px;
  font-weight: 500;
  line-height: normal;
}

@media screen and (max-width: 850px) {
  .content {
    max-width: 640px;
  }
  .title {
    color: #88898d;
    margin: 0 0 18px;
    padding: 0;
    font-family: "Open Sans", sans-serif;
    font-size: 65px;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    letter-spacing: 2.52px;
  }
  .contacts {
    max-width: 640px;
  }
}

@media screen and (max-width: 750px) {
  .title {
    font-size: 40px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 2.52px;
  }
  .content {
    max-width: 500px;
  }
}

@media screen and (max-width: 750px) {
  .content {
    max-width: 410px;
  }
}

@media screen and (max-width: 500px) {
  .title {
    margin: 0 0 18px;
    padding: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #02e2ff;
  }
  .wrapper {
    flex-direction: column;
  }
  .content {
    max-width: 100%;
  }
  .item {
    padding: 0 20px;
  }
}
