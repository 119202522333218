.center-mobile__general-page-gadgets {
  background: linear-gradient(
    269deg,
    rgba(196, 196, 196, 0.09) 40.61%,
    rgba(196, 196, 196, 0) 92.65%
  );
}

.center-mobile__general-page-gadgets-container {
  font-family: "Open Sans", sans-serif;
  max-width: 1066px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 52px 0 59px;
}

.center-mobile__general-page-gadgets-title {
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
  padding: 0;
  margin: 0 0 57px;
}

.center-mobile__general-page-gadgets-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: end;
  max-width: 1040px;
  width: 100%;
}

.center-mobile__general-page-gadgets-mobile-wrapper {
  display: flex;
  max-width: 390px;
  width: 100%;
}

.center-mobile__general-page-gadgets-pad-wrapper {
  max-width: 545px;
  width: 100%;
}

.center-mobile__general-page-gadgets-mobile-group {
  display: flex;
  max-width: 390px;
  width: 100%;
}

.center-mobile__general-page-gadgets-pad-group {
  display: flex;
  max-width: 545px;
  width: 100%;
}

.center-mobile__general-page-gadgets-device {
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 50px;
  color: #88898d;
  margin: 0;
  padding: 0;
  text-align: center;
}

.center-mobile__general-page-gadgets-mobile-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  color: #88898d;
  max-width: 250px;
  padding: 0;
  margin: 0 20px 0 0;
}

.center-mobile__general-page-gadgets-pad-text {
  align-self: flex-end;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  color: #88898d;
  max-width: 250px;
  padding: 0 0 55px;
  margin: 0 0 0 20px;
}

.center-mobile__general-page-gadgets-button {
  width: 219px;
  height: 52px;
  background: #028fe8;
  border-radius: 20px;
  border: none;
  margin-top: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.center-mobile__general-page-gadgets-icon {
  margin-right: 8px;
}

.center-mobile__general-page-gadgets-list {
  list-style-type: none;
  padding: 0;
  margin: 0 20px 0 0;
  max-width: 250px;
  width: 100%;
}

.center-mobile__general-page-gadgets-padlist {
  list-style-type: none;
  padding: 0 0 55px;
  margin: 0 0 0 20px;
  max-width: 250px;
  width: 100%;
  align-self: flex-end;
}

.center-mobile__general-page-gadgets-links {
  display: flex;
  max-width: 260px;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #c9c5c5;
  margin-bottom: 10px;
}

.center-mobile__general-page-gadgets-link {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 50px;
  color: #88898d;
  text-decoration: none;
}

.phone-image {
  -webkit-box-shadow: 15px 19px 33px 1px rgba(34, 60, 80, 0.28);
  -moz-box-shadow: 15px 19px 33px 1px rgba(34, 60, 80, 0.28);
  box-shadow: 15px 19px 33px 1px rgba(34, 60, 80, 0.28);
  border-radius: 20px;
}

.pad-image {
  -webkit-box-shadow: 15px 19px 33px 1px rgba(34, 60, 80, 0.28);
  -moz-box-shadow: 15px 19px 33px 1px rgba(34, 60, 80, 0.28);
  box-shadow: 15px 19px 33px 1px rgba(34, 60, 80, 0.28);
  border-radius: 20px;
}

@media screen and (max-width: 1080px) {
  .center-mobile__general-page-gadgets-mobile-text {
    margin: 0 20px 0 20px;
  }
  .center-mobile__general-page-gadgets-pad-text {
    margin: 0 20px 0 20px;
  }
  .center-mobile__general-page-gadgets-list {
    margin: 0 20px 0;
  }
  .center-mobile__general-page-gadgets-padlist {
    margin: 0 20px 0;
  }
}
