.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 499px;
  font-family: "Open Sans", sans-serif;
}

.item__icon {
  width: 205px;
  height: 182px;
  margin-bottom: 68px;
  align-self: flex-start;
  transition: 0.8s;
}

.item__icon:hover {
  transform: scale(1.2);
}

.item__title {
  width: 100%;
  color: #ffffff;
  font-size: 35px;
  font-weight: bold;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  text-transform: uppercase;
  margin: 0;
  min-height: 84px;
}

.item__text {
  color: #262626;
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: normal;
  line-height: 25px;
  margin: 0;
  padding: 0;
  margin-top: 21px;
}

@media screen and (max-width: 1080px) {
  .item__title {
    color: #02e2ff;
  }
}
