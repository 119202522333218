.delivery-info-mobile__container {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 45px 20px 65px;
}

.delivery-info-mobile__title {
  max-width: none;
  min-width: 0;
  min-height: 0;
  text-transform: uppercase;
}

.delivery-info-mobile__list {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  max-width: 956px;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 88px;
  align-self: center;
}

.delivery-info-mobile__paragraph {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #88898d;
  margin: 0;
  padding: 0;
  z-index: 2;
}

.delivery-info-mobile__paragraph-border {
  width: 27.02px;
  height: 0px;
  border: 1px solid #02e2ff;
  margin: 18px auto;
}

.delivery-info-mobile__paragraph-border:nth-child(5) {
  margin: 0;
  border: none;
}

.delivery-info-mobile__image {
  max-width: 304px;
  height: 304px;
  margin: -692px 0 348px 0;
}
