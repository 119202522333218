.subtitle {
  font-family: "Open Sans", sans-serif;
  color: #02e2ff;
  font-size: 30px;
  font-weight: bold;
  font-style: normal;
  max-width: 427px;
  margin: 0;
  padding: 0;
}

.subtitle-long {
  max-width: 637px;
  min-height: 67px;
  text-transform: uppercase;
}

.subtitle_color_white {
  color: #fff;
}

@media screen and (max-width: 800px) {
  .subtitle {
    font-size: 25px;
    line-height: 32px;
  }
  .subtitle-long {
    min-height: 0;
  }
}

@media screen and (max-width: 650px) {
  .subtitle-long {
    max-width: 450px;
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .subtitle {
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 26px;
    text-transform: uppercase;
    color: #02e2ff;
  }
}
