.not-found {
  margin: 0 auto;
  padding: 50px 0;
  min-width: 320px;
  max-width: 1280px;
  display: flex;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}

.not-found__title {
  margin: 0;
  padding: 0;
  max-width: 400px;
  min-height: 169px;
  color: #333;
  font-size: 140px;
}

.not-found__subtitle {
  margin: 5px 0 84px;
  padding: 0;
  max-width: 400px;
  min-height: 19px;
  font-size: 16px;
  line-height: 19px;
  color: #333;
}

.note-found__link {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  line-height: 22px;
  width: 223px;
  height: 46px;
  background-color: #028fe8;
  border-radius: 20px;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.note-found__link:hover {
  cursor: pointer;
  opacity: 0.7;
  transition: opactity .3s ease-in-out;
}
