.section {
  background: linear-gradient(
    225deg,
    rgba(196, 196, 196, 0.09) 40.61%,
    rgba(196, 196, 196, 0) 92.65%
  );
}

.content {
  padding: 50px 0 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1066px;
  width: 100%;
  font-family: "Open Sans", sans-serif;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
  padding: 0;
  margin: 0 0 25px;
}

.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #88898d;
  max-width: 980px;
  padding: 0;
  margin: 0 0 57px;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 94px;
}

.wrapperIcon {
  border-right: 1px solid rgba(196, 196, 196, 0.63);
  /* padding-right: 25px; */
  min-height: 94px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 65px;
  width: 100%;
}

.wrapperText {
  border-right: 1px solid rgba(196, 196, 196, 0.63);
  /* padding-right: 25px; */
  min-height: 94px;
  display: flex;
  align-items: center;
  max-width: 324px;
  width: 100%;
}

.wrapperParagraph {
  border-right: 1px solid rgba(196, 196, 196, 0.63);
  /* padding-right: 25px; */
  min-height: 94px;
  display: flex;
  align-items: center;
}

.icon {
  padding-right: 25px;
}

.text {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #88898d;
  margin: 0;
  padding: 0 25px 0 20px;
}

.paragraph {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #88898d;
  margin: 0;
  padding: 0 0 0 36px;
  max-width: 448px;
}

.button {
  font-style: normal;
  font-weight: 700;
  font-size: 10.5385px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  border: none;
  padding: 11px 27px;
  margin-left: 20px;
  background: #028fe8;
  border-radius: 14.4398px;
  text-decoration: none;
}

@media screen and (max-width: 1160px) {
  .content {
    max-width: 890px;
  }
}

@media screen and (max-width: 980px) {
  .content {
    max-width: 690px;
  }
}

@media screen and (max-width: 600px) {
  .content {
    max-width: 345px;
  }
  .title {
    font-size: 19px;
    line-height: 26px;
    margin: 0 0 15px;
  }
  .subtitle {
    font-size: 13px;
    line-height: 17px;
    margin: 0 0 20px;
  }
}

@media screen and (max-width: 390px) {
  .content {
    max-width: 274px;
  }
}
