.banner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner__content {
  width: 100%;
  max-width: 1708px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner__image {
  margin-top: -5px;
}