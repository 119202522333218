.repair-apple-price__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1066px;
  width: 100%;
  padding: 56px 0 55px;
}

.repair-apple-price__subtitle {
  text-align: center;
  max-width: 790px;
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
  margin: 0 0 26px;
  padding: 0;
}

.repair-apple-price__wrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1px;
  max-width: 993px;
  width: 100%;
  background: #fff;
  box-shadow: 0px 0px 134px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-top: 1px;
  padding: 15px 10px;
}

.repair-apple-price__paragraph {
  margin: 15px 0 0;
  padding: 0;
  max-width: 993px;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  color: #88898d;
}

@media screen and (max-width: 1090px) {
  .repair-apple-price__container {
    max-width: 850px;
  }
}

@media screen and (max-width: 950px) {
  .repair-apple-price__container {
    max-width: 790px;
  }
}

@media screen and (max-width: 830px) {
  .repair-apple-price__container {
    max-width: 680px;
  }
}

@media screen and (max-width: 720px) {
  .repair-apple-price__container {
    max-width: 480px;
  }
  .repair-apple-price__wrapper {
    max-width: 450px;
  }
}

@media screen and (max-width: 500px) {
  .repair-apple-price__container {
    max-width: 100%;
    padding: 45px 20px;
  }
  .repair-mb__price-span {
    max-width: 200px;
  }
  .repair-apple-iphone__price-price {
    align-self: flex-start;
  }
  .repair-apple-price__subtitle {
    font-size: 19px;
    line-height: 26px;
    margin: 0 0 26px;
    padding: 0;
  }
}
