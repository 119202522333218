.form-application {
  /*   visibility: hidden; */
  position: absolute;
  display: none;
  display: flex;
  flex-direction: column;
  max-width: 683px;
  width: 100%;
  padding-top: 28px;
  transform: translateY(-110%);
  /* transition-property: visibility; */
  transition-duration: 1s;
  transition-timing-function: ease-in-out;
  z-index: -1;
}

.form-application_is-opened {
  visibility: visible;
  position: relative;
  /* top: 0%; */
  transform: translateY(0);
  z-index: 0;
}

.form-application__content {
  max-width: 683px;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: "Open Sans", sans-serif;
}

.form-application__inputs {
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  max-width: 683px;
  width: 100%;
}

.form-application__input-box {
  display: flex;
  flex-direction: column;
  max-width: 330px;
  width: 100%;
  margin-bottom: 24px;
}

.form-application__input-big-box {
  display: flex;
  flex-direction: column;
  max-width: 683px;
  width: 100%;
  margin-bottom: 24px;
}

.form-application__lable {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  text-transform: uppercase;
  color: #028fe8;
  margin-bottom: 12px;
}

.form-application__input {
  max-width: 330px;
  width: 100%;
  height: 44px;
  background: #ffffff;
  border: 1px solid #028fe8;
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #028fe8;
  padding-left: 15px;
}

.form-application__input:hover {
  cursor: pointer;
  box-shadow: 0px 0px 15px rgba(149, 243, 255, 0.904);
  transition: box-shadow 0.3s linear;
  border-color: #c4ecfa;
}

.form-application__big-input {
  max-width: 683px;
  width: 100%;
  height: 73px;
  background: #ffffff;
  border: 1px solid #028fe8;
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #028fe8;
  padding-left: 15px;
}

.form-application__big-input:hover {
  cursor: pointer;
  box-shadow: 0px 0px 15px rgba(149, 243, 255, 0.904);
  transition: box-shadow 0.3s linear;
  border-color: #c4ecfa;
}

.form-application__button {
  background: #56ccf2;
  box-shadow: 0px 0px 15px rgb(65 99 149 / 20%);
  border-radius: 2px;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  color: #fff;
  width: 180px;
  height: 51px;
  border: transparent;
  cursor: pointer;
  padding: 0;
  margin-top: 25px;
}

.form-application__button:hover {
  opacity: 0.7;
  transition: opactity 0.3s ease-in-out;
}

.form-application__backdrop {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 5;
}

.form-application__container-popup {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.form-application__contentSuccess {
  font-family: "Open Sans", sans-serif;
  background: #028fe8;
  max-width: 714px;
  width: 100%;
  background: #fff;
  padding: 35px 51px;
  border-radius: 20px;
}

.form-application__success {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 158px 0 60px;
  position: relative;
}
