.repair-iphone-mobile__models-links-content {
  font-family: "Open Sans";
  max-width: 100%;
  padding: 45px 0;
}

.repair-iphone-mobile__models-links-title {
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 26px;
  text-transform: uppercase;
  color: #02e2ff;
  margin: 0 0 29px;
  padding: 0 20px;
}

.repair-iphone-mobile__models-links-container {
  max-width: 100%;
  width: 100%;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.repair-iphone-mobile__models-links-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.repair-iphone-mobile__models-links-item {
  display: flex;
  margin-bottom: 27px;
  align-items: center;
}

.repair-iphone-mobile__models-links-link {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #88898d;
  text-decoration: none;
}

.repair-iphone-mobile__models-links-link:hover {
  transition: 0.3s;
  opacity: 0.5;
  color: #02e2ff;
  border-bottom: 1px solid #02e2ff;
}
