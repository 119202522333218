.section {
  padding: 51px 0 40px;
}

.content {
  font-family: "Open Sans", sans-serif;
  max-width: 345px;
  width: 100%;
  background: #02e2ff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 18px 0 39px;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  padding: 0;
  margin: 0 0 19px;
}

.subtitle {
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 24px;
  color: #ffffff;
  padding: 0;
  margin: 30px 0 15px;
}

.text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  padding: 0;
  margin: 0 0 30px;
  max-width: 290px;
}

.button {
  background: #028fe8;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 0px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 16.6885px;
  width: 210px;
  height: 44px;
  border: none;
}

@media screen and (max-width: 390px) {
  .content {
    max-width: 274px;
  }
  .text {
    max-width: 260px;
  }
}
