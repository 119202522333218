.scroll {
  width: 54px;
  height: 54px;
  background: #fff;
  box-shadow: 0px 0px 54px rgba(2, 134, 255, 0.28);
  display: flex;
  border: none;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  align-self: flex-end;
  position: fixed;
  z-index: 10;
}

.scroll:hover {
  opacity: 0.7;
  transition: opactity 0.3s ease-in-out;
}

.scroll-hidden {
  display: none;
}

.scroll__icon {
  width: 12px;
  height: 6px;
}

@media screen and (max-width: 1440px) {
  .scroll {
    right: calc(1.85vw + 15px);
    bottom: calc(1.85vw + 15px);
  }
}

@media screen and (min-width: 1441px) {
  .scroll {
    right: calc(4.16vw - 20px);
    bottom: calc(4.16vw - 20px);
  }
}
