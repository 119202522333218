.repair-ipad__info-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 1066px;
  width: 100%;
  padding: 75px 0 52px;
}

.repair-ipad__info-title {
  margin: 0 0 64px 0;
  padding: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-transform: uppercase;
  color: #02e2ff;
}

.repair-ipad__info-wrapper {
  display: flex;
}

.repair-ipad__info-subtitle {
  margin: 0 0 30px 0;
  padding: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 31px;
  color: #02e2ff;
}

.repair-ipad__info-article {
  display: flex;
}

.repair-ipad__info-paragraph {
  margin: 0;
  padding: 0;
  max-width: 470px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  color: #88898d;
}

.repair-ipad__info-icon {
  width: 215px;
  height: 215px;
  border-radius: 107.5px;
  background: rgba(255, 255, 255, 0.53);
  box-shadow: 0px 0px 134px rgba(114, 41, 232, 0.13);
  background-image: url("../../images/icons/repair-ip-stick.svg");
  background-repeat: no-repeat;
  background-position: center;
  margin: auto 0 auto 170px;
}

@media screen and (max-width: 1130px) {
  .repair-ipad__info-container {
    max-width: 850px;
  }
}

@media screen and (max-width: 920px) {
  .repair-ipad__info-container {
    max-width: 664px;
  }
  .repair-ipad__info-icon {
    display: none;
  }
  .repair-ipad__info-paragraph {
    max-width: 664px;
  }
}

@media screen and (max-width: 720px) {
  .repair-ipad__info-container {
    max-width: 480px;
    padding: 45px 0;
  }
  .repair-ipad__info-title {
    margin: 0 0 18px 0;
    font-size: 28px;
    line-height: 31px;
  }
  .repair-ipad__info-subtitle {
    margin: 0 0 25px 0;
    font-size: 24px;
    line-height: 26px;
  }
  .repair-ipad__info-paragraph {
    text-align: left;
    font-size: 15px;
    line-height: 22px;
  }
}

@media screen and (max-width: 500px) {
  .repair-ipad__info-container {
    max-width: 100%;
    padding: 45px 20px;
  }
  .repair-ipad__info-title {
    font-size: 22px;
    line-height: 25px;
  }
  .repair-ipad__info-subtitle {
    font-size: 18px;
    line-height: 20px;
  }
  .repair-ipad__info-paragraph {
    font-size: 14px;
    line-height: 21px;
  }
  .repair-ipad__info-border {
    width: 60px;
    height: 7px;
    background: #e4e9ea;
    margin: 0 auto 36px;
  }
}
