.content {
  font-family: "Open Sans", sans-serif;
  max-width: 1066px;
  width: 100%;
  padding: 55px 0 63px;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 0;
  margin: 0 0 25px;
}

.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 25px;
  color: #ffffff;
  margin: 0 0 55px;
  padding: 0;
  max-width: 965px;
}

.fieldset {
  display: flex;
  border: none;
  margin: 0;
  padding: 0;
}

.formTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0 0 25px;
  padding: 0;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 690px;
  width: 100%;
}

.formList {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  max-width: 690px;
  width: 100%;
}

.formItem {
  display: flex;
  flex-direction: column;
  max-width: 330px;
  width: 100%;
}

.formLabel {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0 0 10px;
  padding: 0;
}

.formInput {
  outline: none;
  border: 1px solid #028fe8;
  border-radius: 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #028fe8;
  padding: 6px 15px;
}

.paragraph {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin: 26px 0 0;
  padding: 0;
}

.formButtonActive {
  width: 210.1px;
  height: 44px;
  background: #02e2ff;
  border-radius: 16.6885px;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  margin: 26px 0 0 43px;
  cursor: pointer;
}

.formButton {
  width: 210.1px;
  height: 44px;
  background: #c9c5c5;
  border-radius: 16.6885px;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  margin: 26px 0 0 43px;
  cursor: pointer;
}

.errorText {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #f9d749;
  padding: 0;
  margin: 0;
}

.hiddenErrorText {
  padding: 7px 0;
  margin: 0;
}

@media screen and (max-width: 1160px) {
  .content {
    max-width: 890px;
  }
}

@media screen and (max-width: 980px) {
  .content {
    max-width: 690px;
  }
  .formList {
    flex-direction: column;
  }
  .fieldset {
    flex-direction: column;
    max-width: 340px;
    align-items: center;
  }
  .inputWrapper {
    align-items: flex-start;
    max-width: 420px;
  }
  .paragraph {
    margin: 0;
  }
  .formButton {
    margin: 26px 0 0;
  }
  .formButtonActive {
    margin: 26px 0 0;
  }
}

@media screen and (max-width: 780px) {
  .content {
    max-width: 550px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .title {
    font-size: 26px;
    line-height: 32px;
    margin: 0 0 18px;
  }
  .subtitle {
    font-size: 17px;
    line-height: 23px;
    margin: 0 0 47px;
    text-align: center;
  }
  .formTitle {
    font-size: 26px;
    line-height: 32px;
    margin: 0 0 21px;
  }
  .form {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .content {
    margin: 0;
    max-width: 100%;
    padding: 45px 20px;
  }
  .title {
    font-size: 19px;
    line-height: 26px;
    max-width: 310px;
    margin: 0 0 10px;
  }
  .subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin: 0 0 39px;
    text-align: left;
  }
  .formTitle {
    font-size: 19px;
    line-height: 25px;
    margin: 0 0 17px;
  }
  .formLabel {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    margin: 0 0 8px;
    text-align: center;
  }
  .formInput {
    border-radius: 5px;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    padding: 6px 15px;
    text-align: center;
  }
  .paragraph {
    display: none;
  }
  .fieldset {
    max-width: 100%;
    width: 100%;
  }
  .formButtonActive {
    width: 174px;
    height: 38px;
    border-radius: 14px;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    margin: 15px 0 0 0;
  }
  .formButton {
    width: 174px;
    height: 38px;
    border-radius: 14px;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    margin: 15px 0 0 0;
  }
  .formItem {
    max-width: 100%;
  }
}
