.slider {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.slider__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 904px;
  width: 100%;
  overflow-x: hidden;
}

.slider__items {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
  transition: transform 1.1s ease-in-out;
}

.slider__arrow {
  cursor: pointer;
}

.slider__arrow:hover {
  opacity: .8;
  transition: opacity .3s ease-in-out;
}

.slider__arrow_faded {
  cursor: none;
  opacity: .3;
}

.carousel-root {
  width: 100%;
}

.slide {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1100px) {
  .slider__wrapper {
    max-width: 750px;
  }
}

@media screen and (max-width: 940px) {
  .slider__wrapper {
    max-width: 600px;
  }
}

@media screen and (max-width: 780px) {
  .slider__wrapper {
    max-width: 500px;
  }
}

@media screen and (max-width: 680px) {
  .slider__wrapper {
    max-width: 400px;
  }
}