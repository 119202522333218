.partners-mobile {
  font-family: 'Open Sans', sans-serif;
}

.partners-mobile__container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 45px 0 57px;
  flex-direction: column;
}

.partners-mobile__title {
  max-width: 243px;
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #02E2FF;
}

.partners-mobile__subtitle {
  max-width: 291px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 21px;
  text-align: center;
  color: #88898D;
}
.partners-mobile__logo {
  max-width: 120px;
  margin-bottom: 40px;
}

.partners-mobile__wrapper {
}