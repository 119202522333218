.ac-trust__content {
  background: linear-gradient(
    115.12deg,
    rgba(196, 196, 196, 0.09) 36.68%,
    rgba(196, 196, 196, 0) 88.72%
  );
}

.ac-trust__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 67px 0 88px;
  max-width: 1066px;
  width: 100%;
}

.ac-trust__border {
  width: 183px;
  height: 10px;
  background: #02e2ff;
  margin-bottom: 36px;
}

.ac-trust__text {
  margin: 0 0 20px;
  padding: 0;
  max-width: 590px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #88898d;
}

.ac-trust__text:last-of-type {
  margin-bottom: 0;
}

@media screen and (max-width: 690px) {
  .ac-trust__container {
    max-width: 500px;
  }
}

@media screen and (max-width: 550px) {
  .ac-trust__container {
    max-width: 410px;
  }
}

@media screen and (max-width: 500px) {
  .ac-trust__container {
    max-width: 100%;
    padding: 50px 20px 65px;
  }
  .ac-trust__border {
    width: 80px;
    margin-bottom: 33px;
  }
  .ac-trust__text {
    max-width: 100%;
    margin: 0 0 15px;
  }
}
