.center-mobile__general-page-description-content {
  font-family: "Open Sans";
  max-width: 1066px;
  width: 100%;
  padding: 55px 0 76px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 50px;
}

.center-mobile__general-page-description-title {
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
  padding: 0;
  margin: 0 0 70px;
}

.center-mobile__general-page-description-text {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: justify;
  color: #88898d;
  max-width: 610px;
  margin: 0 35px 0 0;
}

.center-mobile__general-page-description-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media screen and (max-width: 980px) {
  .center-mobile__general-page-description-image {
    display: none;
  }
  .center-mobile__general-page-description-wrapper {
    justify-content: center;
  }
  .center-mobile__general-page-description-title {
    margin: 0 0 50px;
    max-width: 830px;
  }
  .center-mobile__general-page-description-text {
    margin: 0;
  }
}

@media screen and (max-width: 920px) {
  .center-mobile__general-page-description-title {
    font-size: 26px;
    line-height: 32px;
  }
  .center-mobile__general-page-description-text {
    font-size: 16px;
    line-height: 22px;
  }
}

@media screen and (max-width: 600px) {
  .center-mobile__general-page-description-content {
    max-width: 100%;
    margin: 0;
    padding: 42px 20px 58px;
  }
  .center-mobile__general-page-description {
    background-image: url("../../../images/center-mobile-moscow-mobile.svg");
    background-repeat: no-repeat;
    background-position: bottom;
    background-color: linear-gradient(
      115.12deg,
      rgba(196, 196, 196, 0.09) 40.61%,
      rgba(196, 196, 196, 0) 92.65%
    );
    background-size: auto;
  }
  .center-mobile__general-page-description-title {
    font-size: 19px;
    line-height: 26px;
    text-align: left;
    margin: 0 0 20px;
  }
  .center-mobile__general-page-description-text {
    font-size: 14px;
    line-height: 21px;
    text-align: justify;
    color: #231f20;
    margin: 0;
  }
}
