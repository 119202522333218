.repair-iphone__au-container {
  padding: 116px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1066px;
}

.repair-iphone__au-title {
  margin: 0;
  max-width: 648px;
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
}

.repair-iphone__au-border {
  background-color: #e5e5e5;
  width: 87px;
  height: 7px;
  margin: 25px auto 58px;
}

.repair-iphone__au-subtitle {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 31px;
  color: #02e2ff;
  text-align: center;
}

.repair-iphone__au-text {
  margin: 0 0 34px 0;
  padding: 0;
  max-width: 664px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  color: #88898d;
}

.repair-iphone__au-text:last-of-type {
  margin-bottom: 0;
}

@media screen and (max-width: 720px) {
  .repair-iphone__au-container {
    max-width: 480px;
    padding: 45px 0;
  }
  .repair-iphone__au-title {
    font-size: 26px;
    line-height: 32px;
  }
  .repair-iphone__au-subtitle {
    font-size: 24px;
    line-height: 26px;
    text-align: left;
  }
  .repair-iphone__au-text {
    margin: 0 0 38px 0;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    text-align: left;
  }
}

@media screen and (max-width: 500px) {
  .repair-iphone__au-container {
    max-width: 100%;
    padding: 45px 20px;
  }
  .repair-iphone__au-title {
    font-size: 19px;
    line-height: 26px;
    text-align: left;
    margin-bottom: 18px;
  }
  .repair-iphone__au-border {
    display: none;
  }
  .repair-iphone__au-subtitle {
    font-size: 18px;
    line-height: 20px;
  }
  .repair-iphone__au-text {
    margin: 0;
    font-size: 14px;
    line-height: 21px;
  }
  .repair-iphone-au-border-paragraph {
    width: 27.02px;
    height: 0px;
    border: 1px solid #02e2ff;
    margin: 18px auto;
  }
}
