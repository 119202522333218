.page {
  max-width: 1708px;
  margin: 0 auto;
  padding: 0;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  text-rendering: optimizeLegibility;
  font-family: "Open Sans", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
}

a.active {
  color: #028fe8;
}

a.active:hover {
  border-bottom: none;
}

html,
body,
#root {
  height: 100%;
}
