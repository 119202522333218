.companies {
  background-color: grey;
  background-image: url(../../images/fontBlocks/about_font_block.jpg);
}

.companies__container {
  width: 100%;
  max-width: 1066px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 55px;
  padding-bottom: 99px;
}

.companies__title {
  margin: 0;
  padding: 0;
  color: #02e2ff;
  font-size: 33px;
  font-weight: bold;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
}

.companies__cards {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: 952px;
  margin-top: 75px;
}

.companies__card {
  width: 100%;
  max-width: 281px;
}

@media screen and (max-width: 1100px) {
  .companies__title {
    max-width: 850px;
  }
}

@media screen and (max-width: 1000px) {
  .companies__cards {
    max-width: 870px;
  }
}

@media screen and (max-width: 950px) {
  .companies__cards {
    max-width: 840px;
    justify-content: space-around;
    align-items: center;
  }

  .companies__card:last-of-type {
    margin-top: 50px;
  }

  .companies__title {
    max-width: 700px;
  }
}
