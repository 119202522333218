.mac-profi__list-container {
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.04);
}

.mac-profi__list-contant {
  width: 100%;
  max-width: 1066px;
  padding: 66px 0 78px;
}

.mac-profi__list {
  font-family: "Open Sans", sans-serif;
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  max-width: 951px;
  width: 100%;
}

.mac-profi__list-link {
  text-decoration: none;
  color: #88898d;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mac-profi__list-link:hover {
  color: #02e2ff;
}

.mac-profi__list-icon {
  width: 71px;
  height: 71px;
  border-radius: 35.5px;
  background-color: #5becff;
  background-image: url("../../images/icons/mac-profi_list-comp.svg");
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 19px;
}

.mac-profi__list-icon-note {
  width: 71px;
  height: 71px;
  border-radius: 35.5px;
  background-color: #5becff;
  background-image: url("../../images/icons/mac-profi_list-note.svg");
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 19px;
}

.mac-profi__list-icon-ipad {
  width: 71px;
  height: 71px;
  border-radius: 35.5px;
  background-color: #5becff;
  background-image: url("../../images/icons/mac-profi_list-ipad.svg");
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 19px;
}

.mac-profi__list-icon-iphone {
  width: 71px;
  height: 71px;
  border-radius: 35.5px;
  background-color: #5becff;
  background-image: url("../../images/icons/mac-profi_list-iphonr.svg");
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 19px;
}

.mac-profi__list-icon-macpro {
  width: 71px;
  height: 71px;
  border-radius: 35.5px;
  background-color: #5becff;
  background-image: url("../../images/icons/mac-profi_list-pro.svg");
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 19px;
}

.mac-profi__list-icon-macmini {
  width: 71px;
  height: 71px;
  border-radius: 35.5px;
  background-color: #5becff;
  background-image: url("../../images/icons/mac-profi_list-mini.svg");
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 19px;
}

.mac-profi__list-icon-ipod {
  width: 71px;
  height: 71px;
  border-radius: 35.5px;
  background-color: #5becff;
  background-image: url("../../images/icons/mac-profi_list-ipod.svg");
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 19px;
}

.mac-profi__list-icon-ios {
  width: 71px;
  height: 71px;
  border-radius: 35.5px;
  background-color: #5becff;
  background-image: url("../../images/icons/mac-profi_list-ios.svg");
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 19px;
}

.mac-profi__list-repair {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.mac-profi__list-name {
  margin: 0;
  padding: 0;
  font-style: bold;
  font-weight: 900;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

@media screen and (max-width: 1000px) {
  .mac-profi__list {
    max-width: 850px;
  }
}

@media screen and (max-width: 880px) {
  .mac-profi__list {
    max-width: 780px;
  }
}

@media screen and (max-width: 800px) {
  .mac-profi__list-contant {
    padding: 48px 0 42px;
  }
  .mac-profi__list {
    max-width: 650px;
  }
  .mac-profi__list-repair {
    font-size: 12px;
    line-height: 16px;
  }
  .mac-profi__list-name {
    font-size: 12px;
    line-height: 16px;
  }
  .mac-profi__list-icon {
    width: 51px;
    height: 51px;
    border-radius: 50%;
    background-image: url("../../images/icons/mac-profi-list-icon-mobile-imac.svg");
  }
  .mac-profi__list-icon-note {
    width: 51px;
    height: 51px;
    border-radius: 50%;
    background-image: url("../../images/icons/mac-profi-list-icon-mobile-macbook.svg");
  }
  .mac-profi__list-icon-ipad {
    width: 51px;
    height: 51px;
    border-radius: 50%;
    background-image: url("../../images/icons/mac-profi-list-icon-mobile-ipad.svg");
  }
  .mac-profi__list-icon-iphone {
    width: 51px;
    height: 51px;
    border-radius: 50%;
    background-image: url("../../images/icons/mac-profi-list-icon-mobile-iphone.svg");
  }
  .mac-profi__list-icon-macpro {
    width: 51px;
    height: 51px;
    border-radius: 50%;
    background-image: url("../../images/icons/mac-profi-list-icon-mobile-macpro.svg");
  }
  .mac-profi__list-icon-macmini {
    width: 51px;
    height: 51px;
    border-radius: 50%;
    background-image: url("../../images/icons/mac-profi-list-icon-mobile-macmini.svg");
  }
  .mac-profi__list-icon-iwatch {
    width: 51px;
    height: 51px;
    border-radius: 50%;
    background-image: url("../../images/icons/mac-profi-list-icon-mobile-iwatch.svg");
  }
  .mac-profi__list-icon-ipod {
    width: 51px;
    height: 51px;
    border-radius: 50%;
    background-image: url("../../images/icons/mac-profi-list-icon-mobile-ipod.svg");
  }
  .mac-profi__list-icon-ios {
    width: 51px;
    height: 51px;
    border-radius: 50%;
    background-image: url("../../images/icons/mac-profi-list-icon-mobile-ios.svg");
  }
}
