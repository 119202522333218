.about-mobile__container {
  font-family: "Open Sans", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 640px;
  padding: 12px 0 110px;
}

.about-mobile__border-title {
  width: 90px;
  height: 7px;
  background: #e4e9ea;
  margin-bottom: 39px;
}

.about-mobile__title {
  color: #88898d;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 80px;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  letter-spacing: 2.52px;
  min-height: 75px;
}

.about-mobile__logo {
  margin-bottom: 42px;
}

.about-mobile__text-container {
  padding-bottom: 59px;
}

.about-mobile__content {
  margin-bottom: 21px;
}

.about-mobile__subtitle {
  margin: 0 0 32px;
  padding: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  color: #02e2ff;
}

.about-mobile__paragraph {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  color: #88898d;
}

.about-mobile__span {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  color: #88898d;
}

.about-mobile__border-text {
  align-self: center;
  width: 35.02px;
  border: 1px solid #02e2ff;
}

.about-mobile__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
}

.about-mobile__item {
  margin-bottom: 25px;
}

@media screen and (max-width: 950px) {
  .title__text {
    max-width: 750px;
  }
}

@media screen and (max-width: 850px) {
  .about-mobile__border-title {
    margin-top: 40px;
  }
}

@media screen and (max-width: 750px) {
  .about-mobile__container {
    max-width: 500px;
  }
  .about-mobile__title {
    font-size: 40px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 2.52px;
    min-height: 75px;
  }
}

@media screen and (max-width: 550px) {
  .about-mobile__container {
    max-width: 410px;
  }
}

@media screen and (max-width: 500px) {
  .about-mobile__container {
    max-width: 100%;
    padding: 0 20px;
  }

  .about-mobile__border-title {
    width: 60px;
    background: #e4e9ea;
    margin-bottom: 39px;
  }

  .about-mobile__title {
    padding: 0 0 18px;
    font-size: 22px;
    line-height: 25px;
    color: #02e2ff;
  }

  .about-mobile__subtitle {
    font-size: 20px;
    line-height: 26px;
  }

  .about-mobile__paragraph {
    font-size: 14px;
    line-height: 21px;
  }

  .about-mobile__border-text {
    width: 27.02px;
  }

  .about-mobile__span {
    font-size: 14px;
    line-height: 21px;
  }
}
