.table {
  max-width: 100%;
  box-shadow: 0px 4px 15px rgba(47, 128, 237, 0.15);
  border-collapse: collapse;
  border-radius: 22px;
  margin-bottom: 10px;
}

.table__caption {
  text-align: start;
  margin-bottom: 10px;
  color: #88898d;
  font-weight: bold;
  display: none;
}

.table__heading {
  margin: 0;
  background-color: #33e5fcb9;
  color: #fff;
  font-weight: normal;
  border: none;
  text-align: start;
  font-size: 16px;
}

.table__heading:last-of-type {
  text-align: center;
}

.table__heading:first-of-type {
  padding-left: 17px;
}

.table__row:nth-child(2n) {
  background-color: rgba(237, 233, 233, 0.55);
}

.table__row:hover {
  background-color: #def7fab9;
  cursor: pointer;
}

.table__sort-button {
  background: transparent;
  border: none;
  cursor: pointer;
  color: #37393b;
}

.table__sort-button:hover {
  opacity: 0.7;
}

.table__data {
  padding: 10px 6px;
  font-size: 15px;
  border: none;
  text-align: start;
  max-width: 650px;
}

.table__data:first-of-type {
  padding-left: 17px;
}

.table__data:last-of-type {
  text-align: center;
}

.table__btn {
  font-size: 14px;
  border-radius: 5px;
  padding: 8px 11px 8px 7px;
  max-width: 220px;
  cursor: pointer;
  box-shadow: 0px 4px 15px rgba(47, 128, 237, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
}

.table__btn:hover {
  opacity: 0.7;
  transition: opactity 0.3s ease-in-out;
}

.table__btn_type_default {
  color: #fff;
  background-color: rgb(68, 191, 89);
  border: none;
}

.table__btn_type_danger {
  color: #fff;
  background-color: rgb(222, 78, 126);
  border: none;
}

.table__btn_type_post {
  color: #fff;
  background-color: #028fe8;
  border: none;
}

.table__btn_type_disabled {
  opacity: 0.3;
}

.table__btn_type_disabled:hover {
  opacity: 0.3;
}

.table__icon {
  width: 25px;
  height: 25px;
  margin: 0;
  padding: 0;
  fill: #fff;
}

.table__checkbox {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  outline: none;
  border-radius: 50px;
  border: 1px solid #a0a0a0;
  background-color: #fff;
  position: relative;
}

.table__checkbox:hover {
  opacity: 0.7;
  transition: opactity 0.3s ease-in-out;
  cursor: pointer;
}

.table__checkbox:checked::after {
  content: "";
  background-color: #a0a0a0;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 50%;
  display: inline-block;
}

.pagination-nav {
  display: flex;
  align-items: absolute;
}

.table__search {
  max-width: 461px;
  width: 100%;
  min-width: 342px;
  font-size: 15px;
  line-height: 24px;
  padding: 7px;
  color: #88898d;
  font-weight: normal;
  border-radius: 2px;
  border: 2px solid #f6f3f3;
  outline: 0;
}

.table__search,
.table__search-clear,
.table__wrapper {
  position: relative;
}

.table__wrapper {
  display: flex;
}

.table__search-clear {
  border: 1px solid transparent;
  background-color: transparent;
  display: inline-block;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
}

.table__search-clear::after {
  content: url(./newIcons/cross.svg);
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 1;
  right: 18px;
  top: 4px;
  bottom: 0;
  margin: auto;
  cursor: pointer;
}

.table__search-clear:hover {
  opacity: 0.5;
}

.table__search:not(:valid) ~ .table__search-clear {
  display: none;
}

.table__search::-webkit-search-cancel-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.pagination-link {
  margin-right: 7px;
  padding: 0 6px;
  color: #88898d;
  font-size: 15px;
  border: none;
  color: #fff;
  border-radius: 6px;
  cursor: pointer;
  background-color: #33e5fcb9;
  box-shadow: 0px 4px 15px rgba(47, 128, 237, 0.15);
  text-decoration: none;
  height: 21px;
}

.pagination-link:last-of-type {
  margin-right: 0;
}

.pagination-link:hover {
  opacity: 0.7;
  transition: opactity 0.3s ease-in-out;
}

.pagination-counter {
  margin: 0;
  color: #88898d;
  padding: 7px;
  font-size: 14px;
}

.action__buttons-header {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
}

.action__buttons-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.action__buttons {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.table__alert {
  padding: 17px 0 17px 17px;
  color: #88898d;
  border: none;
  text-align: left;
}

@media screen and (max-width: 1190px) {
  .pagination-counter {
    min-width: 80px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }
  .pagination-sign {
    display: none;
  }
  .table__heading {
    font-size: 14px;
  }
  .table__data {
    font-size: 13px;
  }
}

@media screen and (max-width: 575px) {
  .table__data {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .action__buttons {
    flex-wrap: wrap;
    gap: 10px;
  }
  .table__btn {
    font-size: 12px;
  }
  .table__alert {
    padding: 7px 0 7px 7px;
    font-size: 12px;
  }
}

@media screen and (max-width: 530px) {
  .table__heading {
    font-size: 11px;
  }
  .table__heading:first-of-type {
    padding-left: 7px;
  }
  .table__data {
    max-width: 50px;
    font-size: 11px;
    padding: 7px 2px;
    line-height: 17px;
  }
  .table__data:first-of-type {
    padding-left: 7px;
  }
  .pagination-counter {
    font-size: 11px;
    min-width: 50px;
  }
  .table__search {
    min-width: 195px;
    font-size: 13px;
    line-height: 17px;
    padding: 8px;
  }
  .table__icon {
    width: 17px;
    height: 17px;
    margin: 0;
    padding: 0;
    fill: #fff;
  }
  .table__search-clear::after {
    content: url(./newIcons/cross.svg);
  }
}
