.center-mobile-general-page__popup-content {
  font-family: "Open Sans", sans-serif;
  inset: 0;
  position: relative;
  z-index: 50;
}

.center-mobile-general-page__popup-backdrop {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.3);
}

.center-mobile-general-page__popup-center {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-mobile-general-page__popup-qwer-success {
  background: #028fe8;
  max-width: 680px;
  width: 100%;
  background: #fff;
  padding: 34px 39px;
  border-radius: 20px;
}

.center-mobile-general-page__popup-qwer {
  background: #028fe8;
  max-width: 680px;
  width: 100%;
  background: #fff;
  padding: 34px 39px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
}

.center-mobile-general-page__popup-container {
  max-width: 290px;
  width: 100%;
  background: #028fe8;
  border: 1px solid #c9c5c5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 25px;
}

.center-mobile-general-page__popup-title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin: 0 0 25px;
  padding: 0;
  align-self: flex-start;
}

.center-mobile-general-page__popup-wrapper {
  display: flex;
  max-width: 289px;
  width: 100%;
  justify-content: space-between;
}

.center-mobile-general-page__popup-input-wrapper {
  max-width: 138px;
  width: 100%;
}

.center-mobile-general-page__paragraph {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.center-mobile-general-page__errors-text {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #f9d749;
  padding: 0;
  margin: 0 0 6px;
}

.center-mobile-general-page__errors-hidden-text {
  padding: 7px 0;
  margin: 0;
}

.center-mobile-general-page__popup-input {
  max-width: 120px;
  outline: none;
  background: #ffffff;
  border: 1px solid #028fe8;
  border-radius: 6px;
  padding: 0 8px;
  height: 29px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #595a5a;
}

.center-mobile-general-page__popup-input::placeholder {
  color: #abacae;
}

.center-mobile-general-page__popup-textarea {
  resize: none;
  max-width: 273px;
  width: 100%;
  height: 89px;
  outline: none;
  background: #ffffff;
  border: 1px solid #028fe8;
  border-radius: 6px;
  padding: 9px 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #595a5a;
}

.center-mobile-general-page__popup-button {
  background: #c9c5c5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 8px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
  color: #ffffff;
  width: 289px;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 34px;
  cursor: pointer;
}

.center-mobile-general-page__popup-button-active {
  background: #02e2ff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 8px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
  color: #ffffff;
  width: 289px;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 34px;
}

.center-mobile-general-page__popup-box {
  display: flex;
  margin-bottom: 22px;
}

.center-mobile-general-page__popup-avatar {
  width: 40px;
  height: 40px;
  border: 2px solid #ffffff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 50%;
  margin-right: 11px;
}

.center-mobile-general-page__popup-name {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.center-mobile-general-page__popup-city {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.center-mobile-general-page__popup-success {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 138px 0 44px;
  position: relative;
}

.center-mobile-general-page__popup-success-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 34px;
  text-align: center;
  color: #88898d;
  padding: 0;
  margin: 12px 0 55px;
  max-width: 282px;
}

.center-mobile-general-page__popup-success-subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #c9c5c5;
  text-align: center;
  margin: 0;
  padding: 0;
  max-width: 224px;
}

.center-mobile-general-page__popup-success-close {
  position: absolute;
  top: 14px;
  right: 15px;
  cursor: pointer;
}

.center-mobile-general-page__recaptcha {
  align-self: flex-start;
  margin-top: 7px;
}

@media screen and (max-width: 950px) {
  .center-mobile-general-page__popup-qwer {
    flex-direction: column;
    max-width: 380px;
    align-items: center;
  }
  .center-mobile-general-page__recaptcha {
    align-self: center;
    margin-top: 27px;
  }
  .center-mobile-general-page__popup-qwer-success {
    max-width: 402px;
  }
}

@media screen and (max-width: 550px) {
  .center-mobile-general-page__popup-qwer {
    max-width: 274px;
    width: 100%;
    background: #fff;
    padding: 30px 23px 35px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
  }
  .center-mobile-general-page__popup-container {
    max-width: 224px;
    padding: 23px 25px;
  }
  .center-mobile-general-page__recaptcha {
    max-width: 274px;
  }
  .center-mobile-general-page__popup-wrapper {
    max-width: 274px;
    justify-content: space-between;
    flex-direction: column;
  }
  .center-mobile-general-page__popup-button {
    background: #c9c5c5;
    border-radius: 6px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    width: 226px;
    height: 42px;
    margin-top: 6px;
  }
  .center-mobile-general-page__popup-button-active {
    background: #02e2ff;
    border-radius: 6px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    width: 226px;
    height: 42px;
    margin-top: 6px;
  }
  .center-mobile-general-page__popup-title {
    font-size: 10px;
    line-height: 14px;
    color: #ffffff;
    margin: 0 0 12px;
  }
  .center-mobile-general-page__popup-title-border {
    width: 226px;
    border: 1px solid #ffffff;
    margin-bottom: 18px;
  }
  .center-mobile-general-page__popup-input {
    max-width: 210px;
    width: 100%;
    padding: 0 8px;
    font-size: 12px;
    line-height: 16px;
    color: #595a5a;
  }
  .center-mobile-general-page__popup-input-wrapper {
    max-width: 227px;
  }
  .center-mobile-general-page__popup-textarea {
    max-width: 210px;
    padding: 9px 8px;
    font-size: 12px;
    line-height: 16px;
  }
  .center-mobile-general-page__popup-success-title {
    font-size: 15px;
    line-height: 20px;
    margin: 3px 0 88px;
    max-width: 157px;
  }
  .center-mobile-general-page__popup-success-subtitle {
    font-size: 10px;
    line-height: 14px;
    max-width: 157px;
  }
  .center-mobile-general-page__popup-success-finger {
    width: 26px;
    height: 33px;
  }
  .center-mobile-general-page__popup-qwer-success {
    max-width: 242px;
  }
  .center-mobile-general-page__popup-box {
    margin-bottom: 16px;
    flex-direction: column;
  }
}
