.cooperation-cont__contant {
  width: 100%;
  max-width: 1066px;
  padding: 81px 0 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cooperation-cont__title {
  margin: 0 0 93px 0;
  padding: 0;
  max-width: 508px;
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #02e2ff;
}

.cooperation-cont__subtitle {
  margin: 0 0 11px 0;
  padding: 0;
  max-width: 283px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #88898d;
}

.cooperation-cont__text {
  margin: 0 0 17px 0;
  padding: 0;
  max-width: 512px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #88898d;
}

.cooperation-cont__links {
  margin: 0;
  padding: 30px 0 0;
  list-style-type: none;
  display: flex;
  border-top: 1px solid #02e2ff;
  max-width: 486px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.cooperation-cont__item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cooperation-cont__icon {
  margin-right: 18px;
}

.cooperation-cont__link {
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #88898d;
}

.cooperation-cont__link:hover {
  opacity: 0.7;
}

@media screen and (max-width: 510px) {
  .cooperation-cont__contant {
    max-width: 100%;
    padding: 45px 20p 65px;
  }
  .cooperation-cont__title {
    margin: 0 0 41px 0;
    max-width: 293px;
    font-size: 19px;
    line-height: 26px;
  }
  .cooperation-cont__subtitle {
    margin: 0 0 9px 0;
    max-width: 265px;
    font-size: 15px;
  }
  .cooperation-cont__text {
    max-width: 274px;
    font-size: 13px;
    line-height: 19px;
  }
  .cooperation-cont__links {
    padding: 21px 0 0;
    border-top: 1px solid #02e2ff;
    max-width: 196px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .cooperation-cont__icon {
    margin-right: 7px;
  }
  .cooperation-cont__link {
    font-size: 14px;
  }
}
