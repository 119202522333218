.cooperation-with__content {
  flex-direction: column;
  align-items: center;
}

.cooperation-with__container {
  width: 100%;
  max-width: 1066px;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cooperation-with__title {
  margin: 0 0 78px;
  padding: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #02e2ff;
  max-width: 720px;
}

.cooperation-with__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  max-width: 700px;
}

.cooperation-with__item {
  display: flex;
  margin-bottom: 32px;
}

.cooperation-with__item:last-of-type {
  margin-bottom: 0;
}

.cooperation-with__lable {
  font-style: normal;
  font-weight: 400;
  font-size: 33px;
  line-height: 32px;
  text-transform: uppercase;
  color: #02e2ff;
  margin-right: 21px;
}

.cooperation-with__text {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-align: justify;
  color: #88898d;
}

@media screen and (max-width: 780px) {
  .cooperation-with__container {
    max-width: 630px;
  }
}

@media screen and (max-width: 670px) {
  .cooperation-with__container {
    max-width: 480px;
  }
}
