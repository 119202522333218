.partners {
  background-color: #f7f7f7;
}

.partners__container {
  width: 100%;
  max-width: 1066px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 51px;
  border: 1px solid rerd;
}

.partners__counter {
  margin-top: 35px;
  margin-bottom: 58px;
}

.partners__subtitle {
  margin: 0;
  padding: 0;
  max-width: 745px;
  width: 100%;
  color: #88898d;
  font-size: 30px;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
}

.partners__span {
  letter-spacing: 2.13px;
}

.partners__logos {
  list-style-type: none;
  display: grid;
  width: 100%;
  margin: 72px 0 0 0;
  padding: 0;
  grid-template-columns: repeat(auto-fill, 210px);
  gap: 58px 40px;
  justify-content: space-between;
}

.partners__logo {
  display: flex;
  align-items: center;
  justify-self: center;
  
}

.partners__logo:hover {
  opacity: 1;
}

.patrners__image{
  max-width: 210px;
  max-height: 105px;
  cursor: pointer;
  filter: grayscale(1);
}

.patrners__image:hover {
  filter: grayscale(0);
}

@media screen and (max-width: 1100px) {
  .partners__container {
    max-width: 900px;
  }
}

@media screen and (max-width: 950px) {
  .partners__container {
    max-width: 750px;
  }
  .partners__counter {
    max-width: 600px;
  }
}


@media screen and (max-width: 800px) {
  .partners__container {
    max-width: 580px;
  }
  .partners__counter {
    max-width: 500px;
  }
}

@media screen and (max-width: 750px) {
  .partners__container {
    max-width: 500px;
  }
  .partners__counter {
    max-width: 400px;
  }
  .partners__subtitle {
    font-size: 24px;
  }
  .patrners__image {
    max-width: 180px;
  }
}