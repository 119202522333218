.header-burger {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.header-burger__content {
  padding: 18px 24px;
  width: 100%;
  max-width: 1066px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-burger_btn {
  cursor: pointer;
  width: 22px;
  height: 14px;
  background-image: url("../../images/icons/burger_icon.svg");
  background-position: center;
  background-repeat: no-repeat;
}

.header-burger_btn-close {
  z-index: 10;
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-image: url("../../images/icons/burger-icon-close.svg");
  background-position: center;
  background-repeat: no-repeat;
}

.header-burger_btn-close:hover {
  opacity: 0.5;
}

.header-burger__tel-link {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #231f20;
  text-decoration: none;
}
