.popup {
  display: flex;
  align-items: flex-start;
  padding-top: 150px;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transition-property: opacity visibility;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}

.popup_is-opened {
  visibility: visible;
  opacity: 1;
}

.popup-fb__content {
  border-radius: 15px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  margin-top: 18px;
  font-family: "verdana";
  background-color: #fff;
  max-width: 940px;
  width: 100%;
  min-height: 370px;
}

.popup-fb__close {
  position: absolute;
  background-color: transparent;
  border: none;
  border-radius: 10px;
  top: -45px;
  right: -43px;
}

.popup-fb__close:hover {
  opacity: 0.7;
  transition: opactity 0.3s ease-in-out;
  cursor: pointer;
}

.popup-fb__title {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  font-size: 26px;
  color: #4f4f4f;
  margin: 0 0 31px;
  padding: 0;
}

.popup-fb__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 940px;
  width: 100%;
}

.popup-fb__form-content {
  border: none;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  max-width: 940px;
  width: 100%;
}

.popup-fb__box {
  max-width: 235px;
  width: 100%;
}

.popup-fb__form-label {
  font-family: "Open Sans", sans-serif;
  margin: 0 0 17px 0;
  padding: 0;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  color: #858687;
}

.popup-fb__form-input {
  font-family: "Open Sans", sans-serif;
  min-height: 30px;
  border-radius: 2px;
  border: 1px solid #919192;
  width: 100%;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  overflow: hidden;
  outline: none;
}

.popup-fb__form-input:hover {
  cursor: pointer;
  box-shadow: 0px 0px 15px rgba(149, 243, 255, 0.904);
  transition: box-shadow 0.3s linear;
  border-color: #c4ecfa;
}

.popup-fb__form-textarea {
  min-height: 28px;
  border-radius: 2px;
  border: 1px solid #919192;
  margin-right: 14px;
  max-width: 360px;
  width: 100%;
  min-height: 110px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  overflow: hidden;
  outline: none;
}

.popup-fb__form-textarea:hover {
  cursor: pointer;
  box-shadow: 0px 0px 15px rgba(149, 243, 255, 0.904);
  transition: box-shadow 0.3s linear;
  border-color: #c4ecfa;
}

.popup-fb__form-button {
  font-family: "Open Sans", sans-serif;
  width: 223px;
  min-height: 46px;
  letter-spacing: normal;
  text-align: center;
  border-radius: 2px;
  margin: 45px 0 0 0;
  background: #56ccf2;
  box-shadow: 0px 0px 15px rgb(65 99 149 / 20%);
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  color: #fff;
  height: 51px;
  border: transparent;
  cursor: pointer;
}

.popup-fb__form-button:hover {
  opacity: 0.7;
  transition: opactity 0.3s ease-in-out;
}

.popup-fb__box-input {
  display: flex;
  flex-direction: column;
  max-width: 360px;
  width: 100%;
}

.popup-fb__box-input:first-of-type {
  margin-bottom: 20px;
}

.popup-fb__recaptcha {
  margin-top: 30px;
}

@media screen and (max-width: 1160px) {
  .popup-fb__content {
    padding: 30px;
    max-width: 700px;
  }
  .popup-fb__form-content {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 900px) {
  .popup {
    padding-top: 50px;
  }
  .popup-fb__content {
    padding: 30px;
    max-width: 450px;
  }
}

@media screen and (max-width: 610px) {
  .popup {
    z-index: 2;
  }
  .popup-fb__content {
    padding: 20px;
    max-width: 300px;
  }
  .popup-fb__title {
    font-size: 20px;
    margin: 0 0 21px;
  }
  .popup-fb__form-textarea {
    max-width: 247px;
    margin-right: 0;
  }
  .popup-fb__box-input {
    margin-top: 15px;
  }
}

@media screen and (max-width: 419px) {
  .popup-fb__content {
    max-width: 316px;
    padding: 15px 0;
  }
  .popup-fb__title {
    align-self: center;
    margin-bottom: 0;
  }
  .popup-fb__form-content {
    max-width: 306px;
    justify-content: center;
  }
  .popup-fb__close {
    right: -5px;
    top: -60px;
  }
  .popup-fb__box-input {
    max-width: 240px;
  }
  .popup-fb__form-label {
    width: 240px;
  }
}
