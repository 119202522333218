.section {
  background: linear-gradient(
    225deg,
    rgba(196, 196, 196, 0.09) 40.61%,
    rgba(196, 196, 196, 0) 92.65%
  );
}

.content {
  max-width: 1066px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  padding: 50px 0 64px;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
  padding: 0;
  margin: 0 0 50px;
  max-width: 700px;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0 0 60px;
  display: flex;
  max-width: 1060px;
  width: 100%;
  justify-content: space-between;
}

.item {
  max-width: 270px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  margin-bottom: 21px;
}

.text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #88898d;
  margin: 0;
  padding: 0;
}

.subtitle {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 31px;
  text-align: center;
  color: #02e2ff;
  padding: 0;
  margin: 0 0 34px;
}

.priceList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-width: 764px;
  width: 100%;
}

.priceItem {
  display: flex;
  width: 100%;
  max-width: 724px;
  justify-content: space-between;
  align-items: center;
  padding: 4px 20px;
}

.priceText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #88898d;
  margin: 0;
  padding: 0;
}

.pricePrice {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #02e2ff;
  margin: 0;
  padding: 0;
}

.paragraph {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #88898d;
  padding: 0;
  margin: 0 0 35px;
}

.border {
  border: 1px solid rgba(196, 196, 196, 0.63);
  margin: 25px 0 17px;
  width: 503px;
}

.button {
  background: #028fe8;
  border-radius: 21.9231px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  border: none;
  width: 264px;
  padding: 5px 55px;
}

@media screen and (max-width: 1160px) {
  .list {
    max-width: 830px;
  }
  .item {
    max-width: 250px;
  }
}

@media screen and (max-width: 870px) {
  .title {
    font-size: 26px;
    line-height: 32px;
    max-width: 510px;
  }
  .list {
    max-width: 520px;
    flex-direction: column;
    margin: 0 0 10px;
  }
  .item {
    max-width: 520px;
    margin-bottom: 50px;
  }
  .subtitle {
    font-size: 25px;
    line-height: 29px;
  }
  .priceList {
    max-width: 520px;
  }

  .priceItem {
    max-width: 480px;
  }
  .button {
    border-radius: 21.9231px;
    font-size: 14px;
    line-height: 18px;
    width: 219px;
    padding: 5px 55px;
  }
}

@media screen and (max-width: 600px) {
  .content {
    max-width: 100%;
    padding: 35px 20px 50px;
  }
  .title {
    font-size: 19px;
    line-height: 26px;
    margin: 0 0 30px;
  }
  .list {
    max-width: 100%;
  }
  .item {
    max-width: 100%;
  }
  .subtitle {
    font-size: 19px;
    line-height: 26px;
  }
  .priceList {
    max-width: 100%;
  }
  .priceItem {
    max-width: 100%;
    padding: 9px 0;
  }
  .priceText {
    font-size: 12px;
    line-height: 16px;
    max-width: 124px;
  }
  .pricePrice {
    font-size: 14px;
    line-height: 24px;
  }
  .paragraph {
    font-size: 12px;
    line-height: 16px;
    margin: 0 0 25px;
  }

  .border {
    border: 1px solid rgba(196, 196, 196, 0.63);
    margin: 25px 0 27px;
    width: 150px;
  }

  .button {
    border-radius: 14.8085px;
    font-size: 12px;
    line-height: 14px;
    padding: 3px 47px;
    width: 174px;
  }
}

/* @media screen and (max-width: 390px) {
  .content {
    max-width: 294px;
  }
  .header {
    max-width: 264px;
  }
  .list {
    max-width: 294px;
  }
  .item {
    max-width: 272px;
  }
  .priceList {
    max-width: 294px;
  }
  .priceItem {
    max-width: 272px;
  }
} */
