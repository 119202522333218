.repair-iphone__contacts-container {
  padding: 116px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1066px;
}

.repair-iphone__contacts-title {
  max-width: 519px;
  margin: 0 auto 55px;
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
}

.repair-iphone__contacts-text {
  margin: 0;
  padding: 0;
  max-width: 624px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  color: #88898d;
}

@media screen and (max-width: 720px) {
  .repair-iphone__contacts-container {
    max-width: 480px;
  }
  .repair-iphone__contacts-title {
    margin: 0 auto 32px;
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
  }
}

@media screen and (max-width: 500px) {
  .repair-iphone__contacts-container {
    max-width: 100%;
    padding: 45px 20px 65px;
  }
  .repair-iphone__contacts-title {
    margin: 0 auto 32px;
    font-size: 19px;
    line-height: 26px;
  }
  .repair-iphone__contacts-text {
    font-size: 14px;
    line-height: 21px;
    text-align: left;
  }
}
