.center-mobile-general-page-slider {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.center-mobile-general-page-slider__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 140%;
  width: 100%;
  overflow-x: hidden;
}
