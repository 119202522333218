.delivery-banner-mobile__content {
  font-family: "Open Sans";
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.delivery-banner-mobile__banner {
  width: 100%;
}

.delivery-banner-mobile__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.delivery-banner-mobile__item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.delivery-banner-mobile__text {
  margin: 7px 0 23px 0;
  padding: 0 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #231f20;
  max-width: 100%;
}
