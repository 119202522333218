.repair-iphone__info-container {
  max-width: 1046px;
  width: 100%;
  padding: 74px 10px;
}

.repair-iphone__info-title {
  margin: 0 0 64px 0;
  padding: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-transform: uppercase;
  color: #02e2ff;
}

.repair-iphone__info-section {
  display: flex;
  margin-bottom: 83px;
}

.repair-iphone__info-section:last-of-type {
  margin-bottom: 0;
}

.repair-iphone__info-wrapper {
  max-width: 665px;
  align-self: flex-start;
}

.repair-iphone__info-qwerty {
  margin: 0 0 30px 0;
  padding: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 31px;
  color: #02e2ff;
}

.repair-iphone__info-text {
  margin: 0 0 0 0;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  color: #88898d;
}

.repair-iphone__info-image-iphone {
  width: 131px;
  height: 131px;
  border-radius: 65.5px;
  background: rgba(255, 255, 255, 0.53);
  box-shadow: 0px 0px 134px rgba(114, 41, 232, 0.13);
  margin: auto;
  background-image: url("../../images/icons/repair-ip-iphone.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.repair-iphone__info-image-stick {
  width: 215px;
  height: 215px;
  border-radius: 107.5px;
  background: rgba(255, 255, 255, 0.53);
  box-shadow: 0px 0px 134px rgba(114, 41, 232, 0.13);
  margin: auto;
  background-image: url("../../images/icons/repair-ip-stick.svg");
  background-repeat: no-repeat;
  background-position: center;
}

@media screen and (max-width: 1130px) {
  .repair-iphone__info-container {
    max-width: 850px;
  }
  .repair-iphone__info-wrapper {
    max-width: 550px;
  }
}

@media screen and (max-width: 920px) {
  .repair-iphone__info-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .repair-iphone__info-wrapper {
    max-width: 664px;
  }
  .repair-iphone__info-image-iphone {
    display: none;
  }
  .repair-iphone__info-image-stick {
    display: none;
  }
}

@media screen and (max-width: 720px) {
  .repair-iphone__info-container {
    max-width: 480px;
    padding: 45px 0;
  }
  .repair-iphone__info-title {
    margin: 0 0 18px 0;
    font-size: 28px;
    line-height: 31px;
  }
  .repair-iphone__info-qwerty {
    margin: 0 0 25px 0;
    font-size: 24px;
    line-height: 26px;
  }
  .repair-iphone__info-text {
    text-align: left;
    font-size: 15px;
    line-height: 22px;
  }
  .repair-iphone__info-section {
    margin-bottom: 36px;
  }
}

@media screen and (max-width: 500px) {
  .repair-iphone__info-container {
    max-width: 100%;
    padding: 45px 20px;
  }
  .repair-iphone__info-title {
    font-size: 22px;
    line-height: 25px;
  }
  .repair-iphone__info-qwerty {
    font-size: 18px;
    line-height: 20px;
  }
  .repair-iphone__info-text {
    font-size: 14px;
    line-height: 21px;
  }
  .repair-iphone__info-border {
    width: 60px;
    height: 7px;
    background: #e4e9ea;
    margin: 0 auto 36px;
  }
}
