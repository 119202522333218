.center-mobile__general-page-link {
  font-family: "Open Sans", sans-serif;
  max-width: 1066px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 55px 0 69px;
  margin: 0 50px;
}

.center-mobile__general-page-link-columns {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-width: 1047px;
  width: 100%;
  justify-content: space-between;
  display: flex;
}

.center-mobile__general-page-link-item {
  max-width: 417px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.center-mobile__general-page-link-title {
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-transform: uppercase;
  text-align: center;
  color: #02e2ff;
  max-width: 250px;
  padding: 0;
  margin: 0 0 119px;
}

.center-mobile__general-page-link-wrapper {
  border: 1px solid #02e2ff;
  position: relative;
  padding: 118px 70px 54px 50px;
  max-width: 290px;
  width: 100%;
}

.center-mobile__general-page-link-img {
  width: 132px;
  height: 132px;
  border-radius: 50%;
  background: #02e2ff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -65px;
  left: -13px;
}

.center-mobile__general-page-link-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  /* max-width: 290px;
  width: 100%; */
}

.center-mobile__general-page-link-links {
  border-bottom: 1px solid #c9c5c5;
  display: flex;
  margin-bottom: 30px;
}

.center-mobile__general-page-link-link {
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 40px;
  color: #88898d;
}

.first-link {
  width: 200px;
}

@media screen and (max-width: 980px) {
  .center-mobile__general-page-link-wrapper {
    max-width: 240px;
    padding: 73px 41px 20px 34px;
  }
}
