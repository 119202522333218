.header {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.header__content {
  max-width: 1066px;
  padding: 32px 0;
  width: 100%;
  display: flex;
}

.header__wrapper {
  display: flex;
  margin-top: 30px;
  margin-left: 80px;
  width: 100%;
  justify-content: space-between;
}

.header__logo {
  width: 271px;
  height: 118px;
}

.header__logo:hover {
  opacity: 0.8;
  transition: opacity 0.3s ease-in-out;
}

.header__tels {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  max-width: 400px;
  font-family: "verdana";
  margin-top: 8px;
}

.header__tel {
  font-size: 19px;
  font-weight: 500;
  font-style: bold;
  line-height: normal;
  width: 190px;
}

.header__button {
  text-decoration: none;
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  background-color: transparent;
  width: 100%;
  color: #333;
}

.header__button:hover {
  opacity: 0.7;
  transition: opacity 0.5s ease-in-out;
}

.header__link {
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  line-height: 22px;
  width: 223px;
  height: 46px;
  background-color: #028fe8;
  border-radius: 20px;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.header__link:hover {
  opacity: 0.7;
}

@media screen and (max-width: 1100px) {
  .header__content {
    max-width: 900px;
  }
  .header__tels {
    flex-direction: column;
    justify-content: start;
    margin: 0;
  }
  .header__tels {
    max-width: 300px;
  }
  .header__tel:first-of-type {
    margin-bottom: 10px;
  }
  .header__wrapper {
    margin-left: 100px;
  }
}

@media screen and (max-width: 1023px) {
  .header {
    flex-direction: column;
    padding: 0;
  }
}

@media screen and (max-width: 940px) {
  .header__content {
    max-width: 800px;
  }
  .header__wrapper {
    margin-left: 63px;
  }
  .header__tels {
    max-width: 200px;
  }
  .header__tel {
    font-size: 17px;
  }
}
