.feedback-card {
  display: flex;
  flex-direction: column;
  user-select: none;
  margin-right: 50px;
  min-width: 310px;
  height: 460px;
}

.feedback-card__image {
  width: 130px;
  height: 119px;
  margin-bottom: 7px;
}

.feedback-card__tail {
  width: 0px;
  height: 0px;
  margin-left: 40px;
  border-left: 0px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 50px solid #fff;
}

.feedback-card__container {
  width: 333px;
  max-height: 333px;
  border-radius: 10px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 44px 42px 54px 32px;
  overflow-y: scroll;
}

.feedback-card__text {
  color: #262626;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-size: 16px;
  font-style: italic;
  letter-spacing: normal;
  line-height: 24px;
  margin: 0;
  padding: 0;
}

/* @media screen and (max-width: 1100px) {
  .feedback-card {
    margin-right: 60px;
    min-width: 330px;
  }
}

@media screen and (max-width: 940px) {
  .feedback-card {
    margin-right: 50px;
    min-width: 320px;
  }
  .feedback-card {
    width: 320px;
  }
}

@media screen and (max-width: 780px) {
  .feedback-card {
    margin-right: 40px;
    min-width: 310px;
  }
  .feedback-card {
    width: 310px;
  }
} */

/* @media screen and (max-width: 680px) {
  .feedback-card {
    margin-right: 30px;
    min-width: 270px;
  }
  .feedback-card__container {
    width: 270px;
  }
} */