.content {
  font-family: "Open Sans", sans-serif;
  padding: 68px 0 92px;
  max-width: 1066px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
  margin: 0 0 78px;
  padding: 0;
}

.list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  max-width: 1035px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.item {
  border: 0.663342px solid #02e2ff;
  padding: 44px 27px 53px 39px;
  max-width: 219px;
  width: 100%;
  position: relative;
}

.circle {
  width: 87px;
  height: 87px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #02e2ff;
  border-radius: 50%;
  position: absolute;
  top: -44px;
  left: -28px;
}

.subtitle {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #88898d;
  max-width: 201px;
  padding: 0;
  margin: 0 0 16px;
}

.wrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
}

.wrapper:last-of-type {
  margin-bottom: 0;
}

.triangle {
  padding-top: 4px;
  margin-right: 7px;
}

.text {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #88898d;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 1160px) {
  .content {
    max-width: 890px;
  }
  .item {
    margin-top: 50px;
    min-height: 233px;
  }
  .title {
    margin: 0 0 28px;
  }
}

@media screen and (max-width: 780px) {
  .title {
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 21px;
  }
  .subtitle {
    font-size: 14px;
    line-height: 21px;
    margin: 0 0 13px;
  }
  .circle {
    width: 77px;
    height: 77px;
    top: -36px;
    left: -21px;
  }
}

@media screen and (max-width: 600px) {
  .title {
    font-size: 19px;
    line-height: 38px;
    margin: 0 0 2px;
  }
  .item {
    padding: 33px 11px 40px 31px;
    min-height: 187px;
    max-width: 100%;
    margin: 50px 20px 0;
  }
  .circle {
    width: 66px;
    height: 66px;
    top: -36px;
    left: -21px;
  }
  .img {
    width: 30px;
    height: 30px;
  }
  .subtitle {
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 11px;
  }
  .text {
    font-size: 14px;
    line-height: 18px;
  }
  .wrapper {
    margin-bottom: 11px;
  }
}

@media screen and (max-width: 390px) {
  .item {
    max-width: 174px;
  }
}
