.repair-mb__trouble-content {
  background: linear-gradient(
    238deg,
    rgba(196, 196, 196, 0.09) 36.68%,
    rgba(196, 196, 196, 0) 88.72%
  );
}

.repair-mb__trouble-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 55px 0 67px;
  width: 100%;
  max-width: 1066px;
}

.repair-mb__trouble-title {
  margin: 0 0 59px;
  padding: 0;
  max-width: 680px;
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
}

.repair-mb__trouble-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  max-width: 663px;
  width: 100%;
}

.repair-mb__trouble-item {
  display: flex;
  margin-bottom: 30px;
}

.repair-mb__trouble-item:last-of-type {
  margin-bottom: 0;
}

.repair-mb__trouble-lable {
  margin-right: 21px;
  font-style: normal;
  font-weight: 400;
  font-size: 33px;
  line-height: 32px;
  text-transform: uppercase;
  color: #02e2ff;
}

.repair-mb__trouble-subtitle {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #88898d;
  margin: 0 0 10px 0;
  max-width: 600px;
}

.repair-mb__trouble-text {
  max-width: 605px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  color: #88898d;
}

@media screen and (max-width: 720px) {
  .repair-mb__trouble-container {
    max-width: 480px;
  }
  .repair-mb__trouble-title {
    margin: 0 0 45px;
    font-size: 26px;
    line-height: 32px;
  }
  .repair-mb__trouble-text {
    font-size: 14px;
    line-height: 22px;
  }
}

@media screen and (max-width: 500px) {
  .repair-mb__trouble-container {
    padding: 45px 0 47px;
    max-width: 100%;
  }
  .repair-mb__trouble-title {
    margin: 0 0 26px;
    font-weight: 700;
    font-size: 19px;
    line-height: 26px;
    text-align: left;
  }
  .repair-mb__trouble-item {
    flex-direction: column;
    padding: 0 20px;
  }
  .repair-mb__trouble-subtitle {
    line-height: 19px;
    margin: 0 0 8px 0;
  }
  .repair-mb__trouble-text {
    font-size: 12px;
    line-height: 21px;
    text-align: left;
  }
  .repair-mb__trouble-lable {
    margin-right: 0;
    font-size: 22px;
    line-height: 32px;
  }
}
