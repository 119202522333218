.section {
  background: #028fe8;
}

.content {
  max-width: 1066px;
  width: 100%;
  padding: 50px 0;
  font-family: "Open Sans", sans-serif;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(355px, 1fr));
  gap: 30px 0;
}

.icon {
  margin-top: 3px;
}

.link {
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin-left: 8px;
}

@media screen and (max-width: 1160px) {
  .content {
    max-width: 890px;
  }
}

@media screen and (max-width: 980px) {
  .content {
    max-width: 690px;
  }
  .list {
    grid-template-columns: repeat(auto-fit, minmax(345px, 1fr));
  }
}

@media screen and (max-width: 780px) {
  .content {
    max-width: 550px;
  }
  .link {
    font-size: 16px;
    line-height: 20px;
    margin-left: 5px;
  }
}

@media screen and (max-width: 600px) {
  .content {
    max-width: 100%;
    padding: 45px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .link {
    font-size: 14px;
    line-height: 18px;
  }
}

@media screen and (max-width: 390px) {
  .list {
    grid-template-columns: repeat(auto-fit, minmax(274px, 1fr));
  }
}
