.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  font-family: "Open Sans", sans-serif;
}

.emaillink {
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 25px;
  color: #ffffff;
}

@media screen and (max-width: 780px) {
  .emaillink {
    font-size: 17px;
    line-height: 23px;
  }
}

@media screen and (max-width: 600px) {
  .emaillink {
    font-size: 14px;
    line-height: 21px;
  }
}
