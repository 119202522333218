.repair-apple-links-text__container {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1066px;
  width: 100%;
}

@media screen and (max-width: 1090px) {
  .repair-apple-links-text__container {
    max-width: 850px;
  }
}

@media screen and (max-width: 950px) {
  .repair-apple-links-text__container {
    max-width: 790px;
  }
}

@media screen and (max-width: 830px) {
  .repair-apple-links-text__container {
    max-width: 680px;
  }
}

@media screen and (max-width: 720px) {
  .repair-apple-links-text__container {
    max-width: 480px;
  }
}

@media screen and (max-width: 500px) {
  .repair-apple-links-text__container {
    max-width: 100%;
  }
}
