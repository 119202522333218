.section {
  background: linear-gradient(
    225deg,
    rgba(196, 196, 196, 0.09) 40.61%,
    rgba(196, 196, 196, 0) 92.65%
  );
}

.content {
  padding: 50px 0 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 690px;
  width: 100%;
  font-family: "Open Sans", sans-serif;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
  padding: 0;
  margin: 0 0 25px;
}

.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #88898d;
  max-width: 980px;
  padding: 0;
  margin: 0 0 57px;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.text {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #88898d;
  margin: 0 0 15px;
  padding: 0 25px 0 20px;
}

.paragraph {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #88898d;
  margin: 0 0 35px;
  padding: 0;
  max-width: 448px;
}

.button {
  font-style: normal;
  font-weight: 700;
  font-size: 10.5385px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  border: none;
  padding: 11px 27px;
  background: #028fe8;
  border-radius: 14.4398px;
  text-decoration: none;
  margin-bottom: 10px;
}

.img {
  margin-bottom: 10px;
}

.border {
  border: 1px solid rgba(196, 196, 196, 0.63);
  width: 250px;
}

@media screen and (max-width: 780px) {
  .content {
    padding: 45px 0 10px;
    max-width: 550px;
  }
  .title {
    font-size: 26px;
    line-height: 32px;
    margin: 0 0 20px;
  }
  .subtitle {
    font-size: 14px;
    line-height: 24px;
    margin: 0 0 37px;
  }
  .text {
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 15px;
    padding: 0;
  }
  .paragraph {
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 30px;
  }
  .button {
    padding: 11px 38px;
    background: #028fe8;
    border-radius: 14.4398px;
  }
}
