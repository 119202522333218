.feedbacks {
  background-image: url(../../images/fontBlocks/font_for_feedbacks.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 898px;
  background-color: #02e1ff;
}

.feedbacks__container {
  max-width: 1066px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 50px;
}

.feedbacks__title {
  color: #fff;
}

.feedbacks__subtitle {
  max-width: 860px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 24px;
  text-align: center;
  padding: 0;
  margin-top: 31px;
  margin-bottom: 44px;
}

.feedbacks__button {
  width: 223px;
  height: 46px;
  min-height: 46px;
  background-color: #028fe8;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  margin-top: 30px;
}

.feedbacks__button:hover {
  opacity: 0.7;
  transition: opactity 0.3s ease-in-out;
}

@media screen and (max-width: 1100px) {
  .feedbacks__container {
    max-width: 900px;
  }
  .feedbacks__subtitle {
    max-width: 700px;
  }
}

@media screen and (max-width: 940px) {
  .feedbacks__container {
    max-width: 750px;
  }
  .feedbacks__subtitle {
    max-width: 550px;
  }
}

@media screen and (max-width: 780px) {
  .feedbacks__container {
    max-width: 650px;
  }
}

@media screen and (max-width: 680px) {
  .feedbacks__container {
    max-width: 490px;
  }
}
