.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #333;
  font-family: 'Verdana';
}

.footer__content {
  max-width: 1066px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.footer__copyright {
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  color: #fff;
}

@media screen and (max-width: 1100px) {
  .footer__copyright {
    margin-left: 25px;
    margin-right: 25px;
  }
}

@media screen and (max-width: 425px) {
  .footer__content {
    max-width: 274px;
    flex-direction: column;
  }
}