.content {
  max-width: 690px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 55px 0 94px;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
  margin: 0 0 52px;
  padding: 0;
}

.box {
  border: 1px solid #02e2ff;
  max-width: 360px;
  width: 100%;
  padding: 94px 50px 51px 39px;
  position: relative;
  margin-top: 71px;
}

.image {
  width: 132px;
  height: 132px;
  border-radius: 50%;
  background: #02e2ff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -19%;
  left: -10%;
}

.boxTitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #88898d;
  margin: 0 0 15px;
  padding: 0;
}

.boxText {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #88898d;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 780px) {
  .title {
    font-size: 26px;
    line-height: 32px;
    margin: 0 0 32px;
  }
  .image {
    width: 111px;
    height: 111px;
    top: -14%;
    left: -7%;
  }
  .box {
    margin-top: 48px;
  }
}
