.repair-mb__info-container {
  width: 100%;
  max-width: 1066px;
  display: flex;
  justify-content: center;
  padding: 110px 0;
}

.repair-mb__info-img {
  width: 215px;
  height: 215px;
  border-radius: 107px;
  background: rgba(255, 255, 255, 0.53);
  box-shadow: 0px 0px 134px rgba(114, 41, 232, 0.13);
  background-image: url("../../images/repair-mb.svg");
  background-position: center;
  background-repeat: no-repeat;
  margin: 12px 0 0 156px;
}

.repair-mb__info-title {
  margin: 0 0 37px 0;
  padding: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-transform: uppercase;
  color: #02e2ff;
}

.repair-mb__info-subtitle {
  margin: 0 0 9px 0;
  max-width: 470px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #88898d;
}

.repair-mb__info-text {
  margin: 0;
  padding: 0;
  max-width: 470px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  color: #88898d;
}

@media screen and (max-width: 1030px) {
  .repair-mb__info-img {
    margin: 12px 0 0 78px;
  }
}

@media screen and (max-width: 900px) {
  .repair-mb__info-img {
    margin: 12px 0 0 65px;
  }
  .repair-mb__info-wrapper {
    max-width: 380px;
  }
}
