.content {
  font-family: "Open Sans", sans-serif;
  max-width: 1066px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0 97px;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
  padding: 0;
  margin: 0 0 116px;
  max-width: 688px;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  max-width: 989px;
  width: 100%;
  justify-content: space-between;
}

.item {
  border: 1px solid #02e2ff;
  padding: 94px 55px 53px 34px;
  max-width: 358px;
  position: relative;
}

.circle {
  background: #02e2ff;
  width: 132px;
  height: 132px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -10%;
  top: -14%;
}

.subtitle {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #88898d;
  padding: 0;
  margin: 0 0 10px;
}

.text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #88898d;
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 1160px) {
  .list {
    max-width: 890px;
  }
  .item {
    max-width: 310px;
  }
}

@media screen and (max-width: 980px) {
  .list {
    max-width: 989px;
  }
  .item {
    max-width: 358px;
    margin-top: 67px;
  }
  .title {
    margin: 0 0 49px;
  }
}

@media screen and (max-width: 780px) {
  .content {
    max-width: 550px;
  }
  .title {
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
    margin: 0 0 30px;
  }
  .subtitle {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 8px;
  }

  .text {
    font-size: 14px;
    line-height: 22px;
  }
  .item {
    max-width: 310px;
    margin-top: 67px;
  }
}

@media screen and (max-width: 600px) {
  .content {
    max-width: 100%;
    padding: 42px 20px 34px;
  }
  .title {
    font-weight: 700;
    font-size: 19px;
    line-height: 26px;
    margin: 0 0 18px;
  }
  .item {
    padding: 57px 6px 23px 21px;
    max-width: 100%;
    margin: 45px 35px 0;
  }
  .circle {
    width: 80px;
    height: 80px;
    left: -9%;
    top: -10%;
  }
  .image {
    width: 24px;
    height: 35px;
  }
}
