.user {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Open Sans", sans-serif;
}

.user__container {
  width: 100%;
  max-width: 1708px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.user__form-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.user__info {
  width: 470px;
  max-height: 513px;
  padding: 20px 30px;
  box-sizing: border-box;
  border-radius: 2px;
  box-shadow: 0px 4px 15px rgba(47, 128, 237, 0.15);
  background-color: #96a9c8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.user__text-container {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  text-align: justify;
  color: #fff;
}

.user__title {
  font-weight: bold;
  font-size: 24px;
  line-height: 155.1%;
  color: #fff;
  margin-top: 0;
  margin-bottom: 40px;
}

.user__paragraph {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
}

.user__list {
  margin: 20px 0 20px 10px;
  padding: 0;
}

@media screen and (max-width: 1019px) {
  .user__info {
    width: 350px;
  }
}

@media screen and (max-width: 779px) {
  .user__form-wrapper {
    justify-content: center;
  }
  .user__info {
    width: 400px;
  }
}

@media screen and (max-width: 485px) {
  .user__container {
    padding: 20px;
  }
  .user__info {
    width: 350px;
  }
}

@media screen and (max-width: 390px) {
  .user__container {
    padding: 10px;
  }
  .user__info {
    width: 300px;
  }
}
