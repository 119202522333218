.footer-nav {
  width: 100%;
  background-color: #02e1ff;
  background-image: url(../../images/fontBlocks/footer_font_block.png);
  display: flex;
  justify-content: center;
  font-family: "Open Sans", sans-serif;
  color: #ffffff;
}

.footer-nav__content {
  max-width: 1066px;
  background-color: #02e1ff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 34px 138px 34px 63px;
  box-sizing: border-box;
}

.footer-nav__adress-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style-type: none;
  margin: 0;
  padding: 0;
  min-height: 210px;
}

.footer-nav__tels {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 3px;
}

.footer-nav__title {
  padding: 0;
  margin: 0;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}

.footer-nav__tel {
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-decoration: none;
  color: #fff;
}

.footer-nav__tel:hover {
  color: #028fe8;
  transition: color 0.5s ease-in-out;
}

.footer-nav__email {
  text-decoration: none;
  color: #fff;
}

.footer-nav__email:hover {
  color: #028fe8;
  transition: color 0.5s ease-in-out;
}

.footer-nav__adress {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}

.footer-nav__title {
  font-weight: 500;
  margin-bottom: 3px;
}

.footer-nav__span {
  font-size: 16px;
  margin: 0;
  font-weight: 700;
  margin: 0 0 0 20px;
}

.footer-nav__logo {
  width: 267px;
}

.footer-nav__adress-item {
  font-size: 16px;
}

.footer-nav__links {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  gap: 10px;
  max-width: 317px;
  flex-wrap: wrap;
  min-height: 210px;
}

.footer-nav__link {
  padding: 0;
  margin: 0;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  max-width: 156px;
}

.footer-nav__link:hover {
  color: #028fe8;
  transition: color 0.5s ease-in-out;
}

.footer-nav__logo-link:hover {
  opacity: 0.8;
  transition: opacity 0.3s ease-in-out;
}

.footer-nav__link:visited {
  color: #ffffff;
}

@media screen and (max-width: 1100px) {
  .footer-nav__content {
    max-width: 900px;
    padding: 34px 63px 34px 63px;
  }
}

@media screen and (max-width: 940px) {
  .footer-nav__content {
    max-width: 800px;
    padding: 34px 30px 34px 30px;
  }
}

@media screen and (max-width: 840px) {
  .footer-nav__content {
    max-width: 700px;
    padding: 34px 30px 34px 30px;
    flex-direction: column;
    align-items: center;
  }
  .footer-nav__title {
    text-align: center;
  }
  .footer-nav__tels {
    align-items: center;
  }
  .footer-nav__tel {
    text-align: center;
  }
  .footer-nav__adress {
    align-items: center;
  }
  .ancor-links-li {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .page-link__li {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .footer-nav__adress-li {
    margin-bottom: 30px;
  }
  .footer-nav__adress-li:last-of-type {
    margin-bottom: 45px;
  }
  .footer-nav__general {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 770px) {
  .footer-nav__content {
    max-width: 500px;
  }
}

@media screen and (max-width: 520px) {
  .footer-nav__content {
    max-width: 274px;
    flex-direction: column;
    padding: 34px 0 71px;
    align-items: center;
  }
}
