.cooperation-info {
  flex-direction: column;
}

.cooperation-info__container {
  display: flex;
  flex-direction: column;
  align-self: center;
  max-width: 1066px;
  width: 100%;
  padding: 57px 0 76px;
}

.cooperation-info__title {
  margin: 0 auto;
  padding: 0;
  max-width: 770px;
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
}

.cooperation-info__border {
  max-width: 85px;
  width: 100%;
  height: 7px;
  background-color: #e4e9ea;
  margin: 26px auto 78px;
}

.cooperation-info__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.cooperation-info__list-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 55px;
}

.cooperation-info__list-box:last-of-type {
  margin-bottom: 0;
}

.cooperation-info__list-image {
  margin-bottom: 25px;
}

.cooperation-info__list-text {
  margin: 0;
  padding: 0;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 31px;
  color: #88898d;
  max-width: 640px;
}

@media screen and (max-width: 780px) {
  .cooperation-info__container {
    max-width: 630px;
  }
}

@media screen and (max-width: 670px) {
  .cooperation-info__container {
    max-width: 480px;
  }
}

@media screen and (max-width: 500px) {
  .cooperation-info__container {
    width: 100%;
    max-width: 100%;
    padding: 24px 0 45px;
  }
  .cooperation-info__title {
    font-size: 22px;
    line-height: 25px;
    padding: 0 20px;
  }
  .cooperation-info__border {
    max-width: 60px;
    background-color: #e4e9ea;
    margin: 18px auto 41px;
  }
  .cooperation-info__list-image {
    margin-bottom: 18px;
  }
  .cooperation-info__list-text {
    font-size: 15px;
    line-height: 20px;
    color: #88898d;
  }
  .cooperation-info__list-box {
    margin-bottom: 36px;
  }
  .cooperation-info__list {
    padding: 0 20px;
  }
}
