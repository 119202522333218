.repair-apple-table-mobile__content {
  border: 3px solid #d9d9d9;
  border-radius: 30px;
  max-width: 713px;
  width: 100%;
  padding: 10px 0 28px;
  margin-bottom: 20px;
}

.repair-apple-table-mobile__titles {
  display: flex;
  background: #d9d9d9;
  padding: 3px 0;
  justify-content: space-between;
}

.repair-apple-table-mobile__titles-wrapper {
  display: flex;
  max-width: 261px;
  width: 100%;
  gap: 9px;
}

.repair-apple-table-mobile__title {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: justify;
  color: #88898d;
  margin: 0;
  padding: 0;
}

.repair-apple-table-mobile__title-name {
  max-width: 166px;
  width: 100%;
  background-color: #fff;
  border-radius: 3px;
  padding: 11px 9px;
}

.repair-apple-table-mobile__title-time {
  max-width: 112px;
  width: 100%;
  background-color: #fff;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.repair-apple-table-mobile__title-price {
  max-width: 140px;
  width: 100%;
  background-color: #fff;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.repair-apple-table-mobile__list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.repair-apple-table-mobile__item {
  display: flex;
  border-bottom: 1px solid #d9d9d9;
  padding: 14px 0;
  justify-content: space-between;
}

.repair-apple-table-mobile__text-name {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: left;
  color: #88898d;
  padding: 0;
  margin: 0;
  max-width: 165px;
  width: 100%;
  padding-left: 12px;
  display: flex;
  align-items: center;
}

.repair-apple-table-mobile__text-time {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #88898d;
  padding: 0;
  margin: 0;
  max-width: 112px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.repair-apple-table-mobile__price {
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 10px;
  color: #02e2ff;
  text-align: center;
  padding: 0;
  margin: 0;
  max-width: 140px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.repair-apple-table__price-padding-img {
  padding-right: 21px;
}

@media screen and (max-width: 500px) {
  .repair-apple-table__price-padding-img {
    padding-right: 15px;
  }
  .repair-apple-table-mobile__titles-wrapper {
    gap: 6px;
  }
  .repair-apple-table-mobile__title-name {
    max-width: 93px;
    padding: 5px 9px;
    margin-right: 6px;
  }
  .repair-apple-table-mobile__title-time {
    max-width: 71px;
    text-align: center;
  }
  .repair-apple-table-mobile__title-price {
    max-width: 74px;
    text-align: center;
  }
  .repair-apple-table-mobile__text-name {
    margin-right: 6px;
    max-width: 98px;
    padding-left: 10px;
    text-align: left;
  }
  .repair-apple-table-mobile__text-time {
    text-align: center;
    max-width: 71px;
    width: 100%;
  }
  .repair-apple-table-mobile__price {
    text-align: center;
    padding: 0;
    margin: 0;
    max-width: 74px;
    width: 100%;
  }
  .repair-apple-table-mobile__item {
    padding: 8px 0;
  }
}
