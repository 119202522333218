.element-title {
  font-family: 'Open Sans', sans-serif;
  max-width: 165px;
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  text-transform: uppercase;
}
