.center-mobile__general-page-feedbacks {
  background: #028fe8;
}

.center-mobile__general-page-feedbacks-container {
  font-family: "Open Sans", sans-serif;
  max-width: 1120px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 55px 0 72px;
}

.center-mobile__general-page-feedbacks-title {
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  padding: 0;
  margin: 0 0 101px;
}

.center-mobile__general-page-feedbacks-title-mobile {
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  padding: 0;
  margin: 0 0 56px;
}

.center-mobile__general-page-feedbacks-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-width: 1060px;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  transition: transform 1.1s ease-in-out;
}

.center-mobile__general-page-feedbacks-list-mobile {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-width: 800px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.center-mobile__general-page-feedback-item {
  max-width: 340px;
  min-width: 340px;
  background: #ffffff;
  position: relative;
  padding: 58px 30px 30px;
  background-image: url(../../../images/icons/center-mobile-feedbacks-arrow.svg),
    url(../../../images/icons/center-mobile-feedbacks-arrow2.svg);
  background-repeat: no-repeat;
  background-position: left 9px top 30px, right 10px bottom 17px;
  box-sizing: border-box;
}

.center-mobile__general-page-feedback-item-mobile {
  max-width: 280px;
  width: 100%;
  background: #ffffff;
  position: relative;
  padding: 58px 30px 30px;
  background-image: url(../../../images/icons/center-mobile-feedbacks-arrow.svg),
    url(../../../images/icons/center-mobile-feedbacks-arrow2.svg);
  background-repeat: no-repeat;
  background-position: left 9px top 30px, right 10px bottom 17px;
  margin-top: 50px;
  height: 170px;
}

.center-mobile__general-page-feedbacks-img {
  border-radius: 50%;
  top: -18%;
  left: 36%;
  position: absolute;
  width: 89px;
  height: 89px;
}

.center-mobile__general-page-feedbacks-name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 15.9px;
  line-height: 26px;
  color: #4e4e4e;
  padding: 0;
  margin: 0 0 7px;
}

.center-mobile__general-page-feedbacks-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #88898d;
  padding: 0;
  margin: 0;
  resize: none;
  border: none;
  max-width: 280px;
  width: 100%;
  height: 132px;
  overflow: scroll;
}

.center-mobile__general-page-feedbacks-button {
  background: #02e2ff;
  box-shadow: 0px 7.8534px 7.8534px rgba(0, 0, 0, 0.25);
  border-radius: 19.6335px;
  color: #ffffff;
  border: none;
  width: 300px;
  padding: 11px 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 15.7068px;
  line-height: 39px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 72px;
}

@media screen and (max-width: 600px) {
  .center-mobile__general-page-feedbacks-container {
    max-width: 345px;
    padding: 30px 0 45px;
  }
  .center-mobile__general-page-feedbacks-button {
    box-shadow: 0px 5.57592px 5.57592px rgba(0, 0, 0, 0.25);
    border-radius: 13.9398px;
    width: 213px;
    height: 45px;
    font-size: 14px;
    line-height: 28px;
    margin-top: 40px;
  }
  .center-mobile__general-page-feedbacks-name {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 800;
    font-size: 15.9px;
    line-height: 26px;
  }
  .center-mobile__general-page-feedbacks-text {
    font-size: 13px;
    line-height: 17px;
  }
  .center-mobile__general-page-feedbacks-title {
    font-size: 19px;
    line-height: 22px;
    margin: 0 0 30px;
  }
  .center-mobile__general-page-feedbacks-img {
    left: 36%;
  }
}

@media screen and (max-width: 390px) {
  .center-mobile__general-page-feedbacks-img {
    left: 33%;
  }
}
