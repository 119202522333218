.content {
  max-width: 1066px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Open Sans", sans-serif;
  padding: 50px 0 80px;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
  padding: 0;
  margin: 0 0 35px;
}

.header {
  max-width: 700px;
  width: 100%;
  padding: 12px 44px 12px 20px;
  background: #ffffff;
  box-shadow: 0px 0px 134px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerText {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: flex-end;
  color: #88898d;
  margin: 0;
  padding: 0;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-width: 764px;
  width: 100%;
}

.item {
  display: flex;
  width: 100%;
  max-width: 724px;
  justify-content: space-between;
  align-items: center;
  padding: 4px 20px;
}

.priceText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #88898d;
  margin: 0;
  padding: 0;
}

.pricePrice {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #02e2ff;
  margin: 0;
  padding: 0;
}

.paragraph {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #88898d;
  padding: 0;
  margin: 0;
}

.border {
  border: 1px solid rgba(196, 196, 196, 0.63);
  margin: 25px 0 17px;
  width: 503px;
}

@media screen and (max-width: 870px) {
  .title {
    font-size: 26px;
    line-height: 32px;
    max-width: 510px;
  }
  .header {
    max-width: 456px;
  }
  .list {
    max-width: 520px;
  }
  .item {
    max-width: 480px;
  }
}

@media screen and (max-width: 600px) {
  .content {
    max-width: 100%;
    padding: 40px 20px 57px;
  }
  .title {
    font-size: 19px;
    line-height: 26px;
    margin: 0 0 25px;
  }
  .header {
    max-width: 100%;
    padding: 17px 0 17px 0;
  }
  .headerText {
    font-size: 12px;
    line-height: 16px;
  }
  .list {
    max-width: 100%;
  }
  .item {
    max-width: 100%;
    padding: 4px 0;
  }
  .priceText {
    font-size: 12px;
    line-height: 16px;
    max-width: 124px;
  }
  .pricePrice {
    font-size: 14px;
    line-height: 24px;
  }
  .border {
    border: 1px solid rgba(196, 196, 196, 0.63);
    margin: 25px 0 27px;
    width: 150px;
  }
  .paragraph {
    font-size: 12px;
    line-height: 16px;
    margin: 0;
  }
}
