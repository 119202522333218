.delivery-banner {
  z-index: 1;
}

.delivery-banner__container {
  width: 100%;
  max-width: 1708px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url(../../images/banners/delivery_page_banner.png);
  background-size: 100% 100%;
  min-height: 730px;
}

.delivery-banner__content {
  max-width: 1060px;
  width: 100%;
}

.delivery-banner__list {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  z-index: 2;
  max-width: 730px;
}

.delivery-banner__item {
  margin-bottom: 15px;
  max-width: max-content;
}

.delivery-banner__item:last-of-type {
  margin-bottom: 0;
}

.delivery-banner__text {
  background-color: #028fe8;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 54px;
  color: #ffffff;
  text-shadow: 0px 4px 34px rgba(0, 0, 0, 0.25);
  margin: 0;
  padding: 0 12px;
}

@media screen and (max-width: 1100px) {
  .delivery-banner__container {
    min-height: 650px;
  }
  .delivery-banner__content {
    max-width: 850px;
  }
}

@media screen and (max-width: 890px) {
  .delivery-banner__container {
    min-height: 500px;
  }
  .delivery-banner__content {
    max-width: 750px;
  }
  .delivery-banner__list {
    max-width: 650px;
  }
  .delivery-banner__item {
    margin-bottom: 10px;
  }
  .delivery-banner__text {
    font-size: 20px;
    line-height: 45px;
    padding: 0 12px;
  }
}

@media screen and (max-width: 800px) {
  .delivery-banner__container {
    min-height: 380px;
  }
  .delivery-banner__content {
    max-width: 600px;
  }
  .delivery-banner__list {
    max-width: 540px;
  }
  .delivery-banner__item {
    margin-bottom: 8px;
  }
  .delivery-banner__text {
    font-size: 18px;
    line-height: 40px;
    padding: 0 8px;
  }
}
