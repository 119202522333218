.repair-mb__group-links-content {
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.repair-mb__group-banner-container {
  width: 100%;
  max-width: 1066px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.repair-mb__group-list-container {
  width: 100%;
  max-width: 1066px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.repair-mb__group-links-title {
  max-width: 790px;
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
  margin: 75px auto 20px;
  padding: 0;
}

.repair-mb__group-links-text {
  max-width: 993px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  color: #88898d;
  margin: 17px auto 19px;
  padding: 0;
}

.repair-mb__group-links-subtitle {
  text-align: center;
  max-width: 790px;
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
  margin: 20px auto 26px;
  padding: 0;
}

.repair-mb__group-links-paragraph {
  margin: 0 0 10px;
  padding: 0;
  max-width: 993px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  color: #88898d;
}

.repair-mb__group-links-item {
  list-style-type: none;
}

.repair-mb__group-links-array {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  color: #02e2ff;
}

.repair-mb__group-links-array:hover {
  color: #028fe8;
  transition: color 0.5s ease-in-out;
}

@media screen and (max-width: 1090px) {
  .repair-mb__group-list-container {
    max-width: 850px;
  }
  .repair-mb__group-links-title {
    max-width: 850px;
  }
  .repair-mb__group-links-text {
    max-width: 850px;
  }
}

@media screen and (max-width: 950px) {
  .repair-mb__group-list-container {
    max-width: 790px;
  }
  .repair-mb__group-links-text {
    max-width: 790px;
  }
}

@media screen and (max-width: 830px) {
  .repair-mb__group-list-container {
    max-width: 680px;
  }
  .repair-mb__group-links-title {
    max-width: 680px;
  }
  .repair-mb__group-links-text {
    max-width: 680px;
  }
}

@media screen and (max-width: 720px) {
  .repair-mb__group-links-text {
    max-width: 480px;
  }
  .repair-mb__group-links-title {
    max-width: 480px;
    font-size: 28px;
    line-height: 29px;
  }
  .repair-mb__group-links-subtitle {
    font-size: 26px;
    line-height: 32px;
    text-align: left;
    margin: 45px auto 20px;
  }
  .repair-mb__group-links-border {
    width: 60px;
    height: 7px;
    background: #e4e9ea;
    margin: 0 auto 36px;
  }
  .repair-mb__group-list-container {
    max-width: 480px;
  }
}

@media screen and (max-width: 500px) {
  .repair-mb__group-links-text {
    max-width: 100%;
    padding: 0 20px;
  }
  .repair-mb__group-links-title {
    max-width: 100%;
    padding: 0 20px;
    font-size: 22px;
    line-height: 25px;
  }
  .repair-mb__group-links-subtitle {
    font-size: 19px;
    line-height: 26px;
    padding: 0 20px;
  }
  .repair-mb__group-links-paragraph {
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    padding: 0 20px;
  }
  .repair-mb__group-list-container {
    max-width: 100%;
  }
  .repair-mb__group-links-array {
    font-size: 14px;
    line-height: 21px;
  }
}
