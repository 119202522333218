.mac-profi-list__mobile-container {
  padding: 21px 0 35px;
  max-width: 600px;
  width: 100%;
}

.mac-profi-list__mobile-list {
  font-family: "Open Sans", sans-serif;
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.mac-profi-list__mobile-link {
  text-decoration: none;
  color: #88898d;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mac-profi-list__mobile-link:hover {
  color: #02e2ff;
}

.mac-profi-list__mobile-repair {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}

.mac-profi-list__mobile-name {
  margin: 0;
  padding: 0;
  font-style: bold;
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}

.mac-profi-list__mobile-icon {
  width: 51px;
  height: 51px;
  border-radius: 50%;
  background-color: #5becff;
  background-image: url("../../images/icons/mac-profi-list-icon-mobile-imac.svg");
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 19px;
}

.mac-profi-list__mobile-icon-note {
  width: 51px;
  height: 51px;
  border-radius: 50%;
  background-color: #5becff;
  background-image: url("../../images/icons/mac-profi-list-icon-mobile-macbook.svg");
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 19px;
}

.mac-profi-list__mobile-icon-ipad {
  width: 51px;
  height: 51px;
  border-radius: 50%;
  background-color: #5becff;
  background-image: url("../../images/icons/mac-profi-list-icon-mobile-ipad.svg");
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 19px;
}

.mac-profi-list__mobile-icon-iphone {
  width: 51px;
  height: 51px;
  border-radius: 50%;
  background-color: #5becff;
  background-image: url("../../images/icons/mac-profi-list-icon-mobile-iphone.svg");
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 19px;
}

.mac-profi-list__mobile-icon-macpro {
  width: 51px;
  height: 51px;
  border-radius: 50%;
  background-color: #5becff;
  background-image: url("../../images/icons/mac-profi-list-icon-mobile-macpro.svg");
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 19px;
}

.mac-profi-list__mobile-icon-macmini {
  width: 51px;
  height: 51px;
  border-radius: 50%;
  background-color: #5becff;
  background-image: url("../../images/icons/mac-profi-list-icon-mobile-macmini.svg");
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 19px;
}

.mac-profi-list__mobile-icon-iwatch {
  width: 51px;
  height: 51px;
  border-radius: 50%;
  background-color: #5becff;
  background-image: url("../../images/icons/mac-profi-list-icon-mobile-iwatch.svg");
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 19px;
}

.mac-profi-list__mobile-icon-ipod {
  width: 51px;
  height: 51px;
  border-radius: 50%;
  background-color: #5becff;
  background-image: url("../../images/icons/mac-profi-list-icon-mobile-ipod.svg");
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 19px;
}

.mac-profi-list__mobile-icon-ios {
  width: 51px;
  height: 51px;
  border-radius: 50%;
  background-color: #5becff;
  background-image: url("../../images/icons/mac-profi-list-icon-mobile-ios.svg");
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 19px;
}

.rec.rec-arrow {
  display: none;
}

.rec.rec-dot {
  width: 8px;
  height: 8px;
  background: rgba(217, 217, 217, 0.55);
  border: none;
  box-shadow: none;
}

.rec.rec-dot_active {
  width: 10px;
  height: 10px;
  background: #d9d9d9;
  border: none;
  box-shadow: none;
}
