.section {
  background: linear-gradient(
    225deg,
    rgba(196, 196, 196, 0.09) 40.61%,
    rgba(196, 196, 196, 0) 92.65%
  );
}

.content {
  max-width: 1066px;
  width: 100%;
  padding: 34px 0 64px;
  font-family: "Open Sans", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
  padding: 0;
  margin: 0 0 52px;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-width: 970px;
  width: 100%;
}

.item {
  display: flex;
  margin-bottom: 35px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 838px;
}

.circle {
  background: #02e2ff;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 10px;
}

.number {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.text {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #88898d;
  margin: 19px 0 6px;
  padding: 0;
}

.paragraph {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #88898d;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 1160px) {
  .content {
    max-width: 890px;
  }
  .wrapper {
    max-width: 780px;
  }
}

@media screen and (max-width: 980px) {
  .content {
    max-width: 690px;
  }
  .wrapper {
    max-width: 620px;
  }
}

@media screen and (max-width: 780px) {
  .content {
    max-width: 550px;
    padding: 34px 0 50px;
  }
  .title {
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 45px;
  }
  .text {
    font-size: 14px;
    line-height: 20px;
  }

  .paragraph {
    font-size: 14px;
    line-height: 20px;
  }
  .wrapper {
    max-width: 480px;
  }
}
