.wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: hidden;
  width: 100%;
  max-width: 345px;
  position: relative;
  max-height: 368px;
  min-height: 368px;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  transition: transform 1.1s ease-in-out;
}

.buttons {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 10;
  max-height: 368px;
  min-height: 368px;
}
