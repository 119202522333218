.item {
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-height: 368px;
  min-height: 368px;
  max-width: 100%;
}

.circle {
  background: #02e2ff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 35px 0 15px;
}

.number {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 24px;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.text {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 17px;
  text-align: center;
  color: #88898d;
  padding: 0;
  margin: 0 0 10px;
}

.paragraph {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  text-align: center;
  color: #88898d;
  padding: 0;
}
