.center-mobile__general-page-advantages {
  font-family: "Open Sans", sans-serif;
  max-width: 1066px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 55px 0 74px;
  margin: 0 70px;
}

.center-mobile__general-page-advantages-title {
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-transform: uppercase;
  color: #02e2ff;
  padding: 0;
  margin: 0 0 55px;
}

.center-mobile__general-page-advantages-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  max-width: 901px;
  width: 100%;
}

.center-mobile__general-page-advantages-item {
  max-width: 181px;
}

.center-mobile__general-page-advantages-image-file {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background-color: #02e2ff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  background-image: url("../../../images/icons/center-mobile-advantage-icon1.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.center-mobile__general-page-advantages-image-finger {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background-color: #02e2ff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  background-image: url("../../../images/icons/center-mobile-advantage-icon2.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.center-mobile__general-page-advantages-image-dialogue {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background-color: #02e2ff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  background-image: url("../../../images/icons/center-mobile-advantage-icon3.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.center-mobile__general-page-advantages-subtitle {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  color: #88898d;
  padding: 0;
  margin: 0 0 15px;
}

.center-mobile__general-page-advantages-text {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #88898d;
}

@media screen and (max-width: 920px) {
  .center-mobile__general-page-advantages-title {
    font-size: 26px;
    line-height: 32px;
  }
  .center-mobile__general-page-advantages-subtitle {
    font-size: 22px;
    line-height: 26px;
  }
  .center-mobile__general-page-advantages-text {
    font-size: 16px;
    line-height: 21px;
  }
  .center-mobile__general-page-advantages-image-file {
    width: 120px;
    height: 120px;
    align-self: center;
  }
  .center-mobile__general-page-advantages-image-finger {
    width: 120px;
    height: 120px;
    align-self: center;
  }
  .center-mobile__general-page-advantages-image-dialogue {
    width: 120px;
    height: 120px;
    align-self: center;
  }
  .center-mobile__general-page-advantages-item {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 720px) {
  .center-mobile__general-page-advantages-list {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .center-mobile__general-page-advantages-item {
    max-width: 400px;
  }
  .center-mobile__general-page-advantages-subtitle {
    text-align: center;
  }
  .center-mobile__general-page-advantages-text {
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .center-mobile__general-page-advantages {
    max-width: 100%;
    padding: 40px 20px 20px;
    margin: 0;
  }
  .center-mobile__general-page-advantages-image-file {
    width: 80px;
    height: 80px;
    align-self: center;
    background-image: url("../../../images/icons/center-mobile-adventage-icon1-mobile.svg");
  }
  .center-mobile__general-page-advantages-image-finger {
    width: 80px;
    height: 80px;
    align-self: center;
    background-image: url("../../../images/icons/center-mobile-adventage-icon2-mobile.svg");
  }
  .center-mobile__general-page-advantages-image-dialogue {
    width: 80px;
    height: 80px;
    align-self: center;
    background-image: url("../../../images/icons/center-mobile-adventage-icon3-mobile.svg");
  }
  .center-mobile__general-page-advantages-title {
    font-weight: 700;
    font-size: 19px;
    line-height: 26px;
    padding: 0;
    margin: 0 0 25px;
  }
  .center-mobile__general-page-advantages-subtitle {
    font-size: 19px;
    line-height: 22px;
    color: #88898d;
    padding: 0;
    margin: 0 0 4px;
  }
  .center-mobile__general-page-advantages-text {
    font-size: 13px;
    line-height: 17px;
    margin: 0 0 25px;
  }
}

@media screen and (max-width: 390px) {
  .center-mobile__general-page-advantages {
    max-width: 274px;
  }
}
