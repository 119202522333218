.content {
  max-width: 1066px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Open Sans", sans-serif;
  padding: 50px 0 74px;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 31px;
  color: #02e2ff;
  transform: rotate(0.08deg);
  padding: 0;
  margin: 0 0 50px;
  max-width: 904px;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
}

.icon {
  margin-top: 5px;
}

.text {
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 25px;
  color: #88898d;
  padding: 0;
  margin: 0 0 0 10px;
  max-width: 1020px;
}

@media screen and (max-width: 1160px) {
  .content {
    max-width: 890px;
  }
}

@media screen and (max-width: 980px) {
  .content {
    max-width: 690px;
  }
}

@media screen and (max-width: 780px) {
  .content {
    max-width: 550px;
    padding: 43px 0 60px;
  }
  .title {
    font-size: 24px;
    line-height: 25px;
    margin: 0 0 41px;
  }
  .text {
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
  }
}

@media screen and (max-width: 600px) {
  .content {
    max-width: 100%;
    padding: 35px 20px 44px;
  }
  .title {
    font-size: 18px;
    line-height: 20px;
    margin: 0 0 32px;
  }
  .text {
    font-weight: 400;
    font-size: 13px;
    line-height: 21px;
  }
}
