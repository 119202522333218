.burger-menu {
  position: fixed;
  top: 97px;
  right: 0;
  background-color: #e5e5e5;
  transform: translateX(100%);
  max-width: 320px;
  width: 100%;
  z-index: 5;
  padding: 25px 0 51px;
  transition: all 0.2s;
}

.burger-menu.active {
  transform: translateX(0);
  transition: all 0.2s;
}

.burger-menu__navigation {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.burger-menu-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.burger-menu__btn {
  width: 202px;
  height: 40px;
  left: 60px;
  top: 123px;
  background: #028fe8;
  border-radius: 20px;
  border: none;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
  margin: 0 0 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.burger-menu__btn:hover {
  opacity: 0.7;
}

.burger-menu__tel-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  margin-bottom: 32px;
}

.berger-menu__tel-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.berger-menu__tel-item:hover {
  opacity: 0.7;
}

.burger-menu__tel-border {
  height: 27px;
  border-left: 1px solid #02e2ff;
  margin: 0 20px;
}

.burger-menu__tel-link {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #231f20;
  text-decoration: none;
}

.burger-menu__item {
  margin: 0 0 20px;
}

.burger-menu__item:last-of-type {
  margin: 0;
}

.burger-menu__link {
  text-decoration: none;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #02e2ff;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.burger-menu__link:hover {
  color: #028fe8;
  transition: 0.3s;
}

@media screen and (min-width: 800px) {
  .burger-menu {
    display: none;
  }
}
