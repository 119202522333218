.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  width: 100%;
  max-width: 506px;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  max-width: 506px;
  justify-content: flex-start;
  gap: 20px;
  transition: transform 1.1s ease-in-out;
}

.buttonWrapper {
  max-width: 66px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}
