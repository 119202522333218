.delivery-info__container {
  width: 100%;
  max-width: 1066px;
  display: flex;
  flex-direction: column;
  padding-top: 81px;
  padding-bottom: 115px;
}

.delivery-info__title {
  max-width: none;
  min-width: 637px;
  min-height: 67px;
  text-transform: uppercase;
}

.delivery-info__list {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  max-width: 956px;
  width: 100%;
  margin-top: 48px;
  margin-bottom: 88px;
  align-self: center;
}

.delivery-info__item {
  width: 100%;
  margin-bottom: 44px;
}

.delivery-info__item:last-of-type {
  margin-bottom: 0;
}

.delivery-info__paragraph {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #88898d;
  margin: 0;
  padding: 0;
  z-index: 2;
}

.delivery-info__image {
  box-sizing: border-box;
  z-index: 1;
  display: flex;
  max-width: 509px;
  width: 100%;
  height: 509px;
  align-self: flex-end;
  margin-top: -526px;
}

@media screen and (max-width: 1100px) {
  .delivery-info__container {
    max-width: 850px;
  }
}

@media screen and (max-width: 890px) {
  .delivery-info__container {
    max-width: 750px;
  }
}

@media screen and (max-width: 800px) {
  .delivery-info__container {
    max-width: 600px;
  }
  .delivery-info__paragraph {
    font-size: 17px;
    line-height: 23px;
  }
  .delivery-info__title {
    min-height: 0;
    min-width: 200px;
  }
}

@media screen and (max-width: 650px) {
  .delivery-info__container {
    max-width: 450px;
  }
  .delivery-info__paragraph {
    font-size: 17px;
    line-height: 23px;
  }
  .delivery-info__title {
    min-height: 0;
    min-width: 200px;
  }
}
