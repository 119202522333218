.cooperation-wu-mobile__contant {
  font-family: "Open Sans";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.cooperation-wu-mobile__title {
  max-width: 100%;
  padding: 0 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #02e2ff;
}
.cooperation-wu-mobile__carousel-box {
  max-width: 100%;
  padding: 0 20px;
  display: flex;
}

.cooperation-wu-mobile__carousel-list {
  margin: 0 0 24px;
  padding: 0;
  list-style-type: none;
}

.cooperation-wu-mobile__item {
  display: flex;
  margin-bottom: 15px;
}

.cooperation-wu-mobile__carousel-number {
  margin: 0 10px 0 0;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  text-transform: uppercase;
  color: #02e2ff;
}

.cooperation-wu-mobile__carousel-text {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  text-align: justify;
  color: #88898d;
}
