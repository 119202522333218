.feedbacks-mobile {
  font-family: "Open Sans", sans-serif;
  background-image: url(../../images/fon.png);
  background-repeat: no-repeat;
  background-color: #02e1ff;
  background-position: cover;
}

.feedbacks-mobile__contant {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 45px 0 74px;
  flex-direction: column;
  width: 100%;
}

.feedbacks-mobile__title {
  margin: 0 0 12px;
  padding: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  max-width: 100px;
}

.feedbacks-mobile__border {
  width: 45px;
  height: 5px;
  background: #e5e5e5;
  margin-bottom: 33px;
}

.feedbacks-mobile__subtitle {
  margin: 0 0 15px;
  padding: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  max-width: 272px;
}

.feedbacks-mobile__btn {
  width: 203px;
  height: 48px;
  background: #028fe8;
  border: none;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
}

.feedbacks-mobile__carousel-box {
  width: 274px;
  height: 215px;
  background: #ffffff;
  border-radius: 20px;
  z-index: 1;
  margin-bottom: 40px;
  padding: 31px 22px;
  overflow: auto;
}

.feedbacks-mobile__carousel-text {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #88898d;
}

@media screen and (max-width: 450px) {
  .feedbacks-mobile__carousel-box {
    width: 272px;
  }
}
