.form {
  width: 470px;
  max-height: 513px;
  padding: 30px 30px 20px;
  box-sizing: border-box;
  border-radius: 2px;
  box-shadow: 0px 4px 15px rgba(47, 128, 237, 0.15);
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: column;
}

.form__fields-container {
  max-width: 280px;
  width: 100%;
}

.form__container {
  max-width: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.form__icon {
  width: 70px;
  margin-bottom: 23px;
}

.form__title {
  margin-top: 0;
  font-weight: bold;
  font-size: 26px;
  color: #464455;
  text-align: left;
  margin-bottom: 11px;
}

.form__alert-message {
  font-weight: normal;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 25px;
  color: #f58999;
}

.form__login {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  max-width: 280px;
  width: 100%;
  border: transparent;
  background-color: #fff;
}

.form__label {
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  color: #858687;
  margin-bottom: 17px;
  margin-top: 20px;
  align-self: flex-start;
}

.form__field {
  margin: 0;
  width: 100%;
  border: 1px solid #919192;
  box-sizing: border-box;
  border-radius: 2px;
  height: 44px;
  padding-left: 15px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  outline: 0;
  color: #4f4f4f;
}

.form__password,
.form__wrapper {
  position: relative;
}

.form__password {
  border: 1px solid transparent;
  background-color: transparent;
  display: inline-block;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
  width: 0;
}

.form__password:hover {
  opacity: 0.5;
}

.form__password::after {
  content: url(../../admin/newIcons/eye-close.svg);
  width: 30px;
  height: 30px;
  display: block;
  position: absolute;
  z-index: 1;
  right: 0;
  top: -65px;
  bottom: 0;
  left: 240px;
  margin: auto;
  cursor: pointer;
}

.form__password_type_shown::after {
  content: url(../../admin/newIcons/eye-open.svg);
  width: 30px;
  height: 30px;
  display: block;
  position: absolute;
  z-index: 1;
  right: 0;
  top: -65px;
  bottom: 0;
  left: 240px;
  margin: auto;
  cursor: pointer;
}

.form__password_add_user::after {
  left: 240px;
}

.form__field:hover {
  cursor: pointer;
  box-shadow: 0px 0px 15px rgba(149, 243, 255, 0.904);
  transition: box-shadow 0.3s linear;
  border-color: #c4ecfa;
}

.form__field:focus {
  background-color: #fff;
}

.form__button {
  background: #56ccf2;
  box-shadow: 0px 0px 15px rgb(65 99 149 / 20%);
  border-radius: 2px;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  color: #fff;
  width: 180px;
  height: 51px;
  border: transparent;
  cursor: pointer;
  margin-top: 30px;
  margin-bottom: 10px;
}

.form__button:hover {
  opacity: 0.7;
  transition: opactity 0.3s ease-in-out;
}

.form__button-disabled {
  opacity: 0.7;
}

.form__error {
  margin: 0 0 15px 0;
  padding: 0;
  font-size: 11px;
  color: #f58999;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  display: -webkit-box;
  line-clamp: 2;
  box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  line-height: 11px;
}

@media screen and (max-width: 1019px) {
  .form {
    width: 350px;
  }
}

@media screen and (max-width: 779px) {
  .form {
    width: 400px;
  }
}

@media screen and (max-width: 485px) {
  .form {
    width: 350px;
  }
  .form__login {
    max-width: 250px;
  }
  .form__title {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .form__icon {
    width: 60px;
    margin-bottom: 20px;
  }
  .form__password::after {
    left: 200px;
  }
  .form__password_type_shown::after {
    left: 200px;
  }
  .form__password_add_user::after {
    left: 210px;
  }
}

@media screen and (max-width: 425px) {
  .form__password_add_user::after {
    left: 190px;
  }
}

@media screen and (max-width: 390px) {
  .form {
    width: 300px;
  }
  .form__login {
    max-width: 240px;
  }
  .form__password_add_user::after {
    left: 187px;
  }
}
