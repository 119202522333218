.mac-profi__section {
}

.mac-profi__content {
  max-width: 1440px;
  width: 100%;
  height: 748px;
  background-image: url("../../images/banners/mac-profi_banner.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mac-profi__container {
  max-width: 920px;
  width: 100%;
  margin-top: -50px;
}

.mac-profi__title {
  margin: 0 0 80px 0;
  padding: 0;
  width: 600px;
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 59px;
  text-transform: uppercase;
  color: #231f20;
  text-shadow: 0px 4px 4px rgba(255, 255, 255, 0.83);
}

.mac-profi__links {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.mac-profi__item {
  display: flex;
  max-width: 637px;
  width: 100%;
}

.mac-profi__lable {
  width: 25px;
  height: 25px;
  margin-right: 20px;
}

.mac-profi__link {
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 31px;
  text-decoration-line: underline;
  color: #231f20;
  cursor: pointer;
  margin-bottom: 31px;
}

.mac-profi__link:hover {
  opacity: 0.7;
}

.mac-profi__text {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 31px;
  color: #231f20;
  margin-bottom: 83px;
}

.mac-profi__select {
  border: none;
  border-bottom: 1px solid #231f20;
  max-width: 260px;
  width: 100%;
  height: 41px;
  background-color: transparent;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 31px;
  color: #88898d;
  cursor: pointer;
}

.mac-profi__selects {
  display: flex;
}

.mac-profi-wrapper {
  max-width: 260px;
  width: 100%;
}

.mac-profi-wrapper:first-of-type {
  margin-right: 30px;
}

@media screen and (max-width: 1440px) {
  .mac-profi__content {
    height: 48vw;
  }
}

@media screen and (max-width: 1280px) {
  .mac-profi__title {
    margin: 0 0 30px 0;
  }
  .mac-profi__container {
    margin-top: 0;
  }
}

@media screen and (max-width: 1130px) {
  .mac-profi__title {
    margin: 0 0 25px 0;
    font-size: 30px;
    line-height: 56px;
  }
  .mac-profi__container {
    margin-top: 0;
  }
  .mac-profi__text {
    margin-bottom: 40px;
  }
  .mac-profi__container {
    max-width: 750px;
  }
}

@media screen and (max-width: 930px) {
  .mac-profi__title {
    margin: 0 0 15px 0;
    font-size: 27px;
    line-height: 35px;
    max-width: 350px;
  }
  .mac-profi__container {
    margin-top: 0;
  }
  .mac-profi__text {
    margin-bottom: 15px;
  }
  .mac-profi__container {
    max-width: 600px;
  }
  .mac-profi__link {
    font-size: 17px;
    line-height: 29px;
    margin-bottom: 10px;
  }
  .mac-profi-wrapper {
    max-width: 230px;
    width: 100%;
  }
}
