.repair-iphone__services-links-container {
  background: linear-gradient(
    60deg,
    rgba(196, 196, 196, 0.09) 36.68%,
    rgba(196, 196, 196, 0) 88.72%
  );
}

.repair-iphone__services-links-wrapper {
  padding: 75px 0 75px;
  max-width: 1066px;
  width: 100%;
}

.repair-iphone__services-links-title {
  margin: 0 0 55px 0;
  padding: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 32px;
  text-transform: uppercase;
  color: #02e2ff;
}

.repair-iphone__services-links-grid {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 340px));
  gap: 14px 22px;
}

.repair-iphone__services-links-item {
  display: flex;
  align-items: center;
  height: 40px;
}

.repair-iphone__services-links--icon {
  margin-right: 26px;
  transform: translateY(2px);
}

.repair-iphone__services-links {
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #88898d;
  align-self: center;
}

.repair-iphone__services-links:hover {
  color: #02e2ff;
  border-bottom: 1px solid #02e2ff;
}

@media screen and (max-width: 1130px) {
  .repair-iphone__services-links-wrapper {
    max-width: 790px;
  }
  .repair-iphone__services-links-grid {
    gap: 14px 50px;
  }
}

@media screen and (max-width: 920px) {
  .repair-iphone__services-links-wrapper {
    max-width: 664px;
  }
  .repair-iphone__services-links-grid {
    gap: 6px 50px;
  }
  .repair-iphone__services-links-item {
    height: 32px;
  }
}

@media screen and (max-width: 720px) {
  .repair-iphone__services-links-wrapper {
    max-width: 480px;
    padding: 53px 0 45px;
  }
  .repair-iphone__services-links-title {
    margin: 0 0 35px 0;
    font-size: 26px;
    line-height: 32px;
  }
}

@media screen and (max-width: 500px) {
  .repair-iphone__services-links-wrapper {
    max-width: 100%;
    padding: 53px 20px 45px;
  }
  .repair-iphone__services-links-title {
    margin: 0 0 32px 0;
    font-size: 19px;
    line-height: 26px;
  }
  .repair-iphone__services-links--icon {
    margin-right: 13px;
  }
  .repair-iphone__services-links {
    font-size: 14px;
    line-height: 21px;
  }
}
