.repair-iphone__models-links-container {
  background: linear-gradient(
    60deg,
    rgba(196, 196, 196, 0.09) 36.68%,
    rgba(196, 196, 196, 0) 88.72%
  );
}

.repair-iphone__models-links-wrapper {
  max-width: 1066px;
  width: 100%;
  padding: 75px 0 75px;
}

.repair-iphone__models-links-title {
  margin: 0 0 55px 0;
  padding: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-transform: uppercase;
  color: #02e2ff;
  max-width: 645px;
}

.repair-iphone__models-links-grid {
  list-style-type: none;
  margin: 0;
  padding: 0;
  column-width: 260px;
}

.repair-iphone__models-links-item {
  height: 35px;
}

.repair-iphone__models-links-link {
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 26px;
  color: #02e2ff;
}

.repair-iphone__models-links-link:hover {
  transition: 0.3s;
  opacity: 0.5;
  color: #02e2ff;
  border-bottom: 1px solid #02e2ff;
}

.repair-iphone__models-links-img {
  margin-right: 20px;
  transform: translateY(2px);
}

.repair-iphone__models-links-number {
  display: none;
}

@media screen and (max-width: 1130px) {
  .repair-iphone__models-links-wrapper {
    max-width: 790px;
  }
}

@media screen and (max-width: 920px) {
  .repair-iphone__models-links-wrapper {
    max-width: 664px;
  }
}

@media screen and (max-width: 720px) {
  .repair-iphone__models-links-wrapper {
    max-width: 480px;
    padding: 45px 0;
  }
  .repair-iphone__models-links-title {
    margin: 0 0 45px 0;
    font-size: 26px;
    line-height: 32px;
  }
}

@media screen and (max-width: 500px) {
  .repair-apple__paragraph-border {
    width: 27.02px;
    height: 0px;
    border: 1px solid #02e2ff;
    margin: 18px 0;
    align-self: center;
  }
  .repair-iphone__models-links-img {
    display: none;
  }
  .repair-iphone__models-links-number {
    display: block;
    margin: 0;
    padding: 0;
    font-size: 22px;
    line-height: 32px;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    color: #02e2ff;
  }
}
