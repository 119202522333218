.bread-crumbs {
  max-width: 993px;
  margin: 12px 0;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  overflow: scroll;
  scrollbar-width: none;
  display: inline-block;
}

.bread-crumbs__link {
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 24px;
  color: rgba(136, 137, 141, 0.78);
  cursor: pointer;
  /* max-width: 170px; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}

.bread-crumbs::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
}

@media screen and (max-width: 720px) {
  .bread-crumbs {
    max-width: 490px;
  }
}

@media screen and (max-width: 500px) {
  .bread-crumbs {
    max-width: 100%;
    padding: 0 20px;
    justify-content: flex-start;
  }
  .bread-crumbs__link {
    max-width: none;
  }
}
