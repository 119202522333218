.admin {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin__container {
  width: 100%;
  max-width: 1708px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.admin__form {
  align-items: center;
}

@media screen and (max-width: 799px) {
  .admin__container {
    padding: 0 0 10px 0;
  }
}
