.aside {
  background-color: #333;
  display: flex;
  padding: 0;
  flex-direction: column;
  align-items: center;
  min-width: 186px;
  flex: 2;
}

.aside_type_hidden {
  min-width: 45px;
}

.aside_type_hidden .aside__subtitle {
  display: none;
}

.aside_type_hidden .aside__button {
  margin: 20px 0 0 0;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.aside_type_hidden .aside__icon {
  margin: 0;
}

.aside__list {
  font-family: "Open Sans", sans-serif;
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.aside__item {
  width: 100%;
}

.aside__item:hover {
  opacity: 0.7;
  cursor: pointer;
  background-color: #028fe8;
  transition: all 1.3s ease;
}

.aside__item:hover .aside__link {
  position: relative;
  cursor: pointer;
}

.aside__link {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  color: #fff;
  text-decoration: none;
  display: flex;
}

.aside__item:hover .aside__link {
  color: #333;
  transition: color 0.3s ease;
}

.aside__icon {
  margin: 0 5px 0 0;
  padding: 0;
  width: 25px;
  fill: "#fff";
}

.aside__link.active .aside__icon {
  filter: invert(55%) sepia(94%) saturate(508%) hue-rotate(143deg)
    brightness(106%) contrast(103%);
}

.aside__link.active:hover .aside__icon {
  filter: invert(14%) sepia(7%) saturate(3%) hue-rotate(349deg) brightness(91%)
    contrast(80%);
}

.aside__subtitle {
  font-size: 14px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 799px) {
  .aside {
    min-width: 39px;
  }
  .aside__link {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .aside__subtitle {
    display: none;
  }
  .aside__icon {
    margin: 0;
  }
}
