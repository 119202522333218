.priceSection {
  background: linear-gradient(
    225deg,
    rgba(196, 196, 196, 0.09) 40.61%,
    rgba(196, 196, 196, 0) 92.65%
  );
}

.content {
  max-width: 1066px;
  width: 100%;
  padding: 55px 0 72px;
  font-family: "Open Sans", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-transform: uppercase;
  color: #02e2ff;
  margin: 0 0 55px;
  padding: 0;
}

.tableHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 920px;
  width: 100%;
  padding: 6px 0;
  background: #fff;
  box-shadow: 0px 0px 134px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 240px;
  width: 100%;
}

.headerTextService {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #88898d;
  margin: 0;
  padding: 0 0 0 20px;
}

.headerText {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #88898d;
  margin: 0;
  padding: 0;
}

.headerTextTime {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #88898d;
  margin: 0;
  padding: 0 20px 0 0;
  max-width: 75px;
}

.subtitle {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #02e2ff;
  margin: 11px 0;
  padding: 0;
}

.list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  max-width: 920px;
  width: 100%;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  padding: 4px 20px;
}

.listText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: flex-end;
  color: #88898d;
  margin: 0;
  padding: 0;
}

.listPrice {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #02e2ff;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  max-width: 90px;
  width: 100%;
}

.listTime {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: flex-start;
  color: #000000;
  margin: 0;
  padding: 0;
  max-width: 75px;
  width: 100%;
}

.listWrapper {
  max-width: 255px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.border {
  max-width: 503px;
  width: 100%;
  border: 1px solid rgba(196, 196, 196, 0.63);
  margin: 25px 0 17px;
}

.paragraph {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #88898d;
  margin: 0;
  padding: 0;
}

.button {
  width: 241px;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: #028fe8;
  border-radius: 20px;
  border: none;
  margin-top: 73px;
  padding: 13.5px 0;
  cursor: pointer;
}

@media screen and (max-width: 980px) {
  .content {
    max-width: 680px;
  }
}

@media screen and (max-width: 780px) {
  .content {
    max-width: 550px;
  }
  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
    text-transform: uppercase;
    color: #02e2ff;
    margin: 0 0 32px;
    padding: 0;
  }
}

@media screen and (max-width: 600px) {
  .content {
    max-width: 100%;
    padding: 45px 20px;
  }
  .title {
    font-size: 19px;
    line-height: 26px;
    margin: 0 0 21px;
  }
  .listText {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    max-width: 110px;
  }
  .listPrice {
    font-size: 14px;
    line-height: 24px;
  }
  .listTime {
    font-size: 12px;
    line-height: 16px;
    max-width: 55px;
  }
  .headerTextService {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    padding: 0 0 0 11px;
  }
  .headerText {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
  }
  .headerTextTime {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    padding: 0 11px 0 0;
    max-width: 55px;
  }
  .wrapper {
    max-width: 145px;
  }
  .listWrapper {
    max-width: 170px;
  }
  .subtitle {
    font-size: 14px;
    line-height: 24px;
  }
  .border {
    max-width: 200 px;
    margin: 25px 0 27px;
  }

  .paragraph {
    font-size: 12px;
    line-height: 16px;
  }

  .button {
    width: 174px;
    font-size: 12px;
    line-height: 14px;
    border-radius: 14px;
    margin-top: 25px;
    padding: 12px 0;
  }
}
