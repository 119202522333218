.repair-iphone-display__list {
  list-style-type: none;
  margin: 16px 0 0;
  padding: 0;
}

.repair-iphone-display__item {
  display: flex;
  margin-bottom: 10px;
}

.repair-iphone-display__paragraph {
  margin: 0;
  padding: 0;
}

.repair-iphone-display__paragraph-down {
  margin: 16px 0 0;
  padding: 0;
}

.repair-iphone-display__margin-bottom {
  margin-bottom: 20px;
}

@media screen and (max-width: 500px) {
  .repair-iphone-display__paragraph {
    font-weight: 400;
    font-size: 14px;
    text-align: left;
  }
  .repair-iphone-display__paragraph-down {
    font-size: 14px;
  }
  .repair-iphone-display__item {
    flex-direction: column;
  }
}
