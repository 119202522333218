.mac-profi__au-container {
  padding: 55px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1066px;
}

.mac-profi__au-title {
  margin: 0 0 25px;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
}

.mac-profi__au-border {
  background-color: #e5e5e5;
  width: 87px;
  height: 7px;
  margin-bottom: 42px;
}

.mac-profi__au-text {
  margin: 0 0 22px;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #88898d;
  max-width: 599px;
}

@media screen and (max-width: 720px) {
  .mac-profi__au-container {
    max-width: 480px;
  }
}
