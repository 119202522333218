.info {
  width: 100%;
  padding: 30px;
  font-family: "Open Sans", sans-serif;
}

.info__container {
  border-radius: 2px;
  box-shadow: 0px 4px 15px rgba(47, 128, 237, 0.15);
  min-height: 360px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.info__list {
  list-style-type: none;
  padding-left: 0;
  margin: 50px 0 0 0;
}

.info__field {
  margin: 0 0 15px;
}

.info__item {
  color: #88898d;
  line-height: 24px;
  font-size: 17px;
  font-weight: 400;
  margin: 0 0 15px;
}

.info__item:last-of-type {
  margin-bottom: 0;
}

.info__item_type_deleted {
  padding-bottom: 30px;
}
.info__line {
  margin: 0 0 10px;
  padding: 0;
}

.info__label {
  float: left;
  text-align: left;
  clear: both;
  width: 70px;
}

.info__change {
  border: 1px solid #e5e5e8;
  border-radius: 5px;
  margin-left: 5px;
  padding: 7px;
  color: #88898d;
  line-height: 24px;
  font-size: 17px;
  font-weight: 400;
  width: 50%;
  float: left;
}

.info__change:hover {
  cursor: pointer;
  box-shadow: 0px 0px 15px rgba(149, 243, 255, 0.904);
  transition: all 0.3s linear;
  border-color: #c4ecfa;
}

.info__change:focus {
  outline: none;
}

.info__change::-webkit-input-placeholder {
  font-size: 14px;
  line-height: 20px;
  color: #02e1ff;
}
.info__change::-moz-placeholder {
  font-size: 14px;
  line-height: 20px;
  color: #02e1ff;
}
.info__change:-ms-input-placeholder {
  font-size: 14px;
  line-height: 20px;
  color: #02e1ff;
}
.info__change:-moz-placeholder {
  font-size: 14px;
  line-height: 20px;
  color: #02e1ff;
}

.info__span {
  font-weight: 700;
}

.info__pages {
  margin: 0 7px 0 0;
}

.info__card {
  margin: 0;
  padding: 0;
}

.info__header {
  display: flex;
  align-items: center;
  margin: 0;
  padding-bottom: 15px;
  border-bottom: 1px dashed #e5e5e8;
  justify-content: space-between;
}

.info__main {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 10px 0;
  border-bottom: 1px dashed #e5e5e8;
}

.info__fields {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.info__form {
  width: 100%;
  margin: 0;
  padding: 0;
  color: #88898d;
  line-height: 24px;
  font-size: 17px;
  font-weight: 400;
}

.info__inputs {
  display: flex;
  flex-direction: column;
  border: none;
  margin: 0;
  padding: 0;
}

.info__wrapper {
  display: flex;
  align-items: center;
}

.info__icon {
  margin-right: 10px;
  width: 50px;
}

.info__space {
  width: 72px;
  height: 59px;
  box-sizing: border-box;
}

.info__button {
  display: flex;
  align-items: center;
  margin-top: 7px;
  background-color: transparent;
  border: none;
  max-width: 270px;
  align-self: flex-start;
}

.info__button:hover {
  opacity: 0.7;
  cursor: pointer;
}

.info__button:hover ~ .info__icon:hover {
  cursor: pointer;
}

.info__error {
  margin: 0 0 0 75px;
  padding: 2px 0;
  font-size: 11px;
  color: #f58999;
  width: 50%;
  line-height: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  display: -webkit-box;
  line-clamp: 2;
  box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.info__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7px;
  background-color: #02e1ff;
  color: #fff;
  border: 1px solid transparent;
  border-radius: 7px;
  font-size: 13px;
  font-weight: 700;
  padding: 7px 10px;
}

.info__btn:hover {
  cursor: pointer;
  opacity: 0.3;
}

.info__btn_type_disabled {
  background-color: #e5e5e8;
}

.info__btn_type_disabled:hover {
  cursor: not-allowed;
}

.info__link {
  text-decoration: none;
  color: #028fe8;
  max-width: 180px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: end;
}

.info__delited {
  text-decoration: none;
  color: #028fe8;
  margin-top: 30px;
  display: block;
}

.info__link:hover {
  opacity: 0.7;
}

.info__buttons {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 20px 0 0;
  gap: 20px;
}

.admin__data-link {
  color: #028fe8;
  text-decoration: none;
}

.admin__data-link:hover {
  opacity: 0.7;
}

@media screen and (max-width: 1190px) {
  .info {
    padding: 20px;
  }
  .info__item {
    font-size: 14px;
  }
  .info__link {
    font-size: 14px;
    max-width: 170px;
  }
  .info__delited {
    font-size: 14px;
  }
}

@media screen and (max-width: 799px) {
  .info {
    padding: 0 10px 10px;
  }
  .info__link {
    max-width: 145px;
  }
  .info__container {
    padding: 10px;
    min-height: 0;
  }
  .info__list {
    margin-top: 15px;
  }
  .info__item {
    line-height: 20px;
    margin-bottom: 10px;
  }
  .info__space {
    width: 52px;
    height: 32px;
  }
}

@media screen and (max-width: 799px) {
  .info__button {
    margin-top: 0;
  }
  .info__icon {
    width: 30px;
  }
}

@media screen and (max-width: 475px) {
  .info__li:first-of-type {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .info__link {
    max-width: 230px;
    text-align: start;
    margin-left: 0;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 374px) {
  .info {
    padding: 0;
  }
  .info__container {
    padding: 5px 0 5px 5px;
  }
  .info__icon {
    margin-right: 0;
  }
  .info__space {
    width: 42px;
  }
}
