.center-mobile__general-page-repair {
  background: linear-gradient(
    269deg,
    rgba(196, 196, 196, 0.09) 40.61%,
    rgba(196, 196, 196, 0) 92.65%
  );
}

.center-mobile__general-page-repair-container {
  font-family: "Open Sans", sans-serif;
  max-width: 1066px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 55px 0 60px;
}

.center-mobile__general-page-repair-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
  padding: 0;
  margin: 0 0 56px;
}

.center-mobile__general-page-repair-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, 210px);
  gap: 72px 109px;
  max-width: 850px;
  width: 100%;
}

.center-mobile__general-page-repair-element {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.center-mobile__general-page-repair-item {
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 25px;
  color: #88898d;
  text-align: center;
  max-width: 213px;
}

.center-mobile__general-page-repair-text {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: justify;
  color: #88898d;
  max-width: 880px;
  padding: 0;
  margin: 47px 50px 0;
}

@media screen and (max-width: 920px) {
  .center-mobile__general-page-repair-title {
    font-size: 26px;
    line-height: 32px;
  }
  .center-mobile__general-page-repair-item {
    font-size: 16px;
    line-height: 21px;
  }
  .center-mobile__general-page-repair-text {
    font-size: 16px;
    line-height: 22px;
  }
  .center-mobile__general-page-repair-list {
    gap: 45px 40px;
    max-width: 710px;
  }
}

@media screen and (max-width: 780px) {
  .center-mobile__general-page-repair-list {
    max-width: 460px;
  }
}

@media screen and (max-width: 600px) {
  .center-mobile__general-page-repair-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .center-mobile__general-page-repair-title {
    font-size: 19px;
    line-height: 26px;
    margin: 0 0 28px;
  }
  .center-mobile__general-page-repair-item {
    font-size: 13px;
    line-height: 17px;
    max-width: 345px;
  }
  .center-mobile__general-page-repair-text {
    display: none;
  }
  .center-mobile__general-page-repair-image {
    width: 42px;
    height: 42px;
  }
}

@media screen and (max-width: 390px) {
  .center-mobile__general-page-repair-item {
    max-width: 274px;
  }
}
