.about-company-mobile__contant {
  max-width: 640px;
  width: 100%;
  font-family: "Open Sans";
  padding: 24px 0 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-company-mobile__title {
  margin: 0 0 18px;
  padding: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
}

.about-company-mobile__border-title {
  width: 90px;
  height: 7px;
  background: #e4e9ea;
  margin: 0 auto;
}

.about-company-mobile__list {
  margin: 45px 0 0;
  padding: 0;
  list-style-type: none;
}

.about-company-mobile__paragraph {
  margin: 0 0 19px;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #88898d;
}

.about-company-mobile__certificate {
  width: 100%;
}

.about-company-mobile__button-req {
  background: #028fe8;
  border-radius: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  border: none;
  width: 220px;
  padding: 10px 10px;
  cursor: pointer;
  align-self: center;
  margin: 15px 0;
}

.about-company-mobile__requisites-list {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.about-company-mobile__requisites-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.about-company-mobile__requisites-title {
  text-align: center;
  margin: 0 0 10px;
  padding: 0;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 26px;
  color: #88898d;
}

.about-company-mobile__requisites-text {
  text-align: center;
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #88898d;
}

.about-company-mobile__requisites-border {
  border-bottom: 1px solid #02e2ff;
  width: 30%;
  margin-top: 7px;
}

@media screen and (max-width: 690px) {
  .about-company-mobile__contant {
    max-width: 500px;
  }
}

@media screen and (max-width: 550px) {
  .about-company-mobile__contant {
    max-width: 410px;
  }
}

@media screen and (max-width: 500px) {
  .about-company-mobile__contant {
    max-width: 100%;
    padding: 24px 20px 26px;
  }

  .about-company-mobile__title {
    font-size: 22px;
    line-height: 25px;
  }

  .about-company-mobile__border-title {
    width: 60px;
  }

  .about-company-mobile__paragraph {
    font-size: 14px;
  }

  .about-company-mobile__border {
    width: 27.02px;
    height: 1px;
    background-color: #02e2ff;
    margin: 0 auto 18px;
  }
}
