.center-mobile__general-page-link-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 55px 0 63px;
  font-family: "Open Sans";
}

.center-mobile__general-page-link-mobile-image {
  background-color: #02e2ff;
  width: 105px;
  height: 105px;
  border-radius: 50%;
  background-image: url("../../../images/icons/center-mobile-links-mobile-mini.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.center-mobile__general-page-link-pad-image {
  background-color: #02e2ff;
  width: 105px;
  height: 105px;
  border-radius: 50%;
  background-image: url("../../../images/icons/center-mobile-links-pad-mini.svg");
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 25px;
}

.center-mobile__general-page-link-mobile-button {
  background-image: url("../../../images/icons/center-mobile-link-select-button.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  border: none;
  width: 15px;
  height: 8px;
  background-size: 15px 8px;
  margin-left: 8px;
}

.center-mobile__general-page-link-mobile-title {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  text-transform: uppercase;
  color: #88898d;
  padding: 0;
  margin: 15px 0 20px;
  display: flex;
  align-items: center;
}

.center-mobile__general-page-link-mobile-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.center-mobile__general-page-link-mobile-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.center-mobile__general-page-link-mobile-link {
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 17px;
  text-align: center;
  color: #88898d;
  text-decoration: none;
}

@media screen and (max-width: 600px) {
  .center-mobile__general-page-link-mobile-image {
    width: 80px;
    height: 80px;
  }
  .center-mobile__general-page-link-pad-image {
    width: 80px;
    height: 80px;
  }
  .center-mobile__general-page-link-mobile-title {
    font-size: 19px;
    line-height: 26px;
  }
  .center-mobile__general-page-link-mobile-button {
    background-image: url("../../../images/icons/center-mobile-link-select-button.svg");
    background-color: #fff;
    border: none;
    width: 9px;
    height: 5px;
    background-size: 11px 5px;
    margin-left: 8px;
  }
}
