.content {
  font-family: "Open Sans", sans-serif;
  padding: 55px 0 61px;
  max-width: 1066px;
  width: 100%;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-transform: uppercase;
  color: #02e2ff;
  margin: 0 0 26px;
  padding: 0;
}

.subtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 25px;
  color: #88898d;
  max-width: 880px;
}

@media screen and (max-width: 1160px) {
  .content {
    max-width: 890px;
  }
}

@media screen and (max-width: 980px) {
  .content {
    max-width: 690px;
  }
}

@media screen and (max-width: 780px) {
  .content {
    max-width: 550px;
  }
  .title {
    font-size: 28px;
    line-height: 32px;
    margin: 0 0 39px;
  }
  .subtitle {
    font-size: 17px;
    line-height: 23px;
  }
}

@media screen and (max-width: 600px) {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    padding: 45px 20px;
  }
  .title {
    font-size: 22px;
    line-height: 25px;
    margin: 0 0 19px;
    text-align: center;
  }
  .subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }
  .border {
    background: #e4e9ea;
    width: 60px;
    height: 7px;
    margin-bottom: 25px;
  }
}
