.repair-apple-dprice__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1066px;
  width: 100%;
  padding: 0;
}

.repair-apple-dprice__title {
  text-align: center;
  max-width: 790px;
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
  margin: 55px 0 30px;
  padding: 0;
}

.repair-apple-dprice__subtitle {
  text-align: center;
  max-width: 790px;
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
  margin: 0 0 26px;
  padding: 0;
}

.repair-apple-dprice__list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  justify-content: space-between;
  padding-bottom: 1px;
  max-width: 993px;
  width: 100%;
  background: #fff;
  box-shadow: 0px 0px 134px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin: 56px 0 55px;
  padding: 0;
}

.repair-apple-dprice__checklist {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  justify-content: space-between;
  padding-bottom: 1px;
  max-width: 993px;
  width: 100%;
  background: #fff;
  box-shadow: 0px 0px 134px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin: 0;
  padding: 0;
}

.repair-apple-dprice__item {
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  max-width: 973px;
  width: 100%;
  margin: 0 auto;
}

.repair-apple-dprice__item:first-of-type {
  border-bottom: 1px solid rgba(196, 196, 196, 0.63);
}

.repair-apple-dprice__paragraph {
  margin: 15px 0 55px;
  padding: 0;
  max-width: 993px;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  color: #88898d;
}

@media screen and (max-width: 1090px) {
  .repair-apple-dprice__container {
    max-width: 850px;
  }
  .repair-apple-dprice__item {
    /* padding: 15px 12px; */
  }
  .repair-apple-dprice__item {
    max-width: 826px;
  }
}

@media screen and (max-width: 950px) {
  .repair-apple-dprice__container {
    max-width: 790px;
  }
  .repair-apple-dprice__item {
    max-width: 766px;
  }
}

@media screen and (max-width: 830px) {
  .repair-apple-dprice__container {
    max-width: 680px;
  }
  .repair-apple-dprice__item {
    max-width: 656px;
  }
}

@media screen and (max-width: 720px) {
  .repair-apple-dprice__container {
    max-width: 480px;
  }
  .repair-apple-dprice__item {
    max-width: 456px;
  }
}

@media screen and (max-width: 500px) {
  .repair-apple-dprice__container {
    max-width: 100%;
    padding: 0 20px;
  }
  .repair-apple-dprice__item {
    max-width: 100%;
    padding: 10px 0;
  }
}
