.mac-profi__apple-container {
  background: linear-gradient(
    223deg,
    rgba(196, 196, 196, 0.09) 36.68%,
    rgba(196, 196, 196, 0) 88.72%
  );
}

.mac-profi__apple-contant {
  padding: 55px 0 59px;
  width: 100%;
  max-width: 1066px;
  display: flex;
  justify-content: center;
}

.mac-profi__apple-logo {
  margin-right: 129px;
  transform: translateY(-35px);
}

.mac-profi__apple-title {
  margin: 0 0 42px;
  padding: 0;
  max-width: 542px;
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-transform: uppercase;
  color: #02e2ff;
}

.mac-profi__apple-text {
  margin: 0 0 42px;
  padding: 0;
  max-width: 534px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #88898d;
}

.mac-profi__apple-button {
  width: 200px;
  height: 56px;
  background: #ba59f6;
  border-radius: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
  align-items: center;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.mac-profi__apple-button:hover {
  opacity: 0.7;
  transition: 0.3s;
}

.mac-profi__apple-link {
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
  color: #ffffff;
  text-decoration: none;
}

@media screen and (max-width: 1033px) {
  .mac-profi__apple-logo {
    margin-right: 65px;
    transform: translateY(-35px);
  }
  .mac-profi__apple-wrapper {
    max-width: 450px;
  }
}

@media screen and (max-width: 880px) {
  .mac-profi__apple-contant {
    max-width: 600px;
  }
  .mac-profi__apple-logo {
    margin-right: 40px;
    transform: translateY(0);
    width: 250px;
    height: 250px;
  }
}

@media screen and (max-width: 720px) {
  .mac-profi__apple-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .mac-profi__apple-title {
    text-align: center;
  }
  .mac-profi__apple-text {
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .mac-profi__apple-contant {
    padding: 45px 20px;
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mac-profi__apple-title {
    font-size: 19px;
    line-height: 26px;
    margin: 0 0 32px;
  }
  .mac-profi__apple-text {
    margin: 0 0 36px;
  }
  .mac-profi__apple-button {
    width: 180px;
    height: 48px;
    font-size: 16px;
    line-height: 22px;
  }
}
