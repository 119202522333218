.center-mobile__general-page-banner-container {
  font-family: "Open Sans";
  /* background-image: url(../../../images/banners/center-mobile-general-banner.svg); */
  background-repeat: no-repeat;
  background-size: 100%;
  max-width: 1440px;
  width: 100%;
  height: 668px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-mobile__general-page-banner-content {
  max-width: 1060px;
  width: 100%;
  margin: 0 50px;
}

.center-mobile__general-page-banner-title {
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 50px;
  color: #231f20;
  padding: 0;
  margin: 0 0 12px 0;
  max-width: 514px;
}

.center-mobile__general-page-banner-subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 50px;
  color: #231f20;
  padding: 0;
  margin: 0 0 65px 0;
}

.center-mobile__general-page-banner-button {
  width: 382px;
  height: 80px;
  border: none;
  background: #028fe8;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0 0 40px 0;
}

.center-mobile__general-page-banner-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #88898d;
  /* color: #ff4141; */
}

@media screen and (max-width: 1440px) {
  .center-mobile__general-page-banner-container {
    height: 46vw;
  }
}

@media screen and (max-width: 920px) {
  .center-mobile__general-page-banner-title {
    font-size: 30px;
    line-height: 38px;
    max-width: 380px;
  }
  .center-mobile__general-page-banner-subtitle {
    font-size: 25px;
    line-height: 42px;
    margin: 0 0 45px 0;
  }
  .center-mobile__general-page-banner-button {
    width: 290px;
    height: 60px;
    font-size: 15px;
    line-height: 38px;
    border-radius: 18px;
  }
}

@media screen and (max-width: 660px) {
  .center-mobile__general-page-banner {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .center-mobile__general-page-banner-button {
    margin: 29px 0;
  }
}

@media screen and (max-width: 500px) {
  .center-mobile__general-page-banner-button {
    width: 188px;
    height: 39px;
    box-shadow: 0px 4.92147px 4.92147px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    font-family: "Roboto";
    font-size: 10px;
    line-height: 25px;
  }
  .center-mobile__general-page-banner-title {
    font-weight: 700;
    font-size: 19px;
    line-height: 26px;
    color: #000000;
    margin: 0 0 15px 0;
    max-width: 225px;
  }
  .center-mobile__general-page-banner-subtitle {
    font-weight: 600;
    font-size: 10px;
    line-height: 34px;
    padding: 0;
    margin: 0;
  }
  .center-mobile__general-page-banner-text {
    display: none;
  }
  .center-mobile__general-page-banner-content {
    max-width: 274px;
    width: 100%;
    margin: 0;
    height: 162px;
  }
}
