.cooperation-mobile__contant {
  font-family: "Open Sans";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 50px 0 62px;
  background: linear-gradient(
    258deg,
    rgba(196, 196, 196, 0.13) 36.68%,
    rgba(196, 196, 196, 0) 88.72%
  );
}

.cooperation-mobile__title {
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 31px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
}

.cooperation-mobile__carousel-box {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  padding: 0 20px;
}

.cooperation-mobile__carousel-list {
  list-style-type: none;
  margin: 0 0 24px 0;
  padding: 0;
}

.cooperation-mobile__carousel-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.cooperation-mobile__carousel-icon {
  background-image: url("../../images/icons/check-mobile.svg");
  background-repeat: no-repeat;
  background-position: 40%;
  width: 17px;
  height: 13px;
  margin-right: 19px;
}

.cooperation-mobile__carousel-text {
  margin: 0;
  padding: 0;
  max-width: 390px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #88898d;
  text-align: left;
}

.cooperation-mobile__text {
  max-width: 100%;
  margin: 39px 0 25px;
  padding: 0 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #88898d;
}

.cooperation-mobile__text:last-of-type {
  margin: 0;
}
