.preloader {
  transform: translateZ(1px);
}

.preloader_overlayed {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
}

.preloader__item {
  position: relative;
  animation: loading 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  background: #98f2fd;
  border-radius: 50%;
  width: 64px;
  height: 64px;
}

/* .preloader__item_type_default {
  width: 32px;
  height: 32px;
}

.preloader__item_type_large {
  width: 64px;
  height: 64px;
} */

@keyframes loading {
  0%,
  100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    transform: rotateY(1800deg);
  }
  100% {
    transform: rotateY(3600deg);
  }
}
