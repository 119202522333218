.notification {
  display: flex;
  justify-content: center;
  width: 100%;
}

.notification-hidden {
  display: none;
}

.notification__container {
  max-width: 1066px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px 20px 22px;
  box-sizing: border-box;
}

.notification__block {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 10px;
}

.notification__wrapper {
  display: flex;
}

.notification__icon {
  align-self: center;
  margin-right: 40px;
}

.notification__text-block {
  max-width: 630px;
  width: 100%;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #88898d;
}

.notification__subtitle {
  margin: 0 0 20px;
  font-weight: 400;
  font-size: 20px;
}

.notification__paragraph {
  margin: 0 0 20px;
}

.notification__button {
  width: 54px;
  height: 54px;
  background: #fff;
  box-shadow: 0px 0px 54px rgba(2, 134, 255, 0.28);
  display: flex;
  border: none;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  align-self: flex-end;
}

.notification__button-icon {
  width: 12px;
  height: 6px;
}

.notification__button:hover {
  opacity: 0.7;
  transition: opactity 0.3s ease-in-out;
}

@media screen and (max-width: 570px) {
  .notification__subtitle {
    margin: 0 0 10px;
    font-weight: 400;
    font-size: 16px;
  }
  .notification__paragraph {
    margin: 0 0 10px;
    font-size: 16px;
  }
  .notification__icon {
    width: 100px;
    height: 100px;
    margin-right: 20px;
  }
}

@media screen and (max-width: 430px) {
  .notification__container {
    padding: 0 20px 12px;
  }
  .notification__block {
    margin-top: 15px;
  }
  .notification__text-block {
    max-width: 160px;
  }
  .notification__icon {
    width: 60px;
    height: 60px;
    margin-right: 10px;
  }
  .notification__subtitle {
    font-size: 14px;
  }
  .notification__paragraph {
    font-size: 14px;
    line-height: 18px;
  }
}
