.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 441px;
  max-width: 281px;
  font-family: "Open Sans";
}

.card__cover {
  min-height: 150px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card__icon {
  max-width: 162px;
  max-height: 144px;
}

.card__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card__image {
  width: 281px;
  height: 112px;
}

.card__title {
  color: rgb(136, 137, 141);
  letter-spacing: normal;
  line-height: 23px;
  font-size: 20px;
  font-weight: 400;
  margin: 37px 0 18px;
  padding: 0;
  max-width: 192px;
  text-align: center;
}

.card__link {
  width: 191px;
  height: 46px;
  background-color: #5e78e9;
  color: #f7f7f7;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 24px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.card__link_color_purple {
  background-color: #ba59f6;
}

.card__link_color_green {
  background-color: #00e593;
}

.card__link:hover {
  opacity: 0.7;
  transition: opactity 0.3s ease-in-out;
}
