.section {
  background: rgba(196, 196, 196, 0.09);
}

.content {
  font-family: "Open Sans", sans-serif;
  padding: 34px 0 64px;
  max-width: 294px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
  margin: 0 0 21px;
  padding: 0;
}

.subtitle {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #02e2ff;
  margin: 12px 0;
  padding: 0;
}

.wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 294px;
  width: 100%;
  align-items: center;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  padding: 9px 11px;
  max-width: 294px;
  width: 100%;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 0px 134px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.headerTextWrapper {
  max-width: 113px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.textService {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  color: #88898d;
  margin: 0;
  padding: 0;
}

.textPrice {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  color: #88898d;
  margin: 0;
  padding: 0;
}

.textTime {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  color: #88898d;
  margin: 0;
  padding: 0;
  max-width: 55px;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-width: 294px;
  width: 100%;
}

.item {
  display: flex;
  padding: 17px 11px;
  border-radius: 5px;
  justify-content: space-between;
  max-width: 294px;
}

.itemText {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  color: #88898d;
  margin: 0;
  padding: 0;
  max-width: 100px;
}

.itemPrice {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #02e2ff;
  margin: 0;
  padding: 0;
  max-width: 98px;
  width: 100%;
}

.itemTime {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: #000000;
  margin: 0;
  padding: 0;
  max-width: 41px;
}

.itemWrapper {
  max-width: 165px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
