.delivery-forms {
  background-color: #028fe8;
  z-index: 3;
}

.delivery-forms__container {
  max-width: 1060px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 45px 0 39px;
}

.delivery-forms__title {
  max-width: 701px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 31px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  margin: 0 0 48px 0;
}

.delivery-forms__selects {
  list-style-type: none;
  padding: 0;
  margin: 0 0 24px 0;
  display: flex;
  max-width: 701px;
  width: 100%;
  justify-content: space-between;
}

.delivery-forms__subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  text-transform: uppercase;
  color: #fff;
  margin: 0 0 12px 0;
}

.delivery-forms__price {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 31px;
  text-align: center;
  color: #ffffff;
  min-height: 31px;
}

.delivery-forms__item {
  color: #fff;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  margin: 0;
}

.delivery-forms__item:hover {
  background: rgba(255, 255, 255, 0.41);
  border-radius: 6px;
}

.delivery-forms__item:nth-of-type(2) {
  margin: 0 20px;
}

.delivery-forms__buttons {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  margin-top: 35px;
}

.delivery-forms__button {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  color: #fff;
  cursor: pointer;
  margin: 0;
}

.delivery-forms__button:hover {
  opacity: 0.7;
  transition: opactity 0.3s ease-in-out;
}

.delivery-forms__select {
  max-width: 330px;
  width: 100%;
}

.delivery-form__button-mobile {
  background: rgba(255, 255, 255, 0.41);
  border-radius: 6px;
  width: 192px;
  height: 33px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  margin: 15px 0 0;
}

.delivery-form__form-modal-backdrop {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 5;
}

.delivery-form__form-modal-container {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.delivery-form__form-modal-content {
  font-family: "Open Sans", sans-serif;
  background: #028fe8;
  max-width: 714px;
  width: 100%;
  background: #fff;
  padding: 35px 51px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  z-index: 5;
}

.delivery-form__form-modal-wrapper {
  max-width: 328px;
  width: 100%;
  background: #028fe8;
  border: 1px solid #c9c5c5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 25px;
}

@media screen and (max-width: 750px) {
  .delivery-forms__selects {
    flex-direction: column;
    max-width: 550px;
    align-items: center;
    justify-content: center;
  }
  .delivery-forms__select {
    margin-bottom: 20px;
  }
  .delivery-forms__select:last-of-type {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 650px) {
  .delivery-forms__title {
    max-width: 450px;
  }
  .delivery-forms__price {
    max-width: 450px;
  }
}

@media screen and (max-width: 500px) {
  .delivery-forms__container {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .delivery-forms__select {
    max-width: 100%;
  }
  .delivery-forms__title {
    max-width: 100%;
    font-size: 15px;
    line-height: 22px;
    margin: 0 0 42px 0;
  }
  .delivery-forms__selects {
    max-width: 100%;
  }
  .delivery-forms__subtitle {
    font-size: 12px;
    line-height: 16px;
    margin: 0 0 8px 0;
  }
  .delivery-forms__price {
    font-size: 14px;
    line-height: 24px;
    min-height: 31px;
    max-width: 275px;
  }
}
