.item {
  min-width: 100%;
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 36px 0 30px;
  background: #02e2ff;
  border-radius: 50px;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  padding: 0;
  margin: 20px 0 15px;
  max-width: 185px;
}

.text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  padding: 0;
  margin: 0 0 25px;
  max-width: 178px;
}

.button {
  background: #028fe8;
  border-radius: 14.4398px;
  text-decoration: none;
  font-style: normal;
  font-weight: 700;
  font-size: 10.5385px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  width: 133px;
  height: 37px;
}
