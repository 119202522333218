.repair-imac__links-section {
  padding: 75px 0 75px;
  background: linear-gradient(
    268deg,
    rgba(196, 196, 196, 0.09) 36.68%,
    rgba(196, 196, 196, 0) 88.72%
  );
}

.repair-imac__links-container {
  max-width: 1066px;
  width: 100%;
}

.repair-imac__links-title {
  margin: 0 0 55px 0;
  padding: 0;
  max-width: 615px;
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 32px;
  text-transform: uppercase;
  color: #02e2ff;
  text-align: left;
}

.repair-imac__links-list {
  margin: 0;
  padding: 0;
  list-style: none;
  max-width: 850px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  gap: 14px 150px;
}

.repair-imac__links-icon {
  margin-right: 26px;
  transform: translateY(2px);
}

.repair-imac__links-link {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 40px;
  color: #88898d;
  text-decoration: none;
}

.repair-imac__links-link:hover {
  color: #02e2ff;
  border-bottom: 1px solid #02e2ff;
}

@media screen and (max-width: 1130px) {
  .repair-imac__links-container {
    max-width: 780px;
  }
}

@media screen and (max-width: 830px) {
  .repair-imac__links-container {
    max-width: 680px;
  }
  .repair-imac__links-list {
    gap: 14px 50px;
  }
}

@media screen and (max-width: 720px) {
  .repair-imac__links-section {
    padding: 53px 0 65px;
  }
  .repair-imac__links-container {
    max-width: 480px;
  }
  .repair-imac__links-list {
    gap: 0 50px;
  }
  .repair-imac__links-title {
    margin: 0 0 32px 0;
    font-size: 26px;
    line-height: 29px;
  }
}

@media screen and (max-width: 500px) {
  .repair-imac__links-container {
    max-width: 100%;
  }
  .repair-imac__links-title {
    margin: 0 0 32px 0;
    font-size: 19px;
    line-height: 26px;
    padding: 0 20px;
  }
  .repair-imac__links-link {
    font-size: 14px;
    line-height: 21px;
  }
  .repair-imac__links-item {
    margin-bottom: 15px;
    padding: 0 20px;
  }
}
