.section {
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: 'Open Sans', sans-serif;
}

.section_group {
  flex-direction: column;
  align-items: center;
}