.repair-mb-mobile__info-content {
  font-family: "Open Sans";
  display: flex;
  max-width: 480px;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
}

.repair-mb-mobile__info-title {
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
  margin: 0 0 26px;
  padding: 0;
}

.repair-mb-mobile__info-border {
  width: 60px;
  height: 7px;
  background: #e4e9ea;
  margin-bottom: 36px;
}

.repair-mb-mobile__info-subtitle {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #88898d;
  margin: 0 0 25px;
  padding: 0;
  text-align: center;
}

.repair-mb-mobile__info-text {
  font-size: 14px;
  line-height: 21px;
  color: #88898d;
  margin: 0;
  padding: 0;
  text-align: center;
}

@media screen and (max-width: 500px) {
  .repair-mb-mobile__info-content {
    max-width: 100%;
    padding: 24px 20px;
  }

  .repair-mb-mobile__info-title {
    font-size: 22px;
    line-height: 25px;
    margin: 0 0 18px;
  }

  .repair-mb-mobile__info-subtitle {
    font-size: 14px;
    text-align: left;
  }

  .repair-mb-mobile__info-text {
    text-align: left;
  }
}
