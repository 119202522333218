.feedback {
  max-width: 340px;
  min-width: 340px;
  height: 253px;
  background: #ffffff;
  position: relative;
  padding: 58px 30px 30px;
  background-image: url(../../../images/icons/center-mobile-feedbacks-arrow.svg),
    url(../../../images/icons/center-mobile-feedbacks-arrow2.svg);
  background-repeat: no-repeat;
  background-position: left 9px top 30px, right 10px bottom 17px;
  box-sizing: border-box;
  margin-top: 45px;
}

@media screen and (max-width: 600px) {
  .feedback {
    max-width: 310px;
    min-width: 310px;
  }
}

@media screen and (max-width: 390px) {
  .feedback {
    max-width: 264px;
    min-width: 264px;
  }
}
