.nav {
  background-color: #02e1ff;
  background-image: url(../../images/fontBlocks/header_nav_font_block.png);
  background-position-x: 50px;
  min-height: 58px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.nav__container {
  background-color: #02e1ff;
  max-width: 1066px;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  min-height: 58px;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  /* z-index: 2; */
}

.nav__items {
  width: 100%;
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.nav__item {
  color: #fff;
  font-family: 'Verdana';
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  cursor: pointer;
  text-decoration: none;
}

.nav__item:hover {
  color: #028fe8;
  transition: color .5s ease-in-out;
}
