.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
}

/* .content {
  max-width: 1066px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
} */

.title {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 70px;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  letter-spacing: 2.52px;
  min-height: 75px;
  color: #88898d;
  max-width: 850px;
}

.border {
  margin: 20px 0 40px;
  width: 86px;
  border-bottom: 7px solid #e4e9ea;
}

.contacts {
  position: absolute;
  max-width: 280px;
  width: 100%;
  height: 100%;
  right: 300px;
  background: white;
  z-index: 500;
  padding: 0 50px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style-type: none;
}

.item {
  margin-bottom: 20px;
}

.subtitle_bold {
  color: rgb(136, 137, 141);
  letter-spacing: normal;
  line-height: 23px;
  font-size: 20px;
  font-weight: 700;
  padding: 0;
  margin: 0;
}

.subtitle {
  color: rgb(136, 137, 141);
  letter-spacing: normal;
  line-height: 23px;
  font-size: 18px;
  font-weight: 400;
  padding: 0;
  margin: 0;
}

.phone_link {
  text-decoration: none;
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  color: #333;
  font-size: 23px;
  font-weight: 500;
  line-height: normal;
}

.mail_link {
  padding: 0;
  margin: 0;
  color: #02e1ff;
  font-size: 19px;
  font-weight: 500;
  line-height: normal;
}

.text {
  padding: 0;
  margin: 0;
  color: #333;
  font-size: 19px;
  font-weight: 500;
  line-height: normal;
}
