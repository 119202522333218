.container {
  width: 100%;
  max-width: 1066px;
  padding: 0 0 76px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin: 0 0 78px;
  padding: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #02e2ff;
  max-width: 720px;
}

.list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  max-width: 700px;
}

.element {
  display: flex;
  margin-bottom: 32px;
}

.number {
  font-style: normal;
  font-weight: 400;
  font-size: 33px;
  line-height: 32px;
  text-transform: uppercase;
  color: #02e2ff;
  margin-right: 21px;
}

.text {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-align: justify;
  color: #88898d;
}

@media screen and (max-width: 780px) {
  .container {
    max-width: 630px;
  }
}

@media screen and (max-width: 670px) {
  .container {
    max-width: 480px;
  }
}

@media screen and (max-width: 500px) {
  .container {
    max-width: 100%;
  }
  .title {
    max-width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #02e2ff;
    padding: 0 20px;
  }
  .number {
    margin: 0 10px 0 0;
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;
    text-transform: uppercase;
    color: #02e2ff;
  }
  .list {
    max-width: 100%;
    padding: 0 20px;
  }
  .text {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    text-align: justify;
    color: #88898d;
  }
  .element {
    display: flex;
    margin-bottom: 15px;
  }
}
