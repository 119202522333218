.delivery-about {
  z-index: 1;
  background-color: #fff;
}

.delivery-about__container {
  width: 100%;
  max-width: 1066px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 55px 0;
}

.delivery-about__subtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 31px;
  text-align: center;
  color: #88898d;
  margin: 0;
  max-width: 559px;
}

@media screen and (max-width: 800px) {
  .delivery-about__container {
    max-width: 600px;
    padding: 45px 0 65px 0;
  }

  .delivery-about__subtitle {
    margin-top: 32px;
    font-size: 17px;
    line-height: 28px;
  }
}

@media screen and (max-width: 650px) {
  .delivery-about__subtitle {
    max-width: 450px;
  }
}

@media screen and (max-width: 500px) {
  .delivery-about__container {
    max-width: 100%;
    padding: 45px 20px 65px;
  }

  .delivery-about__subtitle {
    margin-top: 32px;
    font-size: 16px;
    line-height: 25px;
    text-align: left;
  }
}
