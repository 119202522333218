.repair-mb__links-content {
  align-items: center;
  padding: 75px 0 75px;
  background: linear-gradient(
    268deg,
    rgba(196, 196, 196, 0.09) 36.68%,
    rgba(196, 196, 196, 0) 88.72%
  );
  margin-top: 65px;
}

.repair-mb__links-title {
  margin: 0 0 55px 0;
  padding: 0;
  max-width: 615px;
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 32px;
  text-transform: uppercase;
  color: #02e2ff;
}

.repair-mb__links-wrapper {
  display: flex;
}

.repair-mb__links-icon {
  margin-right: 26px;
  transform: translateY(2px);
}

.repair-mb__links-list {
  max-width: 900px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 14px 81px;
}

.repair-mb__links-link {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 40px;
  color: #88898d;
  text-decoration: none;
}

.repair-mb__links-link:hover {
  color: #02e2ff;
  border-bottom: 1px solid #02e2ff;
}

.repair-mb__links-wrapper-images {
  display: flex;
  flex-direction: column;
}

.repaip-mb__links-imagemb {
  width: 160px;
  height: 160px;
  border-radius: 80px;
  background: rgba(255, 255, 255, 0.53);
  box-shadow: 0px 0px 134px rgba(0, 0, 0, 0.05);
  align-self: flex-end;
  transform: translateY(10px);
  background-image: url("../../images/icons/repair-mb-mb.svg");
  background-repeat: no-repeat;
  background-position: center;
  transform: translate(0, 55px);
  margin-left: 40px;
}

.repaip-mb__links-imagesd {
  width: 65px;
  height: 65px;
  border-radius: 32px;
  background: rgba(255, 255, 255, 0.53);
  box-shadow: 0px 0px 134px rgba(0, 0, 0, 0.05);
  transform: rotate(78.26deg);
  background-image: url("../../images/icons/repair-mb-stick.svg");
  background-repeat: no-repeat;
  background-position: center;
  transform: matrix(0.2, 0.98, 0.98, -0.2, 0, 0);
  transform: translate(100px, -10px);
}

@media screen and (max-width: 1130px) {
  .repair-mb__links-wrapper-images {
    margin-left: -150px;
  }
  .repaip-mb__links-imagemb {
    opacity: 0.4;
  }
  .repair-mb__links-list {
    gap: 14px 31px;
  }
}

@media screen and (max-width: 940px) {
  .repair-mb__links-wrapper-images {
    display: none;
  }
  .repair-mb__links-list {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 14px 31px;
  }
}

@media screen and (max-width: 900px) {
  .repair-mb__links-list {
    display: flex;
    flex-direction: column;
  }
  .repair-mb__links-link {
    line-height: 25px;
    color: #88898d;
    text-decoration: none;
  }
}

@media screen and (max-width: 720px) {
  .repaip-mb-container {
    max-width: 480px;
  }
  .repair-mb__links-title {
    margin: 0 0 42px 0;
    font-size: 26px;
    line-height: 29px;
  }
}

@media screen and (max-width: 500px) {
  .repair-mb__links-content {
    padding: 0;
  }
}
