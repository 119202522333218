.repair-apple-iphone__price-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 55px 0 55px;
  width: 100%;
  max-width: 1066px;
}

.repair-apple-iphone__price-title {
  max-width: 700px;
  width: 100%;
  margin: 0 0 26px;
  padding: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
}

.repair-apple-iphone__price-list {
  margin: 0;
  padding: 26px 57px;
  list-style-type: none;
  max-width: 879px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 134px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  max-height: 700px;
  overflow: auto;
}

.repair-apple-iphone__price-item {
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(196, 196, 196, 0.63);
  margin-bottom: 20px;
}

.repair-apple-iphone__price-span {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #88898d;
}

.repair-apple-iphone__price-price {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: right;
  color: #02e2ff;
  align-self: end;
}

.repair-apple-price__text {
  margin: 15px 0 20px;
  padding: 0;
  max-width: 993px;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  color: #88898d;
}

@media screen and (max-width: 1090px) {
  .repair-apple-iphone__price-container {
    max-width: 650px;
  }
  .repair-apple-iphone__price-list {
    max-width: 650px;
    padding: 26px 30px;
  }
}

@media screen and (max-width: 830px) {
  .repair-apple-iphone__price-container {
    max-width: 530px;
  }
  .repair-apple-iphone__price-list {
    max-width: 530px;
    padding: 16px 15px;
  }
}

@media screen and (max-width: 720px) {
  .repair-apple-iphone__price-container {
    max-width: 440px;
  }
  .repair-apple-iphone__price-list {
    max-width: 440px;
    padding: 16px 15px;
  }
  .repair-apple-iphone__price-span {
    max-width: 330px;
  }
  .repair-apple-iphone__price-title {
    font-size: 26px;
    line-height: 32px;
  }
}

@media screen and (max-width: 500px) {
  .repair-apple-iphone__price-container {
    max-width: 100%;
    padding: 45px 20px;
  }
  .repair-apple-iphone__price-list {
    max-width: 100%;
    padding: 10px 15px;
  }
  .repair-apple-iphone__price-span {
    max-width: 200px;
  }
  .repair-apple-iphone__price-price {
    align-self: flex-start;
  }
}
