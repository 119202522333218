.advantages {
  background-color: #fff;
  min-height: 1280px;
  padding: 0;
  background-image: url(../../images/fontBlocks/advantages_font_block.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.advantages__container {
  width: 100%;
  max-width: 1066px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
}

.advantages__title {
  color: #fff;
}

.advantages__items {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: 948px;
  margin-top: 70px;
}

.advantages__item {
  max-width: 250px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1080px) {
  .advantages {
    min-height: 0;
    padding: 0 0 110px 0;
    background-image: none;
    background: linear-gradient(
      223deg,
      rgba(196, 196, 196, 0.09) 36.68%,
      rgba(196, 196, 196, 0) 88.72%
    );
  }
  .advantages__title {
    color: #88898d;
  }
}

@media screen and (max-width: 1000px) {
  .advantages__container {
    max-width: 870px;
  }
}

@media screen and (max-width: 950px) {
  .advantages__container {
    max-width: 800px;
  }
}
