.cooperation-about__content {
  font-family: "Open Sans", sans-serif;
  max-width: 1244px;
  width: 100%;
  min-height: 443px;
  background: #02e2ff;
  box-shadow: 0px 0px 134px rgba(0, 0, 0, 0.05);
  border-radius: 300px;
  display: flex;
  align-items: center;
}

.cooperation-about__box {
  max-width: 366px;
  width: 100%;
  min-height: 366px;
  background: #ffffff;
  border-radius: 301.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 90px 0 57px;
}

.cooperation-about__text {
  margin: 0;
  padding: 0;
  max-width: 572px;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  color: #ffffff;
}

@media screen and (max-width: 1270px) {
  .cooperation-about__content {
    max-width: 1100px;
  }
  .cooperation-about__box {
    margin: 0 45px 0 57px;
  }
}

@media screen and (max-width: 1130px) {
  .cooperation-about__content {
    max-width: 1000px;
    min-height: 400px;
  }
  .cooperation-about__box {
    max-width: 300px;
    width: 100%;
    min-height: 300px;
  }
}

@media screen and (max-width: 1020px) {
  .cooperation-about__box {
    max-width: 280px;
    width: 100%;
    min-height: 280px;
  }
  .cooperation-about__content {
    max-width: 950px;
    min-height: 350px;
  }
  .cooperation-about__box {
    margin: 0 30px 0 37px;
  }
}

@media screen and (max-width: 970px) {
  .cooperation-about__logo {
    max-width: 180px;
  }
  .cooperation-about__content {
    max-width: 870px;
    min-height: 300px;
  }
  .cooperation-about__box {
    max-width: 240px;
    width: 100%;
    min-height: 240px;
    margin: 0 40px 0 37px;
  }
  .cooperation-about__text {
    font-size: 18px;
    line-height: 28px;
    max-width: 520px;
  }
}

@media screen and (max-width: 900px) {
  .cooperation-about__content {
    max-width: 800px;
    min-height: 270px;
  }
  .cooperation-about__box {
    max-width: 200px;
    width: 100%;
    min-height: 200px;
    margin: 0 35px 0 37px;
  }
  .cooperation-about__logo {
    max-width: 150px;
  }
}

@media screen and (max-width: 820px) {
  .cooperation-about__content {
    max-width: 670px;
    min-height: 220px;
  }
  .cooperation-about__logo {
    max-width: 110px;
  }
  .cooperation-about__box {
    max-width: 160px;
    width: 100%;
    min-height: 160px;
    margin: 0 15px 0 17px;
  }
  .cooperation-about__text {
    font-size: 16px;
    line-height: 23px;
    max-width: 460px;
  }
}

@media screen and (max-width: 680px) {
  .cooperation-about__content {
    flex-direction: column;
    max-width: 480px;
    border-radius: 41px;
    margin: 34px 0 0 0;
    padding: 0 0 60px 0;
  }
  .cooperation-about__box {
    margin: 20px 0 32px 0;
  }
  .cooperation-about__text {
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .cooperation-about__content {
    max-width: 294px;
  }
  .cooperation-about__box {
    max-width: 146px;
    min-height: 146px;
    border-radius: 301.5px;
    margin: 20px 0 32px 0;
  }
  .cooperation-about__text {
    font-size: 14px;
    line-height: 21px;
    max-width: 232px;
    text-align: center;
  }
}
