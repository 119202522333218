@font-face {
  font-family: 'Verdana';
  src: url('./Verdana.ttf') format('truetype');
};

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
};

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
};

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
};

@font-face {
  font-family: 'Open Sans';
  src: url('../../vendor/fonts/OpenSans-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
};

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
};

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
};

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: bold;
};

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: bold;
};

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: bold;
};
