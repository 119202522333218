.payment {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  font-family: "Open Sans", sans-serif;
  justify-content: center;
  max-width: 600px;
  width: 100%;
}

.payment__title {
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 31px;
  text-align: center;
  color: #88898d;
  margin: 0;
}

.payment__list {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  display: flex;
  max-width: fit-content;
  margin-top: 24px;
}

.payment__item {
  width: 73px;
  height: 45px;
  left: 591px;
  top: 2650px;
  background: #028fe8;
  border-radius: 6px;
}

.payment__item:nth-last-of-type(2) {
  margin: 0 20px;
}

@media screen and (max-width: 600px) {
  .payment__title {
    font-size: 16px;
  }
}
