.advantages-mobile {
  font-family: "Open Sans", sans-serif;
}

.advantages-mobile__content {
  max-width: 640px;
  width: 100%;
  padding: 75px 0 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.advantages-mobile__title {
  color: #88898d;
  margin: 0 0 18px;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 65px;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  letter-spacing: 2.52px;
}

.advantages-mobile__border {
  width: 90px;
  height: 7px;
  background: #c4c4c4;
}

.advantages-mobile__list {
  list-style-type: none;
  margin: 0;
  padding: 58px 0 0;
}

.advantages-mobile__list-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.advantages-mobile__item {
  margin-bottom: 34px;
}

.advantages-mobile__item:last-of-type {
  margin: 0;
}

.advantages-mobile__icon {
  margin-right: 14px;
}

.advantages-mobile__subtitle {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  text-transform: uppercase;
  color: #02e2ff;
}

.advantages-mobile__paragraph {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  color: #88898d;
}

@media screen and (max-width: 750px) {
  .advantages-mobile__content {
    max-width: 500px;
  }
  .advantages-mobile__title {
    font-size: 40px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 2.52px;
  }
}

@media screen and (max-width: 550px) {
  .advantages-mobile__content {
    max-width: 410px;
  }
}

@media screen and (max-width: 500px) {
  .advantages-mobile__content {
    max-width: 100%;
    padding: 45px 20px;
  }

  .advantages-mobile__title {
    margin: 0 0 18px;
    padding: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #02e2ff;
  }

  .advantages-mobile__border {
    width: 60px;
  }

  .advantages-mobile__list {
    list-style-type: none;
    margin: 0;
    padding: 58px 0 0;
  }
  .advantages-mobile__subtitle {
    font-size: 16px;
    line-height: 19px;
  }
  .advantages-mobile__paragraph {
    font-weight: 600;
    font-size: 12px;
    line-height: 21px;
  }
}
