.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  color: #88898d;
}

.title__text {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 80px;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  letter-spacing: 2.52px;
  min-height: 75px;
}

.title__text_white {
  color: #fff;
}

.title__underline {
  margin-top: 20px;
  width: 86px;
  border-bottom: 7px solid #e4e9ea;
}

@media screen and (max-width: 750px) {
  .title__text {
    font-size: 40px;
  }
}