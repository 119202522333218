.section {
  background: #028fe8;
}

.content {
  max-width: 1066px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 0 56px;
  font-family: "Open Sans", sans-serif;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 0;
  margin: 0 0 31px;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.item {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.item:last-of-type {
  margin-bottom: 36px;
}

.label {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  color: #ffffff;
}

.input {
  background: #ffffff;
  border: 1px solid #028fe8;
  border-radius: 6px;
  max-width: 401px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #028fe8;
  padding: 6px 15px;
}

.input::placeholder {
  color: rgba(2, 143, 232, 0.5);
}

.button {
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
  background: #ff3002;
  border: none;
  border-radius: 16.6885px;
  width: 210px;
  height: 44px;
  cursor: pointer;
}

.errorText {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #f9d749;
  padding: 0;
  margin: 0;
}

.hiddenErrorText {
  margin: 0;
  padding: 7px;
}

.errorText {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #f9d749;
  padding: 0;
  margin: 0 0 6px;
}

.hiddenErrorText {
  margin: 0;
  padding: 10px;
}

.backdrop {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 5;
}

.container_popup {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.contentSuccess {
  font-family: "Open Sans", sans-serif;
  background: #028fe8;
  max-width: 714px;
  width: 100%;
  background: #fff;
  padding: 35px 51px;
  border-radius: 20px;
}

.success {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 158px 0 60px;
  position: relative;
}

.buttonInactive {
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
  background: #c9c5c5;
  border: none;
  border-radius: 16.6885px;
  width: 210px;
  height: 44px;
  cursor: no-drop;
}

@media screen and (max-width: 1160px) {
  .content {
    align-items: center;
    max-width: 900px;
  }
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .list {
    max-width: 900px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .item {
    display: flex;
    max-width: 433px;
    width: 100%;
  }
}

@media screen and (max-width: 780px) {
  .title {
    font-size: 26px;
    line-height: 32px;
    margin: 0 0 25px;
  }
  .label {
    font-size: 14px;
    line-height: 19px;
  }
  .input {
    font-size: 14px;
    line-height: 20px;
    color: #028fe8;
    padding: 6px 15px;
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .content {
    max-width: 100%;
    padding: 30px 20px 46px;
  }
  .form {
    width: 100%;
  }
  .title {
    font-size: 19px;
    line-height: 26px;
    margin: 0 0 20px;
  }
  .item {
    margin-bottom: 0;
  }
  .label {
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    margin-bottom: 8px;
  }
  .button {
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    border-radius: 14px;
    width: 174px;
    height: 38px;
  }
}
