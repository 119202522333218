.section {
  background: linear-gradient(
    225deg,
    rgba(196, 196, 196, 0.09) 40.61%,
    rgba(196, 196, 196, 0) 92.65%
  );
}

.content {
  font-family: "Open Sans", sans-serif;
  max-width: 345px;
  width: 100%;
  padding: 40px 0 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 26px;
  text-transform: uppercase;
  color: #02e2ff;
  padding: 0;
  margin: 0 0 35px;
}

@media screen and (max-width: 390px) {
  .content {
    max-width: 274px;
  }
}
