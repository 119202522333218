.mac-profi__reasons-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 70px 0 93px;
  width: 100%;
  max-width: 1066px;
}

.mac-profi__reasons-title {
  margin: 0 0 55px;
  padding: 0;
  max-width: 680px;
  font-style: normal;
  font-weight: 400;
  font-size: 27px;
  line-height: 34px;
  text-align: center;
  color: #88898d;
}

.mac-profi__reasons-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.mac-profi__reasons-item {
  display: flex;
  margin-bottom: 49px;
}

.mac-profi__reasons-item:last-of-type {
  margin-bottom: 71px;
}

.mac-profi__reasons-icon {
  margin-right: 44px;
}

.mac-profi__reasons {
  margin: 0 0 10px 0;
  padding: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #88898d;
}

.mac-profi__reasons-text {
  margin: 0;
  padding: 0;
  max-width: 658px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #88898d;
}

.mac-profi__reasons-border {
  background-color: #02e2ff;
  width: 183px;
  height: 1px;
  align-self: center;
  margin-bottom: 61px;
}

.mac-profi__reasons-paragraph {
  margin: 0;
  padding: 0;
  max-width: 680px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #88898d;
}

@media screen and (max-width: 880px) {
  .mac-profi__reasons-container {
    max-width: 600px;
  }
}

@media screen and (max-width: 720px) {
  .mac-profi__reasons-container {
    max-width: 480px;
  }
}

@media screen and (max-width: 500px) {
  .mac-profi__reasons-container {
    padding: 45px 20px 65px;
    max-width: 100%;
  }
  .mac-profi__reasons-title {
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    text-align: left;
  }
  .mac-profi__reasons-icon {
    margin-right: 12px;
    width: 23px;
    height: 23px;
  }
  .mac-profi__reasons {
    margin: 0;
    line-height: 19px;
  }
  .mac-profi__reasons-text {
    font-size: 12px;
    line-height: 21px;
    text-align: justify;
  }
  .mac-profi__reasons-list-wrapper-mobile {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
  }
  .mac-profi__reasons-item-mobile {
    display: flex;
    flex-direction: column;
    margin: 36px 0;
  }
  .mac-profi__reasons-paragraph {
    font-size: 14px;
    line-height: 23px;
  }
  .mac-profi__reasons-border {
    width: 77px;
    margin: 11px 0 45px;
  }
}
