.content {
  max-width: 1066px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 48px 0 37px;
  font-family: "Open Sans", sans-serif;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
  padding: 0;
  margin: 0 0 25px;
}

.paragraph {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #88898d;
  padding: 0;
  margin: 0 0 35px;
  max-width: 980px;
}

.subtitle {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 24px;
  color: #02e2ff;
  padding: 0;
  margin: 0 0 32px;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0 0 50px;
  max-width: 920px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 426px));
  gap: 37px 68px;
}

.item {
  display: flex;
  max-width: 425px;
}

.text {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #88898d;
  padding: 0;
  margin: 0 0 0 25px;
}

@media screen and (max-width: 1160px) {
  .content {
    max-width: 890px;
  }
  .list {
    max-width: 890px;
    gap: 37px 38px;
  }
}

@media screen and (max-width: 980px) {
  .content {
    max-width: 690px;
  }
  .list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 780px) {
  .content {
    max-width: 550px;
  }
  .title {
    font-size: 26px;
    line-height: 32px;
    margin: 0 0 20px;
  }
  .paragraph {
    font-size: 13px;
    line-height: 20px;
  }
  .subtitle {
    font-size: 22px;
    line-height: 20px;
    margin: 0 0 30px;
  }
  .text {
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    margin: 0 0 10px;
    text-align: center;
  }
  .item {
    display: flex;
    max-width: 425px;
    flex-direction: column;
    align-items: center;
  }
  .tick {
    width: 40px;
  }
  .list {
    list-style-type: none;
    padding: 0;
    margin: 0 0 40px;
  }
}

@media screen and (max-width: 600px) {
  .list {
    gap: 0;
    margin: 0;
  }
  .content {
    max-width: 100%;
    padding: 45px 20px;
  }
  .title {
    font-size: 19px;
    line-height: 26px;
    margin: 0 0 15px;
  }
  .paragraph {
    line-height: 17px;
  }
  .subtitle {
    font-size: 19px;
    line-height: 17px;
    margin: 0 0 26px;
  }
  .text {
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 40px;
  }
}
