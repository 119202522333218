.modal {
  align-items: center;
  justify-content: center;
  display: flex;
  box-shadow: 0px 0px 25px;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  visibility: hidden;
}

.modal_is-opened {
  visibility: visible;
  opacity: 1;
}

.modal__close {
  position: absolute;
  background-color: transparent;
  border: none;
  border-radius: 10px;
  top: -45px;
  right: -43px;
}

.modal__close svg:hover {
  transition: opacity 0.5s ease-in-out;
  opacity: 0.6;
  cursor: pointer;
}

.modal__content {
  max-width: 430px;
  min-height: 370px;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  box-sizing: border-box;
  color: #5c5c5c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 10px 20px;
}

.modal__title {
  margin: 0;
  margin-left: 36px;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  max-width: 330px;
}

.modal__text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  margin-top: 10px;
}

.modal__figure {
  margin: 0;
  padding: 0;
}

.modal__form {
  display: flex;
  flex-direction: column;
}

.modal__input-container {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 535px) {
  .modal__content {
    max-width: 330px;
    min-height: 350px;
    padding: 45px 10px 20px;
  }
}

@media screen and (max-width: 425px) {
  .modal__content {
    max-width: 250px;
    min-height: 230px;
    padding: 25px 10px;
  }
  .modal__close {
    top: -60px;
    right: -11px;
  }
}
