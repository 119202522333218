.about-company__section {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  max-width: 1066px;
}

.about-comp__container {
  width: 100%;
  max-width: 1066px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 56px 0;
}

.about-comp__container-full {
  display: flex;
  flex-direction: column;
}

.about-comp__logo {
  /* margin-right: 126px; */
}

.about-comp__wrapper {
  max-width: 683px;
}

.about-comp__title {
  margin: 0 0 20px;
  padding: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-transform: uppercase;
  color: #02e2ff;
  text-align: center;
}

.about-company__subtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 27px;
  color: #88898d;
  margin: 0 0 30px;
  max-width: 700px;
}

.about-comp__text {
  margin: 0 0 26px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  color: #88898d;
}

.about-comp__text-full {
  margin: 0 0 26px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  color: #88898d;
}

.about-comp__certificate-img {
  max-width: 500px;
  margin-bottom: 26px;
  margin-left: 20px;
}

.about-comp__certificate-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.about-comp__requisites-list {
  align-self: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-width: 700px;
}

.about-comp__requisites-element {
  display: flex;
  border-bottom: solid 1px #caccd3;
  justify-content: space-between;
}

.about-comp__requisites-text {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #88898d;
  max-width: 350px;
  text-align: right;
}

.about-comp__requisites-button {
  background: #028fe8;
  border-radius: 21.9231px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  border: none;
  width: 264px;
  padding: 5px 35px;
  cursor: pointer;
  align-self: center;
  margin: 30px 0;
}

@media screen and (max-width: 1100px) {
  .about-comp__container {
    max-width: 900px;
  }
  .about-comp__container-full {
    max-width: 900px;
  }
  .about-comp__text {
    max-width: 550px;
  }
}

@media screen and (max-width: 970px) {
  .about-comp__container {
    max-width: 800px;
  }
  .about-comp__container-full {
    max-width: 800px;
  }
  .about-comp__title {
    font-size: 28px;
    line-height: 32px;
  }
  .about-comp__text {
    max-width: 400px;
  }
}
