.gadgetSection {
  background: linear-gradient(
    225deg,
    rgba(196, 196, 196, 0.09) 40.61%,
    rgba(196, 196, 196, 0) 92.65%
  );
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Open Sans", sans-serif;
  padding: 56px 0 65px;
  max-width: 780px;
  width: 100%;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
  margin: 0 0 60px;
  padding: 0;
}

.mobileWrapper {
  display: flex;
  max-width: 503px;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 105px;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0 20px 0 0;
  max-width: 250px;
  width: 100%;
}

.gadgetNameWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gadgetName {
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #88898d;
  margin: 30px 0 0;
  padding: 0;
}

.padWrapper {
  display: flex;
  max-width: 630px;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}

@media screen and (max-width: 920px) {
  .title {
    font-size: 26px;
    line-height: 32px;
    margin: 0 0 45px;
  }
}

@media screen and (max-width: 720px) {
  .mobileWrapper {
    max-width: 483px;
    margin-bottom: 80px;
  }
  .padWrapper {
    max-width: 610px;
  }
}
