.content {
  font-family: "Open Sans", sans-serif;
  max-width: 1066px;
  width: 100%;
  padding: 55px 0 94px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
  margin: 0 0 121px;
  padding: 0;
  max-width: 653px;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, 451px);
  gap: 121px 91px;
  max-width: 993px;
  width: 100%;
}

.box {
  border: 1px solid #02e2ff;
  max-width: 360px;
  width: 100%;
  padding: 94px 50px 51px 39px;
  position: relative;
}

.image {
  width: 132px;
  height: 132px;
  border-radius: 50%;
  background: #02e2ff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -19%;
  left: -10%;
}

.boxTitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #88898d;
  margin: 0 0 15px;
  padding: 0;
}

.boxText {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #88898d;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 1160px) {
  .box {
    max-width: 330px;
    padding: 84px 40px 41px 29px;
  }
  .list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, 401px);
    gap: 90px 70px;
    max-width: 873px;
  }
}
