.mac-profi-mobile__content {
  font-family: "Open Sans";
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 480px;
}

.mac-profi-mobile__container {
  font-family: "Open Sans";
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mac-profi-mobile__banner {
  max-width: 1440px;
  width: 100%;
}

.mac-profi-mobile__title {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 25px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
  margin: 35px 0 18px;
  padding: 0;
}

.mac-profi-mobile__border {
  width: 64.86px;
  height: 7px;
  background: #e4e9ea;
}

.mac-profi-mobile__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.mac-profi-mobile__item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 36px;
}

.mac-profi-mobile__icon {
  margin-bottom: 18px;
}

.mac-profi-mobile__link {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  text-decoration-line: underline;
  color: #88898d;
  text-decoration: none;
}

.mac-profi-mobile__paragraph {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #88898d;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 500px) {
  .mac-profi-mobile__content {
    max-width: 100%;
    padding: 0 20px;
  }
}
