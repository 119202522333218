.content {
  max-width: 1066px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 50px 0 84px;
  font-family: "Open Sans", sans-serif;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
  padding: 0;
  margin: 0 0 50px;
}

.wrapper {
  display: flex;
  align-items: flex-start;
  gap: 48px;
}

.sale {
  max-width: 450px;
  width: 100%;
  padding: 30px 0 50px 56px;
  background: #028fe8;
  border-radius: 15px;
}

.saleTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 24px;
  color: #ffffff;
  padding: 0;
  margin: 0 0 25px;
}

.saleText {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  padding: 0;
  margin: 0 0 56px;
  max-width: 380px;
}

.button {
  background: #02e2ff;
  border-radius: 16.6885px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  width: 210px;
  height: 44px;
  cursor: pointer;
}

@media screen and (max-width: 1160px) {
  .wrapper {
    align-items: center;
    flex-direction: column;
  }
}
