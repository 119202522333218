.repair-iphone__pricelist-container {
  width: 100%;
  max-width: 1066px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Open Sans", sans-serif;
}

.repair-iphone__pricelist-list {
  margin: 0;
  padding: 0;
  max-width: 503px;
  width: 100%;
  list-style-type: none;
}

.repair-iphone__pricelist-item {
  padding: 4px 10px;
  display: flex;
  justify-content: space-between;
  max-width: 483px;
  width: 100%;
  height: 24px;
  background-color: transparent;
  border-radius: 5px;
  margin-bottom: 8px;
}

.repair-iphone__pricelist-item:first-of-type {
  margin-top: 22px;
}

.repair-iphone__pricelist-text {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #88898d;
}

.repair-iphone__pricelist-price {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #02e2ff;
}

.repair-iphone__pricelist-border {
  max-width: 503px;
  width: 100%;
  border: 1px solid rgba(196, 196, 196, 0.63);
  margin-top: 48px;
}

.repair-iphone__pricelist-paragraph {
  margin: 20px 0 0;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #88898d;
}

@media screen and (max-width: 720px) {
  .repair-iphone__pricelist-list {
    max-width: 480px;
  }
  .repair-iphone__pricelist-item {
    max-width: 460px;
  }
  .repair-iphone__pricelist-paragraph {
    margin: 27px 0 0;
    font-size: 12px;
    line-height: 16px;
  }
}

@media screen and (max-width: 500px) {
  .repair-iphone__pricelist-list {
    max-width: 320px;
  }
  .repair-iphone__pricelist-item {
    height: 42px;
    max-width: 100%;
    width: 100%;
    text-align: left;
  }
  .repair-iphone__pricelist-text {
    font-size: 12px;
    line-height: 16px;
    max-width: 100%;
    margin-right: 20px;
  }

  .repair-iphone__pricelist-price {
    font-size: 14px;
    line-height: 20px;
  }
}
