.backdrop {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 5;
}

.container {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  width: 100%;
}

.content {
  font-family: "Open Sans", sans-serif;
  background: #028fe8;
  max-width: 714px;
  width: 100%;
  background: #fff;
  padding: 35px 51px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  z-index: 5;
}

.contentSuccess {
  font-family: "Open Sans", sans-serif;
  background: #028fe8;
  max-width: 714px;
  width: 100%;
  background: #fff;
  padding: 35px 51px;
  border-radius: 20px;
}

.wrapper {
  max-width: 328px;
  width: 100%;
  background: #028fe8;
  border: 1px solid #c9c5c5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 25px;
}

.form {
  max-width: 330px;
  width: 100%;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #ffffff;
  padding: 0;
  margin: 0 0 20px;
}

.label {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
}

.input {
  max-width: 330px;
  outline: none;
  background: #ffffff;
  border: 1px solid #028fe8;
  border-radius: 6px;
  padding: 0 12px;
  height: 39px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #595a5a;
}

.errorText {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #f9d749;
  padding: 0;
  margin: 0 0 6px;
}

.hiddenErrorText {
  margin: 0;
  padding: 10px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
}

.button {
  background: #c9c5c5;
  border-radius: 8px;
  width: 230px;
  height: 53px;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  margin: 34px auto 0;
}

.buttonActive {
  background: #02e2ff;
  border-radius: 8px;
  width: 230px;
  height: 53px;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  margin: 34px auto 0;
}

.success {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 158px 0 60px;
  position: relative;
}

.recaptcha {
  align-self: flex-start;
  margin-top: 7px;
}

@media screen and (max-width: 950px) {
  .content {
    max-width: 380px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .wrapper {
    margin-bottom: 20px;
  }
  .recaptcha {
    align-self: center;
    margin-top: 27px;
  }
  .contentSuccess {
    max-width: 380px;
    padding: 35px 51px;
  }
}

@media screen and (max-width: 550px) {
  .content {
    max-width: 100%;
    width: 100%;
    background: #fff;
    padding: 30px 23px 35px;
    border-radius: 20px;
    display: flex;
    align-items: center;
  }
  .wrapper {
    max-width: 100%;
    padding: 23px 25px;
    width: 85%;
    margin-bottom: 20px;
  }
  .recaptcha {
    max-width: 274px;
  }
  .title {
    font-size: 10px;
    line-height: 14px;
    padding: 0;
    margin: 0 0 10px;
    text-shadow: 0px 1.62388px 1.62388px rgba(0, 0, 0, 0.25);
  }
  .button {
    border-radius: 6px;
    width: 226px;
    height: 42px;
    font-size: 12px;
    line-height: 16px;
  }

  .buttonActive {
    border-radius: 6px;
    width: 226px;
    height: 42px;
    font-size: 12px;
    line-height: 16px;
  }
  .label {
    font-size: 12px;
    line-height: 16px;
  }
  .contentSuccess {
    max-width: 218px;
    padding: 35px 51px;
  }
  .contentMobileForm {
    display: flex;
    width: 100%;
    padding: 0 20px;
  }
}
