.mac-profi-mobile__au-container {
  font-family: "Open Sans";
  padding: 45px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.mac-profi-mobile__au-title {
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
  padding: 0;
  margin: 0 0 11px;
}

.mac-profi-mobile__au-border {
  width: 45px;
  height: 0px;
  border: 3px solid #e5e5e5;
  margin-bottom: 32px;
}

.mac-profi-mobile__au-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.mac-profi-mobile__au-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.mac-profi-mobile__au-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #88898d;
  margin: 0;
  padding: 0;
}

.mac-profi-mobile__border-paragraph {
  width: 27.02px;
  height: 0px;
  border: 1px solid #02e2ff;
  margin: 18px 0;
}
