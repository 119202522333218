.companies-mobile {
  padding: 45px 0 61px;
  background: linear-gradient(250deg, rgba(196, 196, 196, 0.11) 36.68%, rgba(196, 196, 196, 0) 88.72%);
}

.companies-mobile__container {
  font-family: 'Open Sans', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.companies-mobile__title {
  margin: 0 0 39px;
  padding: 0;
  max-width: 273px;
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #02E2FF;
}

.companies-mobile__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.companies-mobile__item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 45px;
}

.companies-mobile__item:last-of-type {
  margin-bottom: 0;
}

.companies-mobile__link {
  width: 170px;
  height: 48px;
  background: #5E78E9;
  border-radius: 20px;
  color: #FFFFFF;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.companies-mobile__logo {
  width: 146px;
  height: 146px;
  background: #FFFFFF;
  box-shadow: 4px 4px 19px rgba(94, 120, 233, 0.12);
  border-radius: 301.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.companies-mobile__text {
  margin: 0 0 30px;
  padding: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #88898D;
}