th {
  margin: 0;
  padding: 10px 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #88898d;
  border: 1px solid rgba(196, 196, 196, 0.63);
  text-align: center;
}

td {
  margin: 0;
  padding: 10px 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #88898d;
  border: 1px solid rgba(196, 196, 196, 0.63);
  text-align: center;
}

.mb-matrix__table-title {
  max-width: 993px;
  width: 100%;
}

table {
  max-width: 993px;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 26px;
}
