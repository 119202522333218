.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  width: 100%;
  max-width: 274px;
  position: relative;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  transition: transform 1.1s ease-in-out;
  max-width: 274px;
  width: 100%;
}

.buttons {
  max-width: 253px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  position: absolute;
  z-index: 10;
}

.emptyButton {
  padding: 2px;
}
