.image {
  width: 40px;
  height: 40px;
  background: #02e2ff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.icon {
  width: 19px;
  height: 19px;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #88898d;
  padding: 0;
  margin: 0 0 5px;
}

.text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #88898d;
  padding: 0;
  margin: 0;
}
