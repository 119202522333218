.repair-mb__links-mobile-section {
  background: linear-gradient(
    115.12deg,
    rgba(196, 196, 196, 0.12) 36.68%,
    rgba(196, 196, 196, 0) 88.72%
  );
}

.repair-mb__links-mobile-content {
  width: 100%;
  padding: 45px 0 50px;
}

.repair-mb__links-mobile-box {
  font-family: "Open Sans";
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.repair-mb__links-mobile-title {
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 26px;
  text-transform: uppercase;
  color: #02e2ff;
  padding: 0 20px;
  margin: 0 0 0;
}

.repair-mb__links-mobile-list {
  list-style-type: none;
  margin: 0;
  padding: 0 20px;
}

.repair-mb__links-mobile-item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.repair-mb__links-mobile-icon {
  background-image: url("../../images/icons/check-mobile.svg");
  background-repeat: no-repeat;
  background-position: 40%;
  width: 17px;
  height: 13px;
  margin-right: 15px;
}

.repair-mb__links-mobile-link {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #88898d;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-align: left;
}
