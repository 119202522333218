.about {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Open Sans", sans-serif;
}

.about__container {
  width: 100%;
  max-width: 1066px;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 81px;
}

.about__content {
  z-index: 1;
  margin-left: 80px;
}

.about__text-content {
  align-self: flex-end;
  margin-top: 70px;
  margin-right: 60px;
  z-index: 1;
}

.about__wrapper {
  display: flex;
  max-width: 896px;
  flex-direction: row;
  justify-content: space-between;
  margin: 48px 0 0;
  padding: 0;
  list-style-type: none;
}

.about__image {
  width: 735px;
  height: 524px;
  margin-top: -123px;
  margin-left: 80px;
  z-index: 0;
  height: auto;
  max-width: 100%;
}

.about__paragraph {
  margin: 29px 0 0;
  padding: 0;
  max-width: 380px;
  color: #88898d;
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
}

.about__text {
  margin: 0;
  padding: 0;
  max-width: 427px;
  color: #88898d;
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
}

@media screen and (max-width: 1040px) {
  .about__content {
    margin-left: 0;
    padding: 0 60px 0;
  }
  .about__item:last-of-type {
    margin-left: 40px;
  }
}
