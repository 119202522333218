.cooper-offers__content {
  background: linear-gradient(
    268deg,
    rgba(196, 196, 196, 0.09) 36.68%,
    rgba(196, 196, 196, 0) 88.72%
  );
  flex-direction: column;
  align-items: center;
}

.cooper-offers__container {
  width: 100%;
  max-width: 1066px;
  padding: 75px 0 83px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cooper-offers__wrapper {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.cooper-offers__title {
  margin: 0 0 57px;
  padding: 0;
  width: 455px;
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 32px;
  text-transform: uppercase;
  color: #02e2ff;
}

.cooper-offers__item {
  display: flex;
  margin-bottom: 31px;
}

.cooper-offers__item:last-of-type {
  margin-bottom: 0;
}

.cooper-offers__icon {
  margin-right: 27px;
  transform: translateY(-10%);
}

.cooper-offers__text {
  margin: 0;
  padding: 0;
  align-items: center;
  max-width: 490px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #88898d;
}

.cooper-offers__images {
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 110px;
  max-width: 350px;
  width: 100%;
}

.cooper-offers__logo {
  transform: translateY(-60px);
}

.cooper-offers__icon-set {
  width: 96px;
  height: 96px;
  background-image: url("../../images/icons/cooper-offers_set.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
  border-radius: 46px;
  position: absolute;
  transform: translate(46px, -340px);
  box-shadow: 0px 0px 134px rgba(0, 0, 0, 0.05);
}

.cooper-offers__icon-stick {
  width: 115px;
  height: 115px;
  background-image: url("../../images/icons/cooper-offers_stick.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
  border-radius: 57px;
  position: absolute;
  transform: translate(289px, -235px);
  box-shadow: 0px 0px 134px rgba(0, 0, 0, 0.05);
}

.cooper-offers__icon-mobile {
  width: 145px;
  height: 145px;
  background-image: url("../../images/icons/cooper-offers_mobile.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
  border-radius: 72px;
  position: absolute;
  transform: translate(313px, 45px);
  box-shadow: 0px 0px 134px rgba(0, 0, 0, 0.05);
}

.cooper-offers__icon-comp {
  width: 192px;
  height: 192px;
  background-image: url("../../images/icons/cooper-offers_comp.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
  border-radius: 96px;
  position: absolute;
  transform: translate(122px, 290px);
  box-shadow: 0px 0px 134px rgba(0, 0, 0, 0.05);
}

.cooper-offers__signature {
  margin: 0 0 6px;
  padding: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #88898d;
  max-width: 845px;
}

.cooper-offers__signature:last-of-type {
  max-width: 610px;
  margin-bottom: 0;
}

.cooper-offers__line {
  margin-top: 92px;
  margin-bottom: 37px;
  width: 183px;
  border-top: 1px solid #02e2ff;
}

.cooper-offers__box {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1180px) {
  .cooper-offers__images {
    margin-left: 55px;
  }
}

@media screen and (max-width: 1130px) {
  .cooper-offers__images {
    margin-left: 25px;
  }
}

@media screen and (max-width: 1100px) {
  .cooper-offers__signature {
    max-width: 600px;
  }
}

@media screen and (max-width: 780px) {
  .cooper-offers__container {
    max-width: 630px;
  }
}

@media screen and (max-width: 670px) {
  .cooper-offers__container {
    max-width: 480px;
  }
}
