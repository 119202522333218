.gadgetSection {
  background: linear-gradient(
    235deg,
    rgba(196, 196, 196, 0.09) 40.61%,
    rgba(196, 196, 196, 0) 92.65%
  );
}

.content {
  font-family: "Open Sans", sans-serif;
  max-width: 480px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 26px 0;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  text-transform: uppercase;
  color: #02e2ff;
  margin: 0 0 45px;
  padding: 0;
}

.gadget {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #88898d;
  padding: 0;
  margin: 0 0 20px;
}

.wrapperImage {
  margin-bottom: 50px;
}

.wrapperImagePad {
  margin: 20px 0 50px;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.item {
  margin-bottom: 15px;
}

.link {
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 17px;
  text-align: center;
  color: #88898d;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .content {
    max-width: 345px;
  }
  .title {
    font-size: 19px;
    line-height: 26px;
    margin: 0 0 25px;
  }
}
