.repair-iphone__price {
  background: rgba(196, 196, 196, 0.09);
}

.repair-iphone__price-container {
  padding: 55px 0 163px;
  width: 100%;
  max-width: 1066px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.repair-iphone__price-title {
  margin: 0 0 49px;
  padding: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
}

.repair-iphone__price-text {
  margin: 0 0 38px;
  padding: 0;
  max-width: 476px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #88898d;
}

.repair-iphone__price-padding-img {
  margin-right: 29px;
}

@media screen and (max-width: 720px) {
  .repair-iphone__price-container {
    padding: 45px 0 110px;
  }
  .repair-iphone__price-title {
    margin: 0 0 37px;
    font-size: 26px;
    line-height: 32px;
  }
  .repair-iphone__price-text {
    margin: 0 0 34px;
    font-size: 16px;
    line-height: 22px;
  }
}

@media screen and (max-width: 500px) {
  .repair-iphone__price-container {
    padding: 45px 0 61px;
    max-width: 100%;
  }
  .repair-iphone__price-title {
    margin: 0 0 25px;
    font-size: 19px;
    line-height: 26px;
  }
  .repair-iphone__price-text {
    margin: 0 0 18px;
    font-size: 14px;
    line-height: 21px;
    padding: 0 20px;
  }
  .repair-iphone__price-padding-img {
    margin-right: 21px;
  }
}
