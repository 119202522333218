.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 56px;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1060px;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-transform: uppercase;
  color: #02e2ff;
  margin: 0 0 30px;
  padding: 0;
}

.subtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 31px;
  text-align: center;
  color: #88898d;
  margin: 0;
  max-width: 700px;
}

.subtitle_two {
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 31px;
  text-align: center;
  color: #88898d;
  margin: 0;
  max-width: 700px;
  margin-bottom: 30px;
}

.list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  width: 100%;
}

.text {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #88898d;
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
}

.text:last-of-type {
  margin-bottom: 30px;
}

.list_with_number {
  margin: 0;
  padding: 0;
  list-style-type: none;
  max-width: 700px;
}

.item {
  display: flex;
  margin-bottom: 32px;
  width: 100%;
}

.number {
  font-style: normal;
  font-weight: 400;
  font-size: 33px;
  line-height: 32px;
  text-transform: uppercase;
  color: #02e2ff;
  margin-right: 21px;
}

.paragraph {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-align: justify;
  color: #88898d;
}

.footer_container {
  background: linear-gradient(
    115.12deg,
    rgba(196, 196, 196, 0.09) 36.68%,
    rgba(196, 196, 196, 0) 88.72%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 47px 0 68px;
  width: 100%;
}

.border {
  width: 183px;
  height: 1px;
  background: #02e2ff;
  margin-bottom: 36px;
}

.footer_text {
  margin: 0;
  padding: 0;
  max-width: 590px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #88898d;
}

@media screen and (max-width: 1100px) {
  .content {
    max-width: 850px;
  }
}

@media screen and (max-width: 970px) {
  .content {
    max-width: 800px;
  }
  .title {
    font-size: 28px;
    line-height: 32px;
  }
}

@media screen and (max-width: 840px) {
  .content {
    max-width: 640px;
  }
}

@media screen and (max-width: 690px) {
  .section {
    padding: 0 20px;
  }
  .content {
    max-width: 500px;
  }
}

@media screen and (max-width: 550px) {
  .content {
    max-width: 410px;
  }
}

@media screen and (max-width: 500px) {
  .section {
    padding: 0;
  }
  .content {
    max-width: 100%;
  }
  .title {
    font-size: 22px;
    line-height: 25px;
    text-align: center;
    margin-bottom: 18px;
  }
  .subtitle {
    padding: 0 20px;
    font-size: 16px;
  }
  .subtitle_two {
    padding: 0 20px;
    font-size: 16px;
  }
  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding: 0 20px;
  }
  .list_with_number {
    padding: 0 20px;
  }
  .paragraph {
    font-size: 14px;
  }
  .footer_text {
    padding: 0 20px;
  }
}
