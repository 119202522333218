.selectSection {
  background: linear-gradient(
    225deg,
    rgba(196, 196, 196, 0.09) 40.61%,
    rgba(196, 196, 196, 0) 92.65%
  );
}

.container {
  max-width: 1066px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 55px 0 47px;
}

.content {
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1066px;
  width: 100%;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  color: #02e2ff;
  margin: 0 0 25px;
  padding: 0;
}

.subtitle {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #88898d;
  margin: 0 0 38px;
  max-width: 476px;
}

.wrapper {
  position: relative;
  max-width: 543px;
  width: 100%;
}

.button {
  background: #ffffff;
  box-shadow: 0px 0px 134px rgba(0, 0, 0, 0.05);
  border-radius: 19px;
  border: none;
  max-width: 543px;
  width: 100%;
  padding: 10px 21px 10px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 31px;
  color: #88898d;
}

.options {
  list-style-type: none;
  margin: 0;
  padding: 20px;
  position: absolute;
  max-width: 465px;
  box-sizing: content-box;
  width: 100%;
  top: 53px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background: #ffffff;
  box-shadow: 0px 14px 24px rgba(18, 68, 100, 0.2);
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);
  overflow: scroll;
  height: 232px;
}

.option {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #88898d;
  cursor: pointer;
  padding: 10px 14px;
}

.optionActive {
  background: rgba(2, 143, 232, 0.06);
  border-radius: 6px;
}

.optionSelected {
  background: rgba(7, 111, 176, 0.06);
}

.list {
  padding: 0;
  margin: 26px 0 0;
  list-style-type: none;
  max-width: 503px;
  width: 100%;
}

.item {
  box-shadow: 0px 0px 134px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 10px;
}

.itemText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #88898d;
  margin: 0;
  padding: 0;
  text-align: left;
}

.itemPrice {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #02e2ff;
  margin: 0;
  padding: 0;
}

.border {
  border: 1px solid rgba(196, 196, 196, 0.63);
  max-width: 503px;
  width: 100%;
  margin: 25px 0 17px;
}

.paragraph {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #88898d;
  margin: 0 0 40px;
  padding: 0;
  max-width: 503px;
}

.downloadButton {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  width: 235px;
  border: none;
  background: #028fe8;
  border-radius: 20px;
  padding: 13px 0;
  cursor: pointer;
}

.carouselWrapper {
  max-width: 274px;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .container {
    padding: 55px 20px 47px;
  }
  .content {
    max-width: 100%;
  }
  .title {
    font-size: 19px;
    line-height: 26px;
    max-width: 310px;
    margin: 0 0 10px;
  }
  .subtitle {
    font-size: 14px;
    line-height: 21px;
    margin: 0 0 18px;
    padding: 0 20px;
  }
  .list {
    margin: 29px 24px 0;
    list-style-type: none;
    max-width: 100%;
    width: 100%;
  }
  .itemText {
    max-width: 210px;
  }
  .border {
    max-width: 150px;
  }
  .paragraph {
    font-size: 12px;
    line-height: 16px;
    margin: 0 0 25px;
    max-width: 274px;
  }
  .downloadButton {
    font-size: 12px;
    line-height: 17px;
    width: 174px;
    border-radius: 14px;
    padding: 9px 0;
  }
  .options {
    padding: 15px;
    max-width: 300px;
    height: 190px;
  }
}
